import { Injectable } from '@angular/core';
import { AppHttpClient } from './app-http-client.service';
import { UtilService } from './util.service';
import { Observable, map } from 'rxjs';
import { Certification } from '../models/certification.model';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(
    public http : AppHttpClient,
    public util : UtilService,
  ) { }

  getCertificates():Observable<Certification[]>{
    return this.http.get('certificates').pipe(
      map((res:any)=>res.map((cert:any)=>new Certification(cert)))
    );
  }

  getCertificatesByAlsdeId(alsdeId:string):Observable<Certification[]>{
    return this.http.get('agentCertificates',{userId:alsdeId}).pipe(
      map((res:any)=>res.map((cert:any)=>new Certification(cert)))
    );
  }
}
