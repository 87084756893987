import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/core/services/payment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';
import { FeeSummary } from 'src/app/core/models/fee.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  FEE_SUMMARY_KEY: string = 'FEE_SUMMARY';

  feeSummary: FeeSummary = new FeeSummary();

  constructor(
    private paymentService: PaymentService,
    private activatedRoute: ActivatedRoute,
    private ls: LocalStorageService,
    public util: UtilService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['appId'] && params['appId'] !== '') {
        // BM 9-23-23 If we route with appId, this is the initial payment
        let appId = params['appId'];
        let feeSummaryId = params['feeSummaryId'];
        this.paymentService
          .getFeeSummary(appId, feeSummaryId)
          .subscribe((val) => {
            this.feeSummary = val;
            if (this.feeSummary.token && feeSummaryId.transactionId != null) {
              // We have already processed this fee summary and perhaps just need to validate it again
              const f = this.feeSummary;
              const url = `preparepayment?localRef=${f.transactionId}&uniqueTransId=${f.transactionId}&token=${f.token}&appId=${f.appId}&userId=${f.userId}&feeSummaryId=${f.feeSummaryId}`;
              this.callPaymentService(url);
            }
          });
      } else if (params['token']) {
        // BM 9-23-23 If we route with token, this is the callback from the payment provider
        this.feeSummary = JSON.parse(this.ls.getItem(this.FEE_SUMMARY_KEY));
        this.feeSummary.token = params['token'];
        const f = this.feeSummary;
        const url = `preparepayment?localRef=${f.transactionId}&uniqueTransId=${f.transactionId}&token=${params['token']}&appId=${f.appId}&userId=${f.userId}&feeSummaryId=${f.feeSummaryId}`;
        this.paymentService
          .saveFeeSummary(this.feeSummary)
          .subscribe((val) => this.callPaymentService(url));
      }
    });
  }

  preparePayment() {
    // BM 9-23-23 Only create a new transaction id if not already present
    if (!this.feeSummary.transactionId) {
      const uid = function () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      };
      let id = uid();
      this.feeSummary.transactionId = id;
    }

    // Set the fee summary to the local storage
    this.ls.setItem(this.FEE_SUMMARY_KEY, JSON.stringify(this.feeSummary));

    const url = `preparepayment?localRef=${this.feeSummary.transactionId}&uniqueTransId=${this.feeSummary.transactionId}&appId=${this.feeSummary.appId}&feeSummaryId=${this.feeSummary.feeSummaryId}`;

    this.callPaymentService(url);
  }

  callPaymentService(url: string) {
    const payload: Array<Object> = [];

    this.feeSummary?.items.map((x: any) => {
      payload.push({
        Sku: 'ALSDE_Certification',
        Description: x.name,
        UnitPrice: x.feeAmount,
        Quantity: 1,
      });
    });

    this.paymentService.preparePayment(url, payload).subscribe((x: any) => {
      if (x?.html5RedirectUrl) {
        // BM 9-23-23 Save the fee summary to prevent duplicate payments
        this.feeSummary.token = x.token;
        this.paymentService
          .saveFeeSummary(this.feeSummary)
          .subscribe((val) => (document.location = x.html5RedirectUrl));
      } else if (x?.items && x?.items.length > 0) {
        this.util.openToastr('success', 'Payment Successful');
        this.router.navigate(['app/app-wizard', x.appId]);
      }
    });
  }

  calculateBalance() {
    //return Math.abs(this.payInfo?.feeSummary?.balanceAmount || 0);
    return this.feeSummary.balanceAmount || 0;
  }

  ngOnDestroy() {
    this.ls.removeItem(this.FEE_SUMMARY_KEY);
  }
}
