<div>
    <div class="description">Please add educational experience here. (Do not include student teaching, substitute, or
        teacher aide experience. If none, enter none. List recent experience first.)</div>
    <div class="description pb-3"> If you are currently employed with a school system at the time of completing this
        application, only list the start date. Leave the end date section blank. </div>
    <div class="container">
        <div class="row">
            <div class="my-3 table-section" *ngIf="jsonData.records.length > 0">
                <div class="row overflow-auto">
                    <table class="table table-striped table-mobile-responsive table-mobile-sided">
                        <thead>
                            <tr>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">School Name</th>
                                <th scope="col">School Location</th>
                                <th scope="col" class="text-start">Grade and Subject Taught</th>
                                <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of jsonData.records;">
                                <td scope="row" data-content="Start Date">{{element.startDate| date:'MM/dd/yyyy'}}</td>
                                <td data-content="End Date">{{element.endDate| date:'MM/dd/yyyy'}}</td>
                                <td data-content="School Name">{{element.schoolName}}</td>
                                <td data-content="School Location">{{element.schoolLocation}}</td>
                                <td data-content="Grade and Subject Taught">{{element.gradeSubject}}</td>
                                <td data-content="Remove" *ngIf="!isReadOnly()"><mat-icon
                                        (click)="deleteExperienceRecord(element)"
                                        style="font-size:32px; color:red;">close</mat-icon></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="px-2 pt-3">
                <div class="row form-group">
                    <div class="col-sm-6 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="Start Date" placeholder="Start Date"
                            controlName="startDate" [minDate]="minFromDate" [maxDate]="maxFromDate"
                            (dateChange)="dateChanges('start', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="End Date" placeholder="End Date"
                            controlName="endDate" [minDate]="minToDate" [maxDate]="maxToDate"
                            (dateChange)="dateChanges('end', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Name of School/School System"
                            placeholder="Name of School" controlName="schoolName"
                            (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Location of School/School System"
                            placeholder="Location of School" controlName="schoolLocation"
                            (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm"
                            title="Grade(s) & Subject(s) Taught or Instructional Support Positions Held"
                            placeholder="Grades and Subjects" controlName="gradeSubject"
                            (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <button mat-stroked-button color="accent" [disabled]="!interalForm.valid"
                            (click)="createExperienceRecord()">Add Experience Record</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>