import { USER_ROLES } from "./user.enum";

export enum UI_FEATURES{
    'FE1' = 'FEATURE-1',
    'FE2' = 'FEATURE-2',
    'FE3' = 'FEATURE-3',
    'FE4' = 'FEATURE-4',
    'FE5' = 'FEATURE-5',
    'FE6' = 'FEATURE-6',
};

const ROLE_NAME: any = {};
ROLE_NAME[USER_ROLES.APPLICANT] = "Applicant";
ROLE_NAME[USER_ROLES.IHE] = "Institute of Higher Education";
ROLE_NAME[USER_ROLES.LEA] = "Education Agency";


const PERMISSIONS_BY_ROLE: any = {};
PERMISSIONS_BY_ROLE[USER_ROLES.ADMIN] = [UI_FEATURES.FE1, UI_FEATURES.FE2, UI_FEATURES.FE3, UI_FEATURES.FE4, UI_FEATURES.FE5];
PERMISSIONS_BY_ROLE[USER_ROLES.APPLICANT] = [UI_FEATURES.FE1];
PERMISSIONS_BY_ROLE[USER_ROLES.IHE] = [UI_FEATURES.FE1, UI_FEATURES.FE2];
PERMISSIONS_BY_ROLE[USER_ROLES.LEA] = [UI_FEATURES.FE1, UI_FEATURES.FE3];

const ROLES_BY_USER_TYPE: any = {};
ROLES_BY_USER_TYPE.APPLICANT = [USER_ROLES.APPLICANT];
ROLES_BY_USER_TYPE.EDU_AGENT = [USER_ROLES.IHE,USER_ROLES.LEA,USER_ROLES.LEA2,USER_ROLES.TEAMS, USER_ROLES.LEA3RDPARTY];

export {PERMISSIONS_BY_ROLE, ROLES_BY_USER_TYPE, ROLE_NAME};