import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AppWizardComponent } from './views/pages/app-wiz/app-wiz.component';
import { CertWizardComponent } from './views/pages/cert-wizard/cert-wizard.component';
import { HelpComponent } from './views/pages/help/help.component';

import { HomePageComponent } from './views/pages/home-page/home-page.component';
import { LoginComponent } from './views/pages/public/login/login.component';
import { MessageCenterComponent } from './views/partials/message-center/message-center.component';
import { LayoutComponent } from './views/themes/layout/layout.component';
import { PaymentComponent } from './views/partials/payment/payment.component';
import { PaymentErrorComponent } from './views/partials/payment-error/payment-error.component';
import { SupportComponent } from './views/pages/support/support.component';
import { RoleGuard } from './core/guards/role.guard';
import { ROLES_BY_USER_TYPE } from './core/enums/ui.enum';

const routes: Routes = [
  {
    path:'app',
    component:LayoutComponent,
    children:[
      { 
        path: '', 
        component: HomePageComponent,
        canActivate: [AuthGuard, RoleGuard], 
        data: { roles: ROLES_BY_USER_TYPE.APPLICANT },
      },
      { path: 'cert-wizard', component: CertWizardComponent, canActivate:[AuthGuard] },
      { path: 'app-wizard/:appId', component: AppWizardComponent, canActivate:[AuthGuard] },
      { path: 'message-center', component: MessageCenterComponent, canActivate:[AuthGuard] },
      { path: 'payment', component: PaymentComponent, canActivate:[AuthGuard] },
      { path: 'payment/error', component: PaymentErrorComponent, canActivate:[AuthGuard] },
      { path: 'help', component: HelpComponent },
      { path: 'support', component: SupportComponent },
      { path: 'auth/login', component: LoginComponent },
      { path: '**', redirectTo:"" },
    ]
  },
  {
    path:'edu-agent',
    loadChildren: () => import('./edu-agent/edu-agent.module').then(m => m.EduAgentModule)
  },
  {
    path: "**",
    redirectTo:"/app"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
