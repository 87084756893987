import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BtnAction } from 'src/app/core/models/btn-action.model';

@Component({
  selector: 'app-btn-action',
  templateUrl: './btn-action.component.html',
  styleUrls: ['./btn-action.component.scss']
})
export class BtnActionComponent implements OnInit {

  @Input() action: BtnAction = {} as BtnAction;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
        
  constructor() { }

  ngOnInit(): void {
  }

  onClickFn(){
    this.onClick.emit();
  }
}
