import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subject, Subscription } from 'rxjs';
import { ARTIFACT_KEY, ARTIFACTS_BY_USER, ARTIFACTS_SUBMIT } from 'src/app/core/constants/artifacts-order.constants';
import { ARTIFACT_STATUS } from 'src/app/core/enums/artifact.enum';
import { Artifact } from 'src/app/core/models/artifact.model';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UiStoreService } from 'src/app/core/services/ui-store.service';

@Component({
  selector: 'app-artifact-action',
  templateUrl: './artifact-action.component.html',
  styleUrls: ['./artifact-action.component.scss']
})
export class ArtifactActionComponent implements OnInit {

  @Input() artifact: Artifact = {} as Artifact;
  @Input() stepControl: FormControl = {} as FormControl;
  @Input() isMobile: boolean = true;
  @Input() submit$ = new Subject();
  @Input() reset$ = new Subject();
  ARTIFACT_KEY = ARTIFACT_KEY
  ARTIFACTS_SUBMIT = ARTIFACTS_SUBMIT
  subscriptions: Subscription[] = [];
  constructor(
    public _ui: UiStoreService,
    public appWizService : AppWizService,
    private step: MatStepper
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.appWizService.artifactUpdated$.subscribe(this.artifactUpdated.bind(this))
    ];
  }

  artifactUpdated(artifact: Artifact){
    if(artifact?.artifactId === this.artifact.artifactId){
      this.artifact = artifact;
    }
  }

  disablBackOrNext(){
    let invalid = this.stepControl.invalid;
    return invalid || this.appWizService.isReadOnly(this.artifact);
  }

  disablSubmit(){
    let invalid = this.stepControl.invalid;
    return invalid || this.appWizService.isReadOnly(this.artifact);
  }

  disableReset(){
    return this.appWizService.isReadOnly(this.artifact);
  }

  selectionChanged(){
    let stepIndex = this.isMobile? 1 : 2;
    const stepElement = document.getElementById('cdk-step-label-'+stepIndex+'-'+this.step.selectedIndex);
    if (stepElement) {
      setTimeout(() => {
        const el: HTMLElement | null = stepElement;
        if (el) {
          el.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
        }
      }, 250);
    }
  }

  submit(){
    this.submit$.next(this.artifact.artifactId+this.isMobile)
  }
  reset(){
    this.reset$.next(this.artifact.artifactId+this.isMobile)
  }

  back(){
    if(!ARTIFACTS_SUBMIT.includes(this.artifact.key)){
      this.submit();
    }
    this.UxAction('back');
  }

  next(){
    this.submit();
    this.UxAction('next');
  }

  UxAction(actionType:'next'|'back'){
    let artifactId = this.artifact.artifactId;
    let allArtifacts = this.appWizService.appWizard.artifacts;
    let currentIndex = allArtifacts.findIndex(i=>i.artifactId == artifactId);
    let nextArtifact:Artifact;
    if(actionType=='next' && currentIndex < allArtifacts.length-1){
      nextArtifact = allArtifacts[currentIndex + 1];
    }else{
      nextArtifact = allArtifacts[currentIndex - 1];
    }
    
    this._ui.expandedArtifactId.next(nextArtifact.artifactId);
    if(ARTIFACTS_BY_USER.includes(nextArtifact.key)){
      setTimeout(() => {this.selectionChanged();}, 1000);
    }else{
      this._ui.selectedTab.appWizard = 0;
    }
    
  }
    
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
