import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AGENT_ARTIFACTS_ACCEPTANCE, ARTIFACT_KEY, ARTIFACTS_BY_USER, ARTIFACTS_ORDER } from 'src/app/core/constants/artifacts-order.constants';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { CertWizService } from 'src/app/core/services/cert-wiz.service';
import { UiStoreService } from 'src/app/core/services/ui-store.service';
import { UtilService } from 'src/app/core/services/util.service';
import { UserService } from 'src/app/core/services/user.service';
import { IAlsdeBackground, AlsdeBackground } from 'src/app/core/models/background.model';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { HttpService } from 'src/app/core/services/http.service';
import { ARTIFACT_STATUS } from 'src/app/core/enums/artifact.enum';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Artifact } from 'src/app/core/models/artifact.model';
import { DialogService } from 'src/app/core/services/dailog.service';
import { APPLICATION_STATUS } from 'src/app/core/enums/application.enum';

@Component({
  selector: 'app-app-wiz',
  templateUrl: './app-wiz.component.html',
  styleUrls: ['./app-wiz.component.scss'],
  providers: [MatStepper]
})
export class AppWizardComponent implements OnInit {

  ARTIFACT = ARTIFACT_KEY
  APPLICATION_STATUS = APPLICATION_STATUS
  stepperFormGroup:FormGroup;
  subscriptions: Subscription[] = [];
  reset$ = new Subject();
  addWizObj:ApplicationWizard = new ApplicationWizard();
  bgrData: IAlsdeBackground = new AlsdeBackground();
  submit$ = new Subject();
  personalInfo: any;
  countWords = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  closeAll:boolean = false;

  @ViewChildren('stepper') private steppers: QueryList<MatStepper> = {} as QueryList<MatStepper>;
  constructor(
    private _fb: FormBuilder,
    public _ui: UiStoreService,
    public _utilService: UtilService,
    public dialogService: DialogService,
    public route : ActivatedRoute,
    public appWizService : AppWizService,
    public certWizService : CertWizService,
    public userService: UserService,
    public ls : LocalStorageService,
    private http: HttpService,
    private step: MatStepper,
    public router: Router
  ) { 
    this.stepperFormGroup = this._fb.group({});
  }

  selectionChanged() {
    const stepElement = document.getElementById('cdk-step-label-2-'+this.step.selectedIndex);
    if (stepElement) {
      setTimeout(() => {
        const el: HTMLElement | null = stepElement;
        if (el) {
          el.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
        }
      }, 250);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe({
      next:(params)=>{
        this.appWizService.getAppWizard(params['appId']).subscribe(x => {
          this.addWizObj = x;
          this.openPendingArtifactOnLoad(x);
          this.initStepperFormGroup(x);
          this.getUserDemographics(this.addWizObj.userId);
          this.userService.BackgroundService(this.addWizObj.userId);
        });
      }
    });
    
  }

  getUserDemographics(alsdeId:string) {
    // const alsdeId = JSON.parse(this.ls.getItem('user')).alsdeId;
    this.userService.getUserDemographics(alsdeId).subscribe((res:any) => {
      this.personalInfo = res.demographics;
    });
  }

  ngAfterViewInit() {
    this.subscriptions = [
      this.userService.bgrData.subscribe(x => this.bgrData = x ),
      this.appWizService.appUpdated$.subscribe(x => this.addWizObj = x ),
      this._ui.expandedArtifactId.subscribe(this.updateStepperIndex.bind(this))
    ]
  }

  openPendingArtifactOnLoad(x:ApplicationWizard){
    // 6-28-23 BM scroll to the first that isn't at least SUBMIT value
    let pendingArtifact = x.artifacts.find(i=>i.artifactStatus < ARTIFACT_STATUS.SUBMIT);
    if (pendingArtifact) {
      let artifactId = pendingArtifact.artifactId||this.appWizService.appWizard.artifacts[0].artifactId;
      setTimeout(()=>this._ui.expandedArtifactId.next(artifactId),100);
    }
  }
  updateStepperIndex(artifactId: string) {
    if(!artifactId) return;
    // this.closeAll = false;
    let artifacts = this.appWizService.appWizard.artifacts;
    artifactId = artifactId || '';
    let userArtifacts = artifacts.filter(i=>ARTIFACTS_BY_USER.includes(i.key));
    let selectedIndex = userArtifacts.findIndex(i=>i.artifactId==artifactId);
    if (this.steppers && selectedIndex>=0) {
      this.steppers.forEach((stepper) => {
        try{
          stepper.selectedIndex = selectedIndex;
          this.closeAll = false;
        }catch{};
      });
    }else{
      //300ms delay required to run after onSteperClick
      setTimeout(()=>
        this.closeAll = true
      ,300); 
    }
  }

  onOpenClose(event: StepperSelectionEvent) {
    this.closeAll = false;
    let artifactId = event.selectedStep.label;
    if (this._ui.expandedArtifactId.value !== artifactId) {
      this._ui.expandedArtifactId.next(artifactId);
    }
  }

  onSteperClick(){
    setTimeout(()=>{
      if(this.closeAll){
        let artifacts = this.appWizService.appWizard.artifacts;
        let userArtifacts = artifacts.filter(i=>ARTIFACTS_BY_USER.includes(i.key));
        this.closeAll = false;
        this._ui.expandedArtifactId.next(userArtifacts[this.steppers.first.selectedIndex].artifactId);
      }
    },100);
  }

  canShowArtifact(key:string){
    let keys = this.appWizService.appWizard.artifacts.map(i=>i.key);
    return keys.includes(key);
  }

  emittedDate(event: any) {
    this.stepperFormGroup.patchValue(event)
  }

  isLocked(artifactKey: string){
    return this.appWizService.appWizard.artifacts.filter(x => x.key == artifactKey)[0]?.locked;
  }

  initStepperFormGroup(appWizard:ApplicationWizard){
    this.stepperFormGroup = this._fb.group({});
    appWizard.artifacts.forEach((artifact: Artifact)=>{
      this.stepperFormGroup.addControl(artifact.artifactId, new FormControl('', [Validators.requiredTrue]));
    })
  }

  deleteApp(){
    this._utilService.openMsgDialog('Are you sure you want to delete this application? This action cannot be undone.', 'Disclaimer', true)
    .afterClosed().subscribe((result) => { 
      if(result){
        this.appWizService.deleteApp().subscribe(()=>{
          this.router.navigate(['app']);
        })
      }
    });
  }

  setIsMobile(val:boolean){
    this._ui.isMobile = val;
  }

  getAppPDFDocId(){
    let doc = this.addWizObj.documents?.find((i:any)=>{
      return i.documentType == "Form" && i.documentSubType == this.addWizObj.approach;
    });
    return doc?.id;
  }
  viewDocument() {
    this.http.get('file', {id: this.getAppPDFDocId()}).subscribe((x:any) => {
      if(x.preSignedURL) {
        this._utilService.urlToBlob(x.preSignedURL, {}, "Application - "+this.addWizObj.appId+".pdf").subscribe();
      }
    });
  }

  checkAgentSignatureIsUnlocked(){
    let artifact = this.addWizObj.artifacts.find(i=>AGENT_ARTIFACTS_ACCEPTANCE.includes(i.key));
    return artifact && !artifact.locked
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
