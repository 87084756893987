export class IMessage {
  id: number = 0;
  title: string = 'de';
  subject?: string = 'fwewef';
  text: string = 'fwefwe';
  link?: string = '';
  linkName?: string = '';
  tooltip?: string = '';
  icon?: string = '';
  type?: string = '';
  typeIcon?: string = '';
  sent: string = "";
  status: string = '';
  messageId: string = '';
  body: string = '';
  read: any = null;
  attachment?: boolean = false;
  attachmentDocId?: string = '';
  isShowCancel?: boolean = false;
}

export class Message extends IMessage {

  constructor(options?: IMessage) {
    super();
    if(options){
      this.id = options.id;
      this.title = options.title;
      this.subject = options.subject;
      this.text = options.text;
      this.link = options.link;
      this.linkName = options.linkName;
      this.tooltip = options.tooltip;
      this.icon = options.icon;
      this.type = options.type;
      this.typeIcon = options.typeIcon;
      this.sent = options.sent;
      this.status = options.status;
      this.messageId = options.messageId;
      this.body = options.body;
      this.read = options.read;
      this.attachment = options.attachment;
      this.attachmentDocId = options.attachmentDocId;
      this.isShowCancel = options.isShowCancel ?? this.isShowCancel;
    }
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      subject: this.subject,
      text: this.text,
      link: this.link,
      linkName: this.linkName,
      tooltip: this.tooltip,
      icon: this.icon,
      type: this.type,
      typeIcon: this.typeIcon,
      sent: this.sent,
      status: this.status,
      messageId: this.messageId,
      body: this.body,
      read: this.read,
      attachment: this.attachment,
      attachmentDocId: this.attachmentDocId,
      isShowCancel: this.isShowCancel
    }
  }
}