<div class="px-4 py-5 my-0 text-center">
  
  <h1 class="display-5 fw-bold" style="font-size: 30px;">Welcome to ACE</h1>
  <h2 class="display-5 fw-bold">Alabama Certification for Educators</h2>
  <div class="col-12 mx-auto">
    <p class="lead mb-4">Login is required to access ACE. </p>
    <p class="text-start mx-4">
      The information provided by the Alabama Department of Education (“we,” “us,” or “our”) on <a target="_blank" href="{{environment.externalUrl.alsdeAce}}">{{environment.externalUrl.alsdeAce}}</a> (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION.

      The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS AND SERVICES, NOR WILL WE BE RESPONSIBLE FOR ANY SUCH TRANSACTIONS.    
    </p>
    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
      <button class="btn btn-primary btn-lg px-4 gap-3" (click)="login()">Login with AIM</button>
    </div>
  </div>
</div>