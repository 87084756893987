import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { APPLICATION_STATUS } from 'src/app/core/enums/application.enum';
import { USER_ROLES } from 'src/app/core/enums/user.enum';
import { AlsdeUser } from 'src/app/core/models/alsde-user.model';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-application-list-item',
  templateUrl: './application-list-item.component.html',
  styleUrls: ['./application-list-item.component.scss']
})
export class ApplicationListItemComponent {
  @Input() item: ApplicationWizard = {} as ApplicationWizard;
  user: AlsdeUser;

  APPLICATION_STATUS = APPLICATION_STATUS;
  constructor(
    private router: Router,
    public userService: UserService,
  ){
    this.user = this.userService.getUser();
  }

  redirect(Obj:any) {
    this.router.navigate(['/app/app-wizard/'+Obj.appId]);
  }

  appActionText(status:string){
    switch(status){
        case APPLICATION_STATUS.STARTED:
            return 'Continue Application'; break;
        case APPLICATION_STATUS.MOREINFO:
            return 'Submit More Info'; break;
        default:
            return "View Application"
    }
  }
}
