import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { AlsdeUser } from '../models/alsde-user.model';

@Directive({
  selector: '[grants]'
})
export class GrantsDirective {

  user:AlsdeUser;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public userService: UserService,
    ) { 
      this.user = this.userService.getUser();
  }

  @Input() set grants(list:string[]){
    console.log(list);
    if (this.user.permissions.find(i => list.includes(i))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
