// Angular Core
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LayoutModule } from '@angular/cdk/layout'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppConfiguration } from './app-configuration';

import { CustomInterceptor, ErrorInterceptor, JwtInterceptor } from './core/interceptors';
import { ErrortostringPipe } from './core/pipes';
import { ErrorLogService } from './core/services/error-log.service';


//Components
import { AppComponent } from './app.component';
import { HomePageComponent } from './views/pages/home-page/home-page.component';
import { DashboardComponent } from './views/partials/dashboard/dashboard.component';
import { CurrentCertsComponent } from './views/partials/current-certs/current-certs.component';
import { CertWizardComponent } from './views/pages/cert-wizard/cert-wizard.component';
import { FooterComponent } from './views/themes/footer/footer.component';
import { QuestionComponent } from './views/partials/shared/question/question.component';
import { CertWizControllerComponent } from './views/partials/cert-wiz-controller/cert-wiz-controller.component';
import { AppRequiredItemComponent } from './views/partials/application/app-required-item/app-required-item.component';
import { MessageDialogComponent } from './views/partials/shared/message-dialog/message-dialog.component';
import { CertItemComponent } from './views/partials/cert-item/cert-item.component';
import { AppWizardComponent } from './views/pages/app-wiz/app-wiz.component';
import { PersonalDataComponent } from './views/partials/application/form/personal-data/personal-data.component';
import { ProofOfCitizenshipComponent } from './views/partials/application/form/proof-of-citizenship/proof-of-citizenship.component';
import { MilitaryDeclarationComponent } from './views/partials/application/form/military-declaration/military-declaration.component';
import { ProfessionalStatusCriminalHistoryComponent } from './views/partials/application/form/professional-status-criminal-history/professional-status-criminal-history.component';
import { PraxxisAssessmentListComponent } from './views/partials/application/form/praxxis-assessment-list/praxxis-assessment-list.component';
import { AcceptanceSignatureComponent } from './views/partials/application/form/acceptance-signature/acceptance-signature.component';
import { HelpComponent } from './views/pages/help/help.component';
import { MessageCenterComponent } from './views/partials/message-center/message-center.component';
import { BackgroundReviewComponent } from './views/partials/background-review/background-review.component'
import { HeaderComponent } from './views/themes/header/header.component';
import { DashboardItemComponent } from './views/partials/dashboard-item/dashboard-item.component';
import { LayoutComponent } from './views/themes/layout/layout.component';
import { LoginComponent } from './views/pages/public/login/login.component';
import { QuestionWizardComponent } from './views/partials/shared/question-wizard/question-wizard.component';
import { FileUploadComponent } from './views/partials/shared/file-upload/file-upload.component';
import { BtnActionComponent } from './views/partials/shared/btn-action/btn-action.component';
import { PaymentComponent } from './views/partials/payment/payment.component';
import { EducationRecordComponent } from './views/partials/application/form/education-record/education-record.component';
import { EducationalExperienceComponent } from './views/partials/application/form/educational-experience/educational-experience.component';
import { PaymentErrorComponent } from './views/partials/payment-error/payment-error.component';
import { SupportComponent } from './views/pages/support/support.component';
import { ArtifactActionComponent } from './views/partials/application/artifact-action/artifact-action.component';
import { MoreInformationNeededComponent } from './views/partials/application/form/more-information-needed/more-information-needed.component';
import { BannerComponent } from './views/partials/shared/banner/banner.component';
import { EducationRecordSubstituteComponent } from './views/partials/application/form/education-record-substitute/education-record-substitute.component';
import { ClassInfoAaComponent } from './views/partials/application/form/class-info-aa/class-info-aa.component';
import { ClassInfoBComponent } from './views/partials/application/form/class-info-b/class-info-b.component';
import { ClassInfoAAlternativeComponent } from './views/partials/application/form/class-info-a-alternative/class-info-a-alternative.component';
import { ClassInfoATraditionalComponent } from './views/partials/application/form/class-info-a-traditional/class-info-a-traditional.component';
import { InternshipPlacementComponent } from './views/partials/application/form/internship-placement/internship-placement.component';
import { AlsdePersonComponent } from './views/partials/application/form/alsde-person/alsde-person.component';
import { SchoolPlacementComponent } from './views/partials/application/form/school-placement/school-placement.component';
import { TeamsContractDetailsComponent } from './views/partials/application/form/teams-contract-details/teams-contract-details.component';
import { EducationRecordHsComponent } from './views/partials/application/form/education-record-hs/education-record-hs.component';
import { EducationRecordTeComponent } from './views/partials/application/form/education-record-te/education-record-te.component';
import { ViewCertDialogComponent } from './views/partials/shared/dialogs/view-cert-dialog/view-cert-dialog.component';
import { RequestApplicantResubmitComponent } from './views/partials/shared/dialogs/request-applicant-resubmit-dialog/request-applicant-resubmit-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    DashboardComponent,
    CurrentCertsComponent,
    CertWizardComponent,
    HeaderComponent,
    FooterComponent,
    QuestionComponent,
    CertWizControllerComponent,
    AppRequiredItemComponent,
    MessageDialogComponent,
    ViewCertDialogComponent,
    RequestApplicantResubmitComponent,
    CertItemComponent,
    DashboardItemComponent,
    AppWizardComponent,
    PersonalDataComponent,
    ProofOfCitizenshipComponent,
    MilitaryDeclarationComponent,
    ProfessionalStatusCriminalHistoryComponent,
    PraxxisAssessmentListComponent,
    AcceptanceSignatureComponent,
    HelpComponent,
    MessageCenterComponent,
    BackgroundReviewComponent,
    LayoutComponent,
    LoginComponent,
    QuestionWizardComponent,
    FileUploadComponent,
    BtnActionComponent,
    PaymentComponent,
    EducationRecordComponent,
    SchoolPlacementComponent,
    EducationalExperienceComponent,
    PaymentErrorComponent,
    SupportComponent,
    ArtifactActionComponent,
    MoreInformationNeededComponent,
    BannerComponent,
    ErrortostringPipe,
    EducationRecordSubstituteComponent,
    EducationRecordTeComponent,
    EducationRecordHsComponent,
    ClassInfoAaComponent,
    ClassInfoBComponent,
    ClassInfoAAlternativeComponent,
    ClassInfoATraditionalComponent,
    InternshipPlacementComponent,
    AlsdePersonComponent,
    TeamsContractDetailsComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    FileUploadModule,
    ToastrModule.forRoot(),
  ],
  entryComponents: [
    MessageDialogComponent,
    ViewCertDialogComponent,
    RequestApplicantResubmitComponent
  ],
  providers: [
    AppConfiguration,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
    // { provide: ErrorHandler, useExisting : ErrorLogService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
