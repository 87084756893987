<div class="card ">
    <div class="title">
        <mat-icon color="accent" title="Checklist" class="title-icon">checklist</mat-icon>
        <h2>Certification Requirements</h2>
    </div>
    <div class="divider"></div>
    <p *ngIf="!isApp">Please complete the questionaire so that we can determine what is required to submit a complete application</p>
    <p></p>
    <h3>Requirements for Application</h3>
    <mat-progress-bar *ngIf="isApp" mode="determinate" value="{{percentComplete}}" color="accent" style="margin-bottom:20px;margin-top:20px;"></mat-progress-bar>
    <app-app-required-item *ngFor="let item of artifacts(); let index=index" [addWizObj]="addWizObj" [artifact]="item" [isApp]="isApp" [sequenceNumber]="index"></app-app-required-item>
</div>