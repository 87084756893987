import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { AlsdeBackground, IAlsdeBackground } from 'src/app/core/models/background.model';
import { HttpService } from 'src/app/core/services/http.service';
import { UserService } from 'src/app/core/services/user.service';


@Component({
  selector: 'app-view-cert-dialog',
  templateUrl: './view-cert-dialog.component.html',
  styleUrls: ['./view-cert-dialog.component.scss']
})
export class ViewCertDialogComponent {

  bgrData: IAlsdeBackground = new AlsdeBackground();
  demographics: any = {};

  alsdeId:string = "";
  constructor(
    private http: HttpService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ViewCertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.alsdeId = data.alsdeId;
    this.getUserDemographics();
  }

  getUserDemographics() {
    return this.userService.getUserDemographics(this.alsdeId).subscribe({
      next: (x: any) => {
        this.bgrData = x.background;
        this.demographics = x.demographics;
      },
      error: (err) => {
        console.log(err);
        this.bgrData = new AlsdeBackground()
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
