<div class="row button-container mt-2">
    <span class="d-flex justify-content-between align-items-center">
        <div class="d-flex float-start align-items-center">
            <button mat-stroked-button color="accent" 
                *ngIf="![ARTIFACT_KEY.PERSONAL,ARTIFACT_KEY.MOREINFO].includes(artifact.key)"
                (click)="back()" 
                [disabled]="disablBackOrNext()"
            >Back</button>
            <button mat-stroked-button color="accent" class="mx-2"
                *ngIf="!ARTIFACTS_SUBMIT.includes(artifact.key)"
                (click)="next()" 
                [disabled]="disablBackOrNext()"
            >Next</button>
            <button mat-stroked-button color="accent" class="mx-2" 
                *ngIf="ARTIFACTS_SUBMIT.includes(artifact.key)"
                (click)="next()" 
                [disabled]="disablSubmit()"
            >Submit</button>
        </div>
        <div class="d-flex float-end">
            <button mat-stroked-button color="accent"
                *ngIf="artifact.key != ARTIFACT_KEY.PERSONAL"
                (click)="reset();" 
                [disabled]="disableReset()"
            >Reset Section</button>
        </div>
    </span>
</div>