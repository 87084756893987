import { Component, Injector } from '@angular/core';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UserService } from 'src/app/core/services/user.service';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-teams-contract-details',
  templateUrl: './teams-contract-details.component.html',
  styleUrls: ['./teams-contract-details.component.scss']
})
export class TeamsContractDetailsComponent extends ArtifactBaseComponent {

  minFromDate: Date | string = new Date(1900, 0, 1);
  maxFromDate: Date | string = new Date(9999,0,0);
  minToDate: Date | string = new Date(1900, 0, 1);
  maxToDate: Date | string = new Date(9999,0,0);

  teachingFieldItems = [
    {
      "id": "1",
      "viewValue": "Mathematics",
      "value": "Mathematics"
    },
    {
      "id": "2",
      "viewValue": "Science",
      "value": "Science"
    }
  ];

  salaryAndExperienceItems = Array.from({ length: 36 }, (value, index) => {
    return {
      viewValue: "Step "+index,
      value: index,
    }
  });


  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService: AppWizService,
    public util: UtilService,
    public userService: UserService,
    private http: HttpService,
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      applicantFirstName: ['', []],
      applicantMiddleName: ['', []],
      applicantLastName: ['', []],
      alsdeId: ['', []],
      applicantSuffix: ['', []],
      applicantMaidenName: ['', []],

      scholasticYear: ['', []],
      contractType: ['', []],
      highestDegree: ['', []],
      advancedCredentials: ['', []],
      advancedContractYears: ['', []],
      preliminaryContractYears: ['', []],

      teachingField: ['', [Validators.required]],
      salaryAndExperience: ['', [Validators.required]],
      leaFinanceEmail: ['', [Validators.required,ValidationService.emailValidator]],
      contractStartDate: ['', [Validators.required]],
      contractEndDate: ['', [Validators.required]],
      contractDays: ['189', [Validators.required, Validators.min(1), Validators.max(189)]],

    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }

  setDefaultValues(){
    let appWizard = this.appWizService.appWizard;
    this.userService.getUserDemographics(appWizard.applicantId).subscribe({
      next:(user:any)=>{
        let pdata = user.demographics;
        let data = {
          applicantFirstName: pdata.firstName,
          applicantMiddleName: pdata.middleName,
          applicantLastName: pdata.lastName,
          alsdeId: pdata.alsdeId,
          applicantSuffix: pdata.suffix,
          applicantMaidenName: pdata.maidenName,

          contractType: appWizard.appExtensionsList[0].properties.contractType,
          advancedCredentials: appWizard.appExtensionsList[0].properties.advancedCredentials,
          scholasticYear: this.scholasticYear(),
          advancedContractYears: appWizard.appExtensionsList[0].properties.numberOfAdvancedContracts,
          preliminaryContractYears: appWizard.appExtensionsList[0].properties.numberofPreliminaryContracts,
          contractDays: '189',
        };
        this.interalForm.patchValue(data);
        let extensions = this.appWizService.appWizard.appExtensionsList[0].properties;
        extensions.applicantDOB = pdata.birthDetails?.dateOfBirth;
      }
    });

    this.http.get("highestdegree/?userId="+appWizard.applicantId).subscribe({
      next:(data:any)=>{
        let highestDegree = data?.degreeDesc ? data?.degreeDesc : "";
        this.interalForm.patchValue({highestDegree});
      }
    });
  }
  
  initJsonData() {
    if(!this.jsonData?.classinfo?.scholasticYear){
      this.setDefaultValues();
    }
    this.jsonData = this.jsonData || { classinfo: null };
    this.jsonData.classinfo = this.jsonData.classinfo || {};
    this.interalForm.patchValue(this.jsonData.classinfo);
  }
  validateJsonData() {
    this.artifact.sectionValid = !this.interalForm.invalid;
  }
  resetJsonData() {
    this.jsonData.classinfo = {};
    this.interalForm.reset();
    this.setDefaultValues();
  }
  override processBeforeSubmit() {
    this.jsonData = { classinfo: this.interalForm.value };

    let value = this.interalForm.value;
    let extensions = this.appWizService.appWizard.appExtensionsList[0].properties;
    extensions.teachingField = value.teachingField;
    extensions.salaryScheduleYearsExp = value.salaryAndExperience;
    extensions.LEAFinancialEmail = value.leaFinanceEmail;
    extensions.contractStartDate = value.contractStartDate;
    extensions.contractEndDate = value.contractEndDate;
    extensions.contractDays = value.contractDays;
    extensions.highestDegree = value.highestDegree;
    extensions.scholasticYear = value.scholasticYear;
    this.appWizService.appWizard.leaFinanceEmail = value.leaFinanceEmail;
  }

  dateChanges(type: string, event: any) {
    if(type == 'start') {
      this.minToDate = new Date(event);
      this.minToDate.setDate(this.minToDate.getDate() + 1);
    }
    else {
      this.maxFromDate = new Date(event);
      this.maxFromDate.setDate(this.maxFromDate.getDate() - 1);
    }
  }
  resetDate(){
    this.minToDate = new Date(1900, 0, 1);
    this.maxFromDate = new Date();
  }

  scholasticYear(){
    let cDate = new Date();
    let startYear = cDate.getFullYear();
    let midDate = new Date(startYear + "-06-30");
    if(cDate.getTime() < midDate.getTime()){
      startYear = startYear-1;
    }
    return `${startYear}-${startYear+1}`;
  }
}
