export class IEndorsement{
    name!:string
    code!:string
}

export class Endorsement extends IEndorsement{
    constructor(options?: IEndorsement){
        super();
        if(options){
            this.name = options.name
            this.code = options.code
        }
    }

    toJSON(){
        return {
            name: this.name,
            code: this.code
        }
    }
}