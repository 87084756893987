<div class="py-3">
    <div class="description">Praxis test codes entered must be the current Praxis exam accepted by the ALSDE. Scores
        must also be received by the ALSDE via electronic submission from ETS. Please be sure you have entered the
        correct Praxis test code(s) and that scores have been sent to the ALSDE via electronic submission. For each
        teaching field sought, a passing score must be earned for all required assessments. Please indicate the
        appropriate Alabama-prescribed Praxis Subject Assessments taken and that is in effect on the date the
        application is received in the Educator Certification Section. The testing requirement must be met prior to
        application submission.</div>
    <div class="primary">

        <div class="table-section">
            <h3>Praxis Assessment List</h3>
            <span><i></i></span>

            <table class="table table-striped table-mobile-responsive table-mobile-sided"
                *ngIf="jsonData.records.length > 0">
                <thead>
                    <tr>
                        <th scope="col">Teaching Field</th>
                        <th scope="col">Grade Level</th>
                        <th scope="col">Test Number</th>
                        <th scope="col">Praxis Name</th>
                        <th scope="col">Date Test Passed</th>
                        <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let element of jsonData.records;let i = index;">
                        <td scope="row" data-content="Teaching Field">{{element.teachingField}}</td>
                        <td data-content="Grade Level">{{element.gradeLevel}}</td>
                        <td data-content="Test Number">{{element.testNumber}}</td>
                        <td data-content="Praxis Name">{{element.praxisName}}</td>
                        <td data-content="Date Test Passed">{{element.testPassedDate | date:'MM/dd/yyyy'}}</td>
                        <td data-content="Remove" *ngIf="!isReadOnly()"><mat-icon (click)="deleteRecord(element)"
                                style="font-size:32px; color:red;">close</mat-icon></td>
                    </tr>
                </tbody>
            </table>
            <div class="px-2">
                <form [formGroup]="interalForm" (ngSubmit)="createRecord()">
                    <div class="row form-group">
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Teaching Field" placeholder="Teaching Field"
                                controlName="teachingField" [type]="'text'"></app-form-control>
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Grade Level" placeholder="Grade Level"
                                controlName="gradeLevel" [type]="'text'"></app-form-control>
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control type="text" [form]="interalForm" title="Test Number"
                                placeholder="Test Number" controlName="testNumber"></app-form-control>
                            <!-- <input type="text" class="form-control" id="testNumber" formControlName="testNumber" placeholder="Test Number"> -->
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control type="text" [form]="interalForm" title="Praxis Name"
                                placeholder="Praxis Name" controlName="praxisName"></app-form-control>
                            <!-- <input type="text" class="form-control" id="praxisName" formControlName="praxisName" placeholder="Praxis Name"> -->
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Test Passed Date"
                                placeholder="Test Passed Date" controlName="testPassedDate"
                                [type]="'date'"></app-form-control>
                            <!-- <input formControlName="testPassedDate" format="dd/mm/yyyy" class="form-control" type="date" id="birthday" name="birthday"> -->
                        </div>
                        <div class="col-md-4 pt-3">
                            <button mat-stroked-button color="accent" [disabled]="!interalForm.valid">Add Assessment
                                Result</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>