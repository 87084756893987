<div *ngFor="let item of getAppList()" class="pb-2">
    <div class="panel">
        <app-application-list-item [item]="item"></app-application-list-item>
    </div>
</div>

<div class="pb-2" *ngIf="getAppList().length == 0">
    <div class="panel">
        No applications found.
    </div>
</div>