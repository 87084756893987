import { Component, OnInit, OnChanges, Injector } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { AppHttpClient } from 'src/app/core/services/app-http-client.service';

@Component({
  selector: 'app-school-placement',
  templateUrl: './school-placement.component.html',
  styleUrls: ['./school-placement.component.scss']
})
export class SchoolPlacementComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  schoolList:any[] = [];
  constructor(
    private injector: Injector,
    public fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService,
    public http : AppHttpClient, 
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      school: [null, [Validators.required]],
    });
    this.fetchSchoolList();
  }

  fetchSchoolList(){
    this.http.get("facilities", {
      systemcode:this.appWizService.appWizard.systemCode,
      // sitecode:this.appWizService.appWizard.siteCode,
    })
    .subscribe({
      next:(res:any)=>{
        this.schoolList = res.map((i:any)=>{
          i.value = JSON.parse(JSON.stringify(i));
          i.viewValue = i.siteLongName;
          return i;
        });
      }
    })
  }

  createRecord(){
    this.jsonData.records.push(this.interalForm.value.school);
    this.interalForm.reset();
    this.validateSection();
  }
  deleteRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
  }

  // BM 9-21-23 Need to set expected count to the record count
  override processBeforeSubmit(){
    this.artifact.expectedCount = this.jsonData.records?.length;
    if(this.jsonData.records?.length > 0){
      let extensions = this.appWizService.appWizard.appExtensionsList[0].properties;
      extensions.hardtoStaff = this.jsonData.records[0].hardtoStaff;
      extensions.LEASiteName = this.jsonData.records[0].siteName;
      extensions.LEASiteType = this.jsonData.records[0].siteTypeDescription;
      extensions.LEASystemName = this.jsonData.records[0].systemName;
      extensions.schoolPlacements = this.jsonData.records;

      let superintendent = this.schoolList.find(i=>(i.value.staffPosition||"").toLowerCase() == "superintendent") || {value:{}};
      extensions.LEASuperintendent = superintendent.value.staffContactName;
      extensions.LEASuperintendentName = superintendent.value.staffContactName;
      extensions.LEASuperintendentEmail = superintendent.value.staffEmail;
    }
  }


}
