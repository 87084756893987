<div>
    <div class="description py-3">Please provide internship placement info</div>
    <div class="container">
        <div class="row">
            <div class="my-3 table-section" *ngIf="jsonData.records.length > 0">
                <div class="row overflow-auto">
                    <table class="table table-striped table-mobile-responsive table-mobile-sided">
                        <thead>
                            <tr>
                                <th scope="col">School Placement</th>
                                <th scope="col">Grade Placement</th>
                                <th scope="col">ALSDE ID of Cooperating Teacher</th>
                                <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of jsonData.records;">
                                <td scope="row" data-content="School Placement">{{element.schoolPlacement}}</td>
                                <td data-content="Grade Placement">{{element.gradePlacement}}</td>
                                <td data-content="alsdeId of Cooperating Teacher">{{element.alsdeId}} ({{element.firstName}} {{element.lastName}})</td>
                                <td data-content="Remove" *ngIf="!isReadOnly()">
                                    <mat-icon (click)="deleteRecord(element)" style="font-size:32px; color:red;">close</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="px-2 pt-3">
                <div class="row form-group">
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="School Placement"
                            placeholder="School Placement" controlName="schoolPlacement"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="select" [form]="interalForm" title="Grade Placement"
                            placeholder="Grade Placement" controlName="gradePlacement" [multiple]="true" [data]="gradePlacementList"></app-form-control>
                    </div>
                   
                    <div class="col-12">
                        <h2 class="text-start">Lookup the Cooperating Teacher</h2>
                        <app-user-lookup (onUserFound)="onUserFound($event)"></app-user-lookup>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <button mat-stroked-button color="accent" [disabled]="!interalForm.valid || jsonData.records.length >= 5"
                            (click)="createRecord()">Add Record</button>
                            <mat-error *ngIf="interalForm.valid && jsonData.records.length >= 5">max 5 records allowed</mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>