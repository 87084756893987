import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NOTIFY } from 'src/app/core/constants/notification.constants';
import { HttpService } from 'src/app/core/services/http.service';
import { UtilService } from 'src/app/core/services/util.service';


@Component({
  selector: 'app-request-applicant-resubmit-dialog',
  templateUrl: './request-applicant-resubmit-dialog.component.html',
  styleUrls: ['./request-applicant-resubmit-dialog.component.scss']
})
export class RequestApplicantResubmitComponent {
  appId:string = "";
  helpText = "Please provide all the information relating to why this application is not satisfactory. This information will be sent to the applicant to allow for remediation.";
  interalForm
  constructor(
    private router: Router,
    private http: HttpService,
    private fb: FormBuilder, 
    public util: UtilService,
    public dialogRef: MatDialogRef<RequestApplicantResubmitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.appId = data.appId;
    this.interalForm = this.fb.group({
      rejectReason: new FormControl(null, [Validators.required, Validators.minLength(10)])
    });
  }

  requestApplicantResubmit() {
    return this.http.post('app/reject', {
      appId: this.appId,
      rejectReason: this.interalForm.value.rejectReason,
      rejectedRoles: ["Applicant"]
    }).subscribe({
      next: (x: any) => {
        this.closeDialog();
        this.util.openToastr('success',NOTIFY.APPLICATION_REJECT.SUCCESS)
        this.router.navigate(['/']);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
