<div class="panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon *ngIf="cert.valid" color="accent" class="panel-icon" title="valid" >book
                </mat-icon>
                <mat-icon *ngIf="!cert.valid" color="accent" class="panel-icon" title="invalid" >book
                </mat-icon>
                <span class="px-2 ">{{cert.title}}</span>
            </mat-panel-title>
            <!-- <mat-panel-description></mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="content">
            <div class="item">Valid: <span class="value">
                <!-- {{cert.certDate | date:'MM/dd/yyyy'}} - {{cert.expires | date:'MM/dd/yyyy'}} -->
                {{cert.certDate|date:'mediumDate'}} - {{cert.expires|date:'mediumDate'}}
            </span> </div>
            <div *ngIf="cert.status =='Valid'" class="item">Status: <span class="value complete">{{cert.status}}</span> </div>
            <div *ngIf="cert.status !='Valid'" class="item">Status: <span class="value pending">{{cert.status}}</span></div>
            <h3 class="mb-0 mt-2"><b>Endorsements:</b></h3>
            <ul><li *ngFor="let item of cert.endorsements">{{item.name}}</li></ul>
        </div>
        <a *ngIf="cert.valid" mat-stroked-button color="accent" (click)="downloadCert()" >Download Certificate</a>
    </mat-expansion-panel>
</div>

