<form [formGroup]="interalForm">
<div>
    <div class="row pt-2 d-flex">
        <div>
            Data in this section comes from your 
            <a [href]=environment.aimURL target="_blank" *ngIf="!readonly">AIM account</a>
            <span *ngIf="readonly"><b>AIM account</b></span>
            . If anything listed is incorrect, please update your information in
            your 
            <a [href]=environment.aimURL target="_blank" *ngIf="!readonly">AIM account</a>
            <span *ngIf="readonly"><b>AIM account</b></span>
            and refresh this application
            <mat-icon style='padding-right: 10px;vertical-align: middle;' 
                color="accent" 
                title="More Information"
                (click)="util.openMsgDialog('To update information in your AIM account (such as your name or address), you will need to login to AIM (<a href=' + environment.aimURL + '>' + environment.aimURL + '</a>)')"
            >help_outline
            </mat-icon>

           
        </div>
    </div>
    <div class="px-2 pt-3">
        <div class="row form-group">
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" readonly="readonly" formControlName="firstName">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Middle Name</mat-label>
                    <input matInput placeholder="Middle Name" readonly="readonly" formControlName="middleName">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" readonly="readonly" formControlName="lastName">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Title</mat-label>
                    <input matInput placeholder="Title" readonly="readonly" formControlName="title">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Suffix</mat-label>
                    <input matInput placeholder="Suffix" readonly="readonly" formControlName="suffix">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Maiden Name</mat-label>
                    <input matInput placeholder="Maiden Name" readonly="readonly" formControlName="maidenName">
                </mat-form-field>
            </div>
        </div>
        <div class="row my-3 form-group">
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Address 1</mat-label>
                    <input matInput placeholder="Street Address" readonly="readonly" formControlName="address1">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Address 2</mat-label>
                    <input matInput placeholder="Street Address" readonly="readonly" formControlName="address2">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>City</mat-label>
                    <input matInput placeholder="City" readonly="readonly" formControlName="city">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>State</mat-label>
                    <input matInput placeholder="State" readonly="readonly" formControlName="state">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="Zip Code" readonly="readonly" formControlName="zipCode">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Country of Citizenship</mat-label>
                    <input matInput placeholder="Country of Citizenship" readonly="readonly" formControlName="country">
                </mat-form-field>
            </div>
        </div>
        <div class="row my-3 form-group">
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Cell Phone</mat-label>
                    <input matInput placeholder="Cell Phone" readonly="readonly" formControlName="cellPhone">
                </mat-form-field>
            </div>
            
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Home Phone</mat-label>
                    <input matInput placeholder="Home Phone" readonly="readonly" formControlName="homePhone">
                </mat-form-field>
            </div>
            
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Work Phone</mat-label>
                    <input matInput placeholder="Work Phone" readonly="readonly" formControlName="workPhone">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Personal Email Address</mat-label>
                    <input matInput placeholder="Personal Email Address" readonly="readonly" formControlName="personalEmail">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Work Email Address</mat-label>
                    <input matInput placeholder="Work Email Address" readonly="readonly" formControlName="workEmail">
                </mat-form-field>
            </div>
        </div>

        <div class="row my-3 form-group">
            <div class="col-md-4" formGroupName="birthDetails">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Date of Birth</mat-label>
                    <input matInput placeholder="Date of Birth" readonly="readonly" formControlName="dateOfBirth">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Sex/Gender</mat-label>
                    <input matInput placeholder="Sex/Gender" readonly="readonly" formControlName="sex">
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="raceEthnicity">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Ethnicity</mat-label>
                    <input matInput placeholder="Ethnicity" readonly="readonly" formControlName="ethnicity">
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="raceEthnicity">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Race</mat-label>
                    <input matInput placeholder="Race" readonly="readonly" formControlName="race">
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="driverLicense">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Drivers License/State Issued ID</mat-label>
                    <input matInput placeholder="Drivers License/State Issued ID" readonly="readonly" formControlName="licenseNumber">
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="driverLicense">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Expiration Date</mat-label>
                    <input matInput placeholder="Expiration Date" readonly="readonly" formControlName="expirationDate">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Live out of the United States</mat-label>
                    <input matInput placeholder="Live out of the United States" readonly="readonly" formControlName="liveInUnitedState">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Stationed on a US Military Base</mat-label>
                    <input matInput placeholder="Stationed on a US Military Base" readonly="readonly" formControlName="stationedOnUsMilitaryBase">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill" class="w-100 form-field">
                    <mat-label>Employed at in an Alabama Educational Institution</mat-label>
                    <input matInput placeholder="Employed at in an Alabama Educational Institution" readonly="readonly" formControlName="employedAlabamaEducationalInstitution">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
</form>