
<div class="panel" *ngIf="canShowArtifact()">
    <mat-expansion-panel [ngStyle]="ngStyle()"
        (opened)="onOpenClose(artifact, true)"
        (closed)="onOpenClose(artifact, false)"
        [expanded]="expandedArtifactId === artifact.artifactId"
    >
        <mat-expansion-panel-header class="py-2">
            <mat-panel-title>
                <div class="d-flex flex-row">
                    <span class="mat-step-header"><div class="mat-step-icon">
                        <div class="mat-step-icon-content">{{sequenceNumber+1}}</div>
                    </div></span>
                    <mat-icon style='padding-right: 0px;' class="title-icon" [title]="artifact.artifactResponsibility=='user' ? 'user responsible' : 'third-party responsible'" *ngIf="artifact.type != 'mixed'">
                       {{artifact.artifactResponsibility == 'user' ? 'person' : 'business'}}
                    </mat-icon>
                    <mat-icon style='padding-right: 10px;' class="title-icon" [title]="artifact.type" *ngIf="artifact.type == 'mixed'">person_add</mat-icon>
                    <mat-icon *ngIf="artifact.artifactStatus == ARTIFACT_STATUS.PACKAGECOMPLETE" color="accent" style='padding-right: 10px;' class="title-icon" title="Item Complete">check_box</mat-icon>
                    <mat-icon *ngIf="artifact.artifactStatus == ARTIFACT_STATUS.COMPLETE" color="accent" style='padding-right: 10px;' class="title-icon" title="Item Complete">check_box</mat-icon>
                    <mat-icon *ngIf="artifact.artifactStatus == ARTIFACT_STATUS.OUTSTANDING" color="accent" style='padding-right: 10px;' class="title-icon" title="Item Outstanding">check_box_outline_blank</mat-icon>
                    <mat-icon *ngIf="artifact.artifactStatus == ARTIFACT_STATUS.SUBMIT" color="accent" style='padding-right: 10px;' class="title-icon" title="Item Pending">indeterminate_check_box</mat-icon>
                    <mat-icon *ngIf="artifact.artifactStatus == ARTIFACT_STATUS.RESUBMIT" color="accent" style='padding-right: 10px;' class="title-icon" title="Item Resubmit">disabled_by_default</mat-icon>
                </div>
                <div class="ps-1" style="width: calc(100% - 74px);">
                    <span>{{artifact.name || artifact.type}} </span>
                </div>
                <div class="d-flex flex-row icon-color-gray me-1">
                    <mat-icon *ngIf="artifact.locked" title="Locked">lock</mat-icon>
                    <mat-icon *ngIf="artifact.visibility == 'readonly'" title="Read Only!">edit_off</mat-icon>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
            <div class="py-2" *ngIf="artifact.description">{{artifact.description}}</div>
            <div class="item">
                Role: {{artifact.role}}
            </div>
            <div class="item" *ngIf="artifact.type == ARTIFACT_TYPE.TranscriptArtifact">
                <div class="pb-2" *ngIf="isApp">
                    <mat-checkbox
                        (change)='TranscriptArtifactChecked()' 
                        [disabled]="artifact.locked" 
                        [(ngModel)]="artifact.sectionValid"
                    >I will request my transcripts to be sent to ALSDE</mat-checkbox>
                </div>
            </div>
            <div class="item">
                Status: 
                <span class="value" [ngClass]="ARTIFACT_STATUS_CLASS[artifact.artifactStatus]">
                {{ARTIFACT_STATUS_LABEL[artifact.artifactStatus]}}
                </span>
            </div>
            <div class="item d-flex">Date Completed: 
                <div class="value" *ngIf="artifact.key!='application-fee'">{{artifact.completedDate | date:'MM/dd/yyyy' }}</div>
                <span *ngIf="artifact.key=='application-fee'">
                    <div class="value">{{artifact.completedDate | date:'MM/dd/yyyy' }}</div>
                </span>
            </div>
            <div *ngIf="artifact.key=='application-fee'">
                <div>Total Amount: <span>{{feeSummary()?.feeTotal | currency}}</span></div>
                <div>Credit Amount: <span>{{feeSummary()?.creditAmount | currency}}</span></div>
                <div>Paid Amount: <span>{{feeSummary()?.feeTotalPaid | currency}}</span></div>
                <div>Total Received: <span>{{feeSummary()?.totalReceived | currency}}</span></div>
            </div>
            <div *ngIf="isApp">
                <div class="float-end" *ngIf="artifact.key=='application-fee' && artifact.artifactStatus != ARTIFACT_STATUS.COMPLETE">
                    <div class="ps-2 pt-2">
                        <button mat-stroked-button color="accent" class="w-100" (click)="paymentRoute()" [disabled]="isReadonly()" >{{feeSummary()?.balanceAmount > 0 ? 'Pay': 'Use Credit'}}</button>
                    </div>
                </div>
                <div class="float-end" *ngIf="artifact.type == ARTIFACT_TYPE.FormDownloadArtifact">
                    <div class="ps-2 pt-2">
                        <button mat-stroked-button color="accent" class="w-100" (click)="download(artifact)">Download</button>
                    </div>
                </div>
                <div class="float-end" *ngIf="artifact.type == ARTIFACT_TYPE.FileUploadArtifact">
                    <div class="ps-2 pt-2">
                        <app-file-upload  
                            [appConfig]="appConfig.fileUploadConfig" 
                            [btnName]="'Upload Supporting Document'" 
                            [artifact]="artifact" 
                            [type]="ARTIFACT_KEY.UPLOADEXISTING"
                            [uploadSummary]="artifact.documents"
                            [artifactUpload]="true"
                            [artifact]="artifact"
                            [locked]="artifact.locked"
                            [docTypes]="artifact.docTypes">
                        </app-file-upload>
                    </div>
                </div>
                <div class="float-end" *ngIf="!artifact.locked && artifact.type == ARTIFACT_TYPE.BgrArtifact">
                    <button mat-stroked-button color="accent" (click)="utilService.gotToFieldPrint()" [disabled]="userService.isEduAgent()">Go to Fieldprint</button>
                </div>
            </div>
            <p> {{artifact.text}}</p>
            <ng-container *ngIf="isApp">
                <app-btn-action *ngFor="let action of artifact.actions" [action]="action" (onClick)="_ui.selectedTab.appWizard=1"></app-btn-action>
            </ng-container>
        </div>
    </mat-expansion-panel>
</div>
