export class IAuth {
    token?: string;
}
    
export class Auth extends IAuth{

    constructor(auth?:IAuth){
        super();
        if(auth){
            this.token = auth?.token;
        }
    }

    toJSON(){
        return {
            token : this.token,
        }
    }
}