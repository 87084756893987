export enum APPLICATION_STATUS{
    'STARTED' = 'started',
    'SUBMITED' = 'submitted',
    'FEE-REQUESTED' = 'fee-requested',
    'MOREINFO' = 'more-info-needed',
    'COMPLETE' = 'application-package-complete',
    'APPROVED' = 'approved',
    'DELETED' = 'deleted',
    'EXPIRED' = 'expired',
    'DENIED' = 'denied',

    // 'PENDING' = "1",
    // 'VERIFIED' ="2",
    // 'FAILED' = "3",
}

export enum APPLICATION_APPROACH{
    'OTH' = 0,
    'CBT' = 1,
    'RTF_TBD' = 2,
    'CTF_TBD' = 3,
}

export let APPLICATION_ACTION_COMPELTE_STATUSES = [
    APPLICATION_STATUS.APPROVED,
    APPLICATION_STATUS.EXPIRED,
    APPLICATION_STATUS.DENIED,
];