import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  inMemoryStorage: { [key: string]: string } = {};
  isSupported = false;
  constructor() {
    try {
      const testKey = "__some_random_key_you_are_not_going_to_use__";
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      this.isSupported = true;
    } catch (e) {
      console.log('localStorage error handling');
    }
  }

  clear(): void {
    if (this.isSupported) {
      localStorage.clear();
    } else {
      this.inMemoryStorage = {};
    }
  }

  getItem(name: string): any {
    if (this.isSupported) {
      return localStorage.getItem(name);
    }
    if (this.inMemoryStorage.hasOwnProperty(name)) {
      return this.inMemoryStorage[name];
    }
    return null;
  }

  key(index: number): string | null {
    if (this.isSupported) {
      return localStorage.key(index);
    } else {
      return Object.keys(this.inMemoryStorage)[index] || null;
    }
  }

  removeItem(name: string): void {
    if (this.isSupported) {
      localStorage.removeItem(name);
    } else {
      delete this.inMemoryStorage[name];
    }
  }

  setItem(name: string, value: string): void {
    if (this.isSupported) {
      localStorage.setItem(name, value);
    } else {
      this.inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  }

  length(): number {
    if (this.isSupported) {
      return localStorage.length;
    } else {
      return Object.keys(this.inMemoryStorage).length;
    }
  }

}
