import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public ls: LocalStorageService,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authService.getAuth().token;
    if (token) {
      if (this.isTokenExpired(token)) {//check token expired
        this.authService.logout();
        return false;
      } else {
        return true;
      }
    }
    this.ls.setItem('afterlogin-redirect-url',window.location.href);
    window.location.href = '/app/auth/login';
    return false;
  }

  private isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return new Date().getTime() >= expiry*1000;
  }

}
