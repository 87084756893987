<div class="mt-1">
    <div class="description my-1">Please complete the Teams Contract information below</div>
    <div class="container">
        <form [formGroup]="interalForm">
            <div class="row form-group">
                <div class="col-12">
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Applicant First Name" 
                                placeholder="Applicant First Name" controlName="applicantFirstName" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Applicant Middle Name" 
                                placeholder="Applicant Middle Name" controlName="applicantMiddleName" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Applicant Last Name" 
                                placeholder="Applicant Last Name" controlName="applicantLastName" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="ALSDE Id" 
                                placeholder="ALSDE Id" controlName="alsdeId" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Applicant Suffix" 
                                placeholder="Applicant Suffix" controlName="applicantSuffix" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Applicant Maiden Name" 
                                placeholder="Applicant Maiden Name" controlName="applicantMaidenName" [readonly]="true"></app-form-control>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Scholastic Year" 
                                placeholder="Scholastic Year" controlName="scholasticYear" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Contract Type" 
                                placeholder="Contract Type" controlName="contractType" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Highest Degree" 
                                placeholder="Highest Degree" controlName="highestDegree" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Advanced Credentials" 
                                placeholder="Advanced Credentials" controlName="advancedCredentials" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Advanced Contract Years" 
                                placeholder="Advanced Contract Years" controlName="advancedContractYears" [readonly]="true"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Preliminary Contract Years" 
                                placeholder="Preliminary Contract Years" controlName="preliminaryContractYears" [readonly]="true"></app-form-control>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="select" [form]="interalForm" title="Teaching Field" 
                                placeholder="Teaching Field" controlName="teachingField" [data]="teachingFieldItems"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="select" [form]="interalForm" title="Salary Schedule/ Years Experience" 
                                placeholder="Salary Schedule/ Years Experience" controlName="salaryAndExperience" [data]="salaryAndExperienceItems"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="LEA Finance Email" 
                                placeholder="LEA Finance Email" controlName="leaFinanceEmail"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="date" [form]="interalForm" title="Contract Start Date" 
                                placeholder="Contract Start Date" controlName="contractStartDate" [minDate]="minFromDate" [maxDate]="maxFromDate"
                                (dateChange)="dateChanges('start', $event)"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="date" [form]="interalForm" title="Contract End Date" 
                                placeholder="Contract End Date" controlName="contractEndDate" [minDate]="minToDate" [maxDate]="maxToDate"
                                (dateChange)="dateChanges('end', $event)"></app-form-control>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-form-control type="text" [form]="interalForm" title="Contract Days" 
                                placeholder="Contract Days" controlName="contractDays"></app-form-control>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>