import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BGR_STATUS, FAILED_BGRS } from 'src/app/core/enums/background.enum';
import { DialogService } from 'src/app/core/services/dailog.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.scss']
})
export class UserLookupComponent {

  FAILED_BGRS = FAILED_BGRS
  BGR_STATUS = BGR_STATUS
  @Output() onUserFound: EventEmitter<any> = new EventEmitter();
  @Input() alsdeUser:any = {demographics:{alsdeId:""}};
  formAlsdeId: string = "";

  constructor(
    public userService: UserService,
    public dialogService: DialogService,
  ){}

  searchUser(){ 
    this.userService.getUserDemographics(this.formAlsdeId).subscribe({
      next:(user)=>{
        this.emitUser(user)
      },
      error: ()=>{
        this.emitUser({demographics:{alsdeId:""}});
      }
    })
  }
  
  emitUser(user:any){
    this.alsdeUser = user;
    this.onUserFound.emit(this.alsdeUser);
  }

  emailAddresses(){
    return this.alsdeUser?.demographics?.emailAddresses?.map((i:any)=>i.emailAddress);
  }

  reset(){
    this.formAlsdeId = "";
    if(this.alsdeUser.background){
      this.emitUser({demographics:{alsdeId:""}});
    }
  }
}
