<!-- {{docTypes|json}} -->
<div class="pt-2 clearfix uploadBtnSec">
    <div>
        Supported file formats are *.pdf, *.png, *.jpeg, *.jpg, *.doc, *.txt, *.tiff, *.docx.
      <br> 
      Maximum file size limit is 10 MB per file. Password protected or encrypted file cannot be uploaded. 
      <br>
      <div class="text-danger">Maximum upload limit is 5 file(s).</div>
    </div>
</div>

<ng-container *ngFor="let docType of documents">
<ng-container *ngFor="let subType of docType.subTypes">
    <app-file-upload 
        (emitData)="onDocumentUpdate();" 
        [appConfig]="appConfig.citUploaderConfig" 
        [btnName]="docType.displayName+' - '+subType.displayName" 
        [artifact]="artifact" 
        [type]="ARTIFACT_KEY.MOREINFO"
        [uploadSummary]="subType.documents"
        [docTypes]="[docType]"
        [locked]="locked"
        [hideValidation]="true"
    ></app-file-upload>
    <hr>
</ng-container>
</ng-container>
