import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnswerModel } from 'src/app/core/models/answer-model';
import { CertificationWizard } from 'src/app/core/models/certification-wizard.model';
import { QuestionModel } from 'src/app/core/models/question.model';
import { CertWizService } from 'src/app/core/services/cert-wiz.service';
import { QuestionService } from 'src/app/core/services/question.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-question-wizard',
  templateUrl: './question-wizard.component.html',
  styleUrls: ['./question-wizard.component.scss']
})
export class QuestionWizardComponent implements OnInit {

  @Input() public questions: QuestionModel[] = [];
  question : QuestionModel = new QuestionModel();
  selectedAnswers : string[] = [''];

  constructor(
    public certWizService : CertWizService,
    public questionService: QuestionService,
    public utilService: UtilService,
  ) { }
  
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.selectedAnswers = [''];
    if(this.questions.length){
      this.question = this.questions[this.questions.length-1];
      if(this.question.selectedAnswers.length){
        this.selectedAnswers = [];
        this.question.selectedAnswers.forEach(selectedAnswer=>{
          this.selectedAnswers.push(selectedAnswer.id);
          let answer = this.question.answers.find(answer=>selectedAnswer.id == answer.id);
          if(answer){
            answer.textboxValue = selectedAnswer.answerText.replace(answer.answerText,"").trim();
          }
        })  
      }
    }
  }

  // make SelectedAnswers Empty If AnswerType is Single
  makeSelectedAnswersEmpty(){
    if(this.question.answerType.name == 'single'){
      this.question.selectedAnswers = [];
    }
  }

  //check selected answer supports certification approach or not
  valueChanged(event:any, answer:AnswerModel){
    if(!event.source){
      return;
    }
    this.makeSelectedAnswersEmpty();
    if(event.source.checked){
      this.question.selectedAnswers.push(new AnswerModel(answer));
      this.selectedAnswers.push(answer.id);
      if(answer.terminationMessage?.text){
        this.utilService.openMsgDialog(answer.terminationMessage.text, 'Certification Approach not Currently Supported')
      }
    }else{
      this.question.selectedAnswers = this.question.selectedAnswers.filter((i:AnswerModel)=>i.id != answer.id);
      this.selectedAnswers = this.question.selectedAnswers.map(i=>i.id);
    }
  }

  valueChangedTextbox(answer:AnswerModel){
    let value = answer.textboxValue;
    let selectedAnswer = this.question.selectedAnswers.find(i=>i.id == answer.id)
    if(selectedAnswer){
      selectedAnswer.answerText = answer.answerText +" "+ value;
    }
  }

  next(){
    this.certWizService.next().subscribe({
      next: (certWizard:CertificationWizard)=>{
        if(certWizard.isComplete){
          console.log("wizard complete");
        }
      }
    });
  }

  back(){
    this.certWizService.back().subscribe();
  }

  submit(){
    // alert("submit");
  }

  questionTooltip(msg?: string) {
    if(msg){
      this.utilService.openMsgDialog(msg);
    }
  }

  answerTooltip(msg?: string) {
    if(msg){
      this.utilService.openSnackBar(msg);
    }
  }


  // return false if any selectedAnswers has terminationMessage()
  // return true if any selectedAnswers have nextQuestionId
  // return true if all answers not have nextQuestionId(this is the last question)
  canNext(){
    if(['text', 'dropdown'].includes(this.question.answerType.name)){
      let isFilledAnswers = this.question.selectedAnswers.filter(i=>i.answerText).length;
      return isFilledAnswers > 0;
    }
    if(this.question.selectedAnswers.length){
      let hasTerminationMessage = this.question.selectedAnswers.filter(i=>i.terminationMessage).length;
      let isShowTextboxSelected = this.question.selectedAnswers.filter(i=>i.showTextbox).length;
      let hasShowTextbox = this.question.answers.filter(i=>isShowTextboxSelected && i.showTextbox && !i.textboxValue).length;
      return hasTerminationMessage == 0 && hasShowTextbox==0;
    }
    return false;
  }

  onUserFound(user:any){
    console.log("Demographic user found",user);
    if(user.demographics.alsdeId){
      let answer = new AnswerModel(this.question.answers[0]);
      answer.answerText = user.demographics.alsdeId
      this.question.selectedAnswers = [answer];
      this.certWizService.certWizard.applicantId = user.demographics.alsdeId;
    }else{
      this.question.selectedAnswers = [];
      this.certWizService.certWizard.applicantId = "";
    }
  }

  disabledOnMaxSelectionsReached(answer:AnswerModel){
    return (this.question.selectedAnswers.length >= this.question.answerType.maxSelections) && !this.question.selectedAnswers.map(i=>i.answerText).includes(answer.answerText) && this.question.answerType.maxSelections > 0;
  }
}
