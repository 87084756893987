<mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
<div class="container px-0 max-w-100" *ngIf="messages && messages.length > 0;else elseBlock">
    <div class="row mx-0">
        <h1 class="pt-4">Message Center</h1>
        <div class="mobile-tab pt-3">
            <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="ChangeTab($event)">
                <mat-tab label="Messages">
                    <div class="pt-3">
                        <ng-template *ngTemplateOutlet="msgList"></ng-template>
                    </div>
                </mat-tab>
                <mat-tab label="Message Pane">
                    <div class="py-3">
                        <ng-template *ngTemplateOutlet="msgView"></ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 py-2 hide-mobile">
            <ng-template *ngTemplateOutlet="msgList"></ng-template>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 py-2 hide-mobile">
            <ng-template *ngTemplateOutlet="msgView"></ng-template>
        </div>
    </div>
</div>
<ng-template #elseBlock>
    <span class="container px-2 text-center">
        <div><h2>No messages found.</h2></div>
    </span>
</ng-template>
<ng-template #msgList>
    <div class="card">
        <div *ngFor="let item of messages; let i=index;" >
            <div class="message-item cursor-pointer" (click)="selectMessage(item, i)"
            [ngClass]="{'unread':!item.read, 'read':item.read, 'active':item.messageId==selectedMessage.messageId}">
                <div class="title">{{item.subject}} <mat-icon class="panel-icon pull-right" color="accent">
                    {{item.read? 'mark_email_read':'mark_email_unread'}}
                </mat-icon></div>
                <div class="date">{{item.sent | date:'short'}}</div>
                <div class="subject">{{item.title}}</div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #msgView>
    <div class="reading-pane">
        <div class="p-4 cursor-pointer">
            <div class="title" title="{{selectedMessage.subject}}">{{selectedMessage.subject}}</div>
            <div class="date">{{selectedMessage.sent | date:'short'}}</div>
            <div class="subject">{{selectedMessage.title}}</div>
            <div style="margin-top:20px;" [innerHTML]="selectedMessage.body"></div>
            <div style="margin-top:20px;" *ngIf="selectedMessage.attachment">
                <button mat-stroked-button color="accent" (click)="downloadAttachment()">Download</button>
            </div>
        </div>
    </div>
</ng-template>