import { Component, Input, OnInit } from '@angular/core';
import { ArtifactsMock } from 'src/app/core/mocks/artifacts.mock';
import { Artifact } from 'src/app/core/models/artifact.model';
import { Message } from '../../../core/models/message.model';
import { CertWizService } from 'src/app/core/services/cert-wiz.service';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ARTIFACTS_ORDER } from 'src/app/core/constants/artifacts-order.constants';
import { UiStoreService } from 'src/app/core/services/ui-store.service';
import { ARTIFACT_STATUS } from 'src/app/core/enums/artifact.enum';

@Component({
  selector: 'app-cert-wiz-controller',
  templateUrl: './cert-wiz-controller.component.html',
  styleUrls: ['./cert-wiz-controller.component.scss'],
})
export class CertWizControllerComponent implements OnInit {
  @Input() isApp = false;
  @Input() addWizObj: any;
  public percentComplete = 0;

  constructor(
    public _ui: UiStoreService,
    public certWizService : CertWizService,
    public appWizService : AppWizService,
    
  ) { }

  ngOnInit(): void {
    this._ui.expandedArtifactId.next("");
    this.appWizService.appUpdated$.subscribe(app => {
        if(app && app.artifacts){
            let done = 0;
            app.artifacts.forEach((art: any) => {
                if(art.artifactStatus > ARTIFACT_STATUS.OUTSTANDING){
                    done++;
                    this.percentComplete = (done / this.appWizService.appWizard.artifacts.length) * 100;
                }
            });
            
        }
    })
  }

  artifacts(): Artifact[]{
    if(this.isApp){
      return this.appWizService.appWizard.artifacts;
    }else{
      return this.certWizService.certWizard.requiredArtifacts;
    }
  }
}
