<div class="container">
    <h1 class="pt-4">Certification Help</h1>
    <div class="description">This page contains information that will help you understand the requirements of different
        approaches to obtaining Alabama Educator Certification</div>
    <div class="primary">
        <p><strong>Q-1. Where can I find an overview of all of Alabama’s Educator Certification Approaches?</strong></p>

        <ul style="list-style-position: outside !important; padding-left: 40px;">
            <li style="font-family: Arial, sans-serif;">Teachers &amp; Administrators</li>
            <li style="font-family: Arial, sans-serif;">Teacher Center</li>
            <li style="font-family: Arial, sans-serif;">Teacher Certification</li>
            <li style="font-family: Arial, sans-serif;">Miscellaneous Documents (Overview of Alabama’s Educator
                Certification Approaches)</li>
        </ul>
        <p><strong>Q-2. I hold a valid professional educator certificate from ANOTHER STATE.</strong></p>
        <p style="padding-left: 40px;">Individuals who hold a valid professional educator certificate issued by another
            state, the District of Columbia, a United States Territory, or the Department of Defense Education Activity
            (DoDEA) may wish to apply for Alabama certification through Certificate Reciprocity. Along with holding a
            valid professional educator certificate, other ancillary requirements (background clearance, experience,
            etc.) must be met. Click here for Certificate Reciprocity applications and all-inclusive information.</p>
        <p><strong>Q-3. I completed an educator preparation program that leads to certification at a NON-ALABAMA college
                or university.</strong></p>
        <p style="padding-left: 40px;">Alabama certification is not offered on the basis of educator preparation
            programs completed at non-Alabama institutions. Individuals who complete programs at a non-Alabama
            institution, must first obtain a valid professional educator certificate from another state, the District of
            Columbia, a United States Territory, or the Department of Defense Education Activity (DoDEA) and then apply
            based on Certificate Reciprocity.</p>
        <p style="padding-left: 40px;">Individuals who hold a valid professional educator certificate issued by another
            state, the District of Columbia, a United States Territory, or the Department of Defense Education Activity
            (DoDEA) may wish to apply for Alabama certification through Certificate Reciprocity. Along with holding a
            valid professional certification, other ancillary requirements (background clearance, experience, etc.) must
            be met. Click here for Certificate Reciprocity applications and all-inclusive information.</p>
        <p><strong>Q-4. I have completed or would like to complete an educator preparation program at an ALABAMA college
                or university.</strong></p>
        <p style="padding-left: 40px;">You will need to speak with the certification officer in the college of education
            at the Alabama college or university. The college or university’s certification officer can provide
            information regarding program admission, program requirements, and upon completion of all requirements, may
            submit application/recommendation for Alabama professional educator or leadership certification.</p>
        <p><strong>Q-5. I hold a valid Alabama certificate in a teaching field and I want to ADD another teaching
                field.</strong></p>
        <p style="padding-left: 40px;">An individual cannot add administration, instructional leadership, library media,
            school counseling, or any other instructional support area through these options.</p>
        <p style="padding-left: 40px;"><strong>Additional Teaching Field (Professional Educator Certificate)</strong>
        </p>
        <p style="padding-left: 80px;">An individual may seek additional certification in certain teaching field(s) if
            the individual:</p>
        <p style="padding-left: 80px;">holds a valid Alabama Professional Educator Certificate in a teaching field
            AND<br>
            earns a passing score on the AECAP prescribed Praxis subject area test(s).</p>
        <p style="padding-left: 80px;"><a
                href="{{environment.externalUrl.certificationBasedOnTestCbt}}" target="_blank"
                rel="noopener" role="link">Additional Teaching Field (PEC) </a> applications and all-inclusive
            information.</p>
        <p style="padding-left: 40px;"><strong>Additional Teaching Field (Career and Technical Education)</strong></p>
        <p style="padding-left: 80px;">An individual may seek additional certification in selected technical education
            program areas or in health science if the individual:</p>
        <p style="padding-left: 80px;">holds a valid Alabama Professional Educator Certificate in a teaching field
            AND<br>
            completes the ALSDE approved ENTRY LEVEL or MASTER LEVEL occupational proficiency assessment AND<br>
            completes Session A of the Career and Technical Education Teacher Certification Program (CTE TCP – Level 1).
        </p>
        <p style="padding-left: 80px;">For <a href="{{environment.externalUrl.atfProfessional}}"
                target="_blank" rel="noopener" role="link">Additional Teaching Field (CTE)</a> applications and
            all-inclusive information.</p>
        <p style="padding-left: 40px;"><strong>Additional Teaching Field (Career and Technical Education – Degree
                Equivalent)</strong></p>
        <p style="padding-left: 80px;">An individual may seek additional Degree Equivalent certification in selected
            technical education program areas if the individual:</p>
        <p style="padding-left: 80px;">holds a valid Bachelor’s Equivalent Technical Education 2 (or higher) Career and
            Technical Certificate in a technical education program area AND<br>
            completes the ALSDE approved ENTRY LEVEL or MASTER LEVEL occupational proficiency assessment.</p>
        <p style="padding-left: 80px;">For <a href="{{environment.externalUrl.ctcAdditionalTeachingDe}}"
                target="_blank" rel="noopener" role="link">Additional Teaching Field (CTE-DE)</a> applications and
            all-inclusive information.</p>
        <p style="padding-left: 40px;"><strong>Driver and Traffic Safety Education</strong></p>
        <p style="padding-left: 80px;">An individual may seek additional certification in Driver and Traffic Safety
            Education if the individual:</p>
        <p style="padding-left: 80px;">holds a valid Alabama Professional Educator Certificate or Alabama Professional
            Leadership Certificate AND<br>
            verifies credit earned for the required basic and advanced driver education coursework.</p>
        <p style="padding-left: 80px;">For <a
                href="{{environment.externalUrl.driverAndTrafficSafetyEducation}}" target="_blank"
                rel="noopener" role="link">Driver and Traffic Safety Education</a> applications and all-inclusive
            information.</p>
        <p><strong>Q-6. I would like to be a SUBSTITUTE Teacher.</strong></p>
        <p style="padding-left: 40px;">The Substitute License CANNOT be requested by an individual.</p>
        <p style="padding-left: 40px;">A substitute license may ONLY be requested by an employing Alabama county/city
            superintendent or eligible non-public/private school administrator.</p>
        <p style="padding-left: 40px;">An individual must have graduated from high school or completed an Alabama State
            Department of Education approved equivalent.</p>
        <p style="padding-left: 40px;">Substitute Licenses cannot be renewed. A new license must be requested by the
            school system or eligible nonpublic/private school.</p>
        <p style="padding-left: 40px;">For the <a href="{{environment.externalUrl.substituteLicense}}"
                target="_blank" rel="noopener" role="link">Substitute License</a> application.</p>
        <p><strong>Q-7. I do not hold an Alabama certificate and would like to teach under an Emergency Certificate or a
                Career and Technical Education Temporary Certificate.</strong></p>
        <p style="padding-left: 40px;">The Emergency Certificate CANNOT be requested by an individual. The Career and
            Technical Temporary Certificate CANNOT be requested by an individual.</p>
        <p style="padding-left: 40px;">The Emergency Certificate or the Career and Technical Temporary Certificate may
            ONLY be requested by an employing Alabama county/city superintendent or eligible non-public/private school
            administrator.</p>
        <p style="padding-left: 40px;">For the Emergency Certificate or the Career and Technical Temporary Certificate,
            an individual must hold at least a bachelor’s degree from a senior institution that was regionally
            accredited or accredited by the Distance Education Accreditation Commission (DEAC) at the time the degree
            was earned. For the Career and Technical Temporary Certificate an individual must also hold the following:
        </p>
        <ul style="list-style-position: outside !important; padding-left: 80px;">
            <li style="font-family: Arial, sans-serif;">Health Science: Active Alabama license in a registered nursing,
                paramedical, or allied health specialty</li>
            <li style="font-family: Arial, sans-serif;">Technical Education Program Area of Cosmetology: Valid Alabama
                Cosmetology Instructor License</li>
        </ul>
        <p style="padding-left: 40px;">The Emergency Certificate and the Career and Technical Temporary Certificate are
            valid for up to two scholastic years and may be re-issued once for an additional two scholastic years.
            Available teaching fields and areas can be found on the application forms.</p>
        <p style="padding-left: 40px;">For <a href="{{environment.externalUrl.emergency}}" target="_blank"
                rel="noopener" role="link">Emergency Certificate</a> applications and all-inclusive information.</p>
        <p style="padding-left: 40px;">For <a href="{{environment.externalUrl.ctcTemporaryCertificates}}"
                target="_blank" rel="noopener" role="link">Career and Technical Temporary Certificate</a> applications
            and all-inclusive information.</p>

    </div>
</div>