import { Component, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-education-record-hs',
  templateUrl: './education-record-hs.component.html',
  styleUrls: ['./education-record-hs.component.scss']
})
export class EducationRecordHsComponent extends ArtifactBaseComponent{

  minFromDate: Date | string = new Date(1900, 0, 1);
  maxFromDate: Date | string = new Date();
  minToDate: Date | string = new Date(1900, 0, 1);
  maxToDate: Date | string = new Date();
  degreeList = [
    {viewValue:"Associates",value:'associates'},
    {viewValue:"Bachelor's",value:'bachelor'},
    {viewValue:"Master's",value:'master'},
    {viewValue:'Specialist',value:'specialist'},
    {viewValue:'Doctorate',value:'doctorate'}
  ]

  constructor(
    private injector: Injector,
    public fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      college: [null, [Validators.required, Validators.maxLength(100)]],
      location: ['', [Validators.required, Validators.maxLength(100)]],
      degree: [[], [Validators.required, Validators.maxLength(100)]],
      major: ['', [Validators.required, Validators.maxLength(100)]],
    });

    this.interalForm.get('degree')?.valueChanges.subscribe(val => {
      if (val.length == 1 && val[0] == "highschool") {
        this.interalForm.controls['major'].clearValidators();
      } else {
        this.interalForm.controls['major'].setValidators([Validators.required]);
      }
      this.interalForm.controls['major'].updateValueAndValidity();
    });
  }

  createEducationRecord(){
    this.jsonData.records.push(this.interalForm.value);
    this.resetDate();
    this.interalForm.reset();
    this.validateSection();
  }
  deleteEducationRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }
  dateChanges(type: string, event: any) {
    if(type == 'start') {
      this.minToDate = new Date(event);
      this.minToDate.setDate(this.minToDate.getDate() + 1);
    }
    else {
      this.maxFromDate = new Date(event);
      this.maxFromDate.setDate(this.maxFromDate.getDate() - 1);
    }
  }
  resetDate(){
    this.minToDate = new Date(1900, 0, 1);
    this.maxFromDate = new Date();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
  }

  // BM 9-21-23 Need to set expected count to the record count
  override processBeforeSubmit(){
    this.artifact.expectedCount = this.jsonData.records?.length;
  }
}
