import { Component, Injector, OnInit } from '@angular/core';
import { AppConfiguration } from 'src/app/app-configuration';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UploadService } from 'src/app/core/services/upload.service';
import { from, mergeMap } from 'rxjs';
import { ARTIFACT_SUBMMITED_BY } from 'src/app/core/enums/artifact.enum';

@Component({
  selector: 'app-more-information-needed',
  templateUrl: './more-information-needed.component.html',
  styleUrls: ['./more-information-needed.component.scss']
})
export class MoreInformationNeededComponent extends ArtifactBaseComponent implements OnInit {

  ARTIFACT_KEY = ARTIFACT_KEY;
  documents:any[] = [];
  constructor(
    private injector: Injector,
    public appConfig: AppConfiguration,
    public override appWizService : AppWizService,
    public override uploadService : UploadService,
  ) {
    super(injector)
  }

  setDefaultDocs(){
    let requestedDocuments = this.getDocTypes();
      this.documents = [];
      requestedDocuments?.map((docType:any)=>{
        docType.docType.subTypes?.map((subTypes:any)=>{
          this.documents.push({
            displayName: docType.docType.displayName,
            code: docType.docType.code,
            subTypes:[{
              documents: [],
              displayName: subTypes.displayName,
              code: subTypes.code,
              maxUpload: 5
            }]
          })
        })
      });
  }
  getDocTypes(){
    return this.artifact?.requestedDocuments;
  }

  initJsonData(){
    this.setDefaultDocs();
    this.jsonData = this.jsonData || { documents: null };
    this.jsonData.documents = this.jsonData.documents || this.documents;
    this.documents = this.jsonData.documents;
  }
  validateJsonData(){
    this.artifact.sectionValid = this.documents.every((doc:any)=>{
      return doc.subTypes[0].documents.length;
    });
  }
  resetJsonData(){
    let unUsedDocuments = this.documents.reduce((unUsedDoc:any[], item, index) => {
      let docs = item.subTypes[0].documents || [];
      item.subTypes[0].documents = [];
      return unUsedDoc.concat(docs);
    }, []);
    this.setDefaultDocs();
    return unUsedDocuments;
  }

  override ngxOnChanges() {
    this.validateJsonData();
  }

  override processBeforeSubmit(){
    if(this.artifact.submittedBy == ARTIFACT_SUBMMITED_BY.SUBMIT_CLICK){
      this.artifact.sectionValid = true;
      if(this.documents?.length){
        this.documents[0].updated = new Date();
      }
    }else{
      this.artifact.sectionValid = false;
    }
    this.jsonData = {documents:this.documents};
  }

}
