import { environment } from "src/environments/environment";

export class AppConfiguration {
    constructor() {}

    public citUploaderConfig = {
            url: environment.apiURL+'file',
            method: 'PUT',
            autoUpload: false,
            multiple: true,
            maxFileSize: 10 * 1024 * 1024,
            queueLimit: 5,
            maxFileCount: 5,
            allowedMimeType: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'application/msword', 'text/plain', 'image/tiff', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
            allowedType: [ 'pdf', 'png', 'jpeg', 'jpg', 'doc', 'txt', 'tiff', 'docx']
    };

    public fileUploadConfig = {
        url: environment.apiURL+'file',
        method: 'PUT',
        autoUpload: false,
        multiple: false,
        maxFileSize: 10 * 1024 * 1024,
        queueLimit: 1,
        maxFileCount: 1,
        allowedMimeType: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'application/msword', 'text/plain', 'image/tiff', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        allowedType: [ 'pdf', 'png', 'jpeg', 'jpg', 'doc', 'txt', 'tiff', 'docx']
};
}