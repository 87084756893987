<div>
    <div class="description py-3"></div>
    <div class="container">
        <div class="row">
            <div class="px-2 pt-3">
                <div class="row form-group">
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="select" [form]="interalForm" title="School"
                            placeholder="School" controlName="school" [data]="schoolList"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <button mat-stroked-button color="accent" [disabled]="!interalForm.valid || jsonData.records.length >= 3"
                            (click)="createRecord()">Add School</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="my-3 table-section" *ngIf="jsonData.records.length > 0">
            <div class="row overflow-auto">
                <table class="table table-striped table-mobile-responsive table-mobile-sided">
                    <thead>
                        <tr>
                            <th scope="col">Site Id</th>
                            <th scope="col">Site Name</th>
                            <th scope="col">Hard to Staff</th>
                            <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of jsonData.records;">
                            <td scope="row" data-content="Site Id">{{element.siteCode}}</td>
                            <td data-content="Site Name">{{element.siteLongName}}</td>
                            <td data-content="Hard to Staff">{{element.hardtoStaff ? "YES":"NO"}}</td>
                            <td data-content="Remove" *ngIf="!isReadOnly()"><mat-icon
                                    (click)="deleteRecord(element)"
                                    style="font-size:32px; color:red;">close</mat-icon></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>