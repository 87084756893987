<div class="container-fluid px-4 max-w-100">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-md-9 row reverse pr-0">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 py-4 pr-0">
                <app-background-review [bgrData]="bgrData"></app-background-review>
                <br />
                <app-dashboard [appList]="appList"></app-dashboard>
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 py-4 primary pr-0">
                <h2>Welcome to the Alabama Certification for Educators</h2>
                <h3>This portal provides a central location for educators to obtain, manage, and renew certificates.
                </h3>

                <div class="horz-div"></div>
                <div class="d-flex justify-content-center">
                    <mat-icon color="accent" style="font-size: 32px; margin-top: 12px;"
                        title="New Application">new_releases</mat-icon>
                    <button mat-stroked-button color="accent" aria-label="New Application" class=""
                        style="margin:10px; font-size: 16px;" (click)="newApp()">New Application
                    </button>
                </div>

                <p class="info">Please note the following that applies to all new applications:<br /><br />
                    An application is not considered complete until all supplemental documents have been received and
                    all the requirements for application have been met, including the receipt of an Alabama Law
                    Enforcement Agency (ALEA) and Federal Bureau of Investigation (FBI) criminal history background
                    check completed through this agency. Applications with missing supplemental documents or other
                    requirements will not be assigned to a certification specialist for a review of eligibility for an
                    Alabama license, certificate, or permit.
                </p>
                <br /><br />
                <p class="info">All communication relating to applications submitted via this portal will be through the
                    Email Address on file in AIM. Please make sure your email address is up to date and double check your spam folder for
                    communications.
                </p>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 py-4">
            <app-current-certs></app-current-certs>
        </div>
    </div>
</div>