import { Injectable } from '@angular/core';
import { AppHttpClient } from './app-http-client.service';
import { Observable, map } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { FeeSummary, FeeSummaryModel } from '../models/fee.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(public http: AppHttpClient, private ls: LocalStorageService) {}

  getFeeSummary(appId: string, feeSummaryId: string): Observable<FeeSummary> {
    return this.http
      .get('fee-summary?appId=' + appId + '&feeSummaryId=' + feeSummaryId)
      .pipe(
        map((res: any) => {
          return new FeeSummary(res.feeSummary as FeeSummaryModel);
        })
      );
  }

  saveFeeSummary(feeSummary: FeeSummary) {
    return this.http
      .post('fee-summary', feeSummary)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /*
  feeSummary(params: any) {
    return this.http
      .get(
        'fee-summary?appId=' +
          params['appId'] +
          '&feeSummaryId=' +
          params['feeSummaryId']
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
*/
  preparePayment(url: string, payload: any) {
    return this.http.post(url, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
