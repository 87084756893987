import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiStoreService {

  isMobile: boolean = true;
  expandedArtifactId: BehaviorSubject<string> = new BehaviorSubject('');
  selectedTab:any = {
    appWizard : 1
  };
  jsonData$ = new Subject();
  constructor() { }

  
}
