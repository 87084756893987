import { ARTIFACT_KEY } from "../constants/artifacts-order.constants";
import { ARTIFACT_STATUS, ARTIFACT_SUBMMITED_BY, ARTIFACT_TYPE } from "../enums/artifact.enum";
import { USER_ROLES } from "../enums/user.enum";
import { AlsdeUser } from "./alsde-user.model";
import { BtnAction } from "./btn-action.model";

export class IArtifact {
    artifactId!: string;
    appId?: string = '';
    userId?: string = '';
    siteIdPrimary?: string = '';
    feeSummaryId?: string = '';
    name!: string;
    type!: ARTIFACT_TYPE;
    key: string = "";
    text: string = "";
    artifactJsonData?: any = null;
    artifactResponsibility?: string = '';
    artifactStatus: ARTIFACT_STATUS = ARTIFACT_STATUS.OUTSTANDING;
    createDate?: Date | null;
    lastUpdatedDate?: Date | null;
    completedDate?: Date | null;
    bgrUrl?: string;
    background?: any;
    feeAmount?: number;
    creditAmount?: number;
    totalDue?: number;
    paidAmount?: number;
    paymentUrl?: string = "";
    transactionId?: string = "";
    documents?: string[] = [];
    docTypes?: any[] = [];
    requestedDocuments?: any[] = [];
    formName?: string = '';
    url?: string = '';
    downloaded?: boolean = false;
    locked: boolean = false;
    visibility: string = "visible";
    maxUpload?: number = 0;
    minUpload?: number = 1;
    expectedCount?: number = 1;
    sectionName?: string = '';
    sectionData?: string = "";
    sectionValid?: boolean = false;
    actions?: BtnAction[] = [];
    receivedDate?: Date | null; //The date the artifact was first received before it has validated.
    submittedBy?: ARTIFACT_SUBMMITED_BY; //used to identify artifact submit reason/event
    
    // 4-9-2024 Roles updated to be single value
    role: string = "";

    //temp
    submittedDate?: Date | null; //The date the artifact was last submitted for validate.
    approvedDate?: Date | null;
    approvedUser?: AlsdeUser | null; //datatype may be change
    transactionDate?: Date | null;
    description?: string;
    useInstructions?: boolean;
    instructions?: string;
    responsible?: string;
    transcriptsOnFile?: number = 0;
    
    // New property
    viewerRoles: USER_ROLES[] = [];
}

export class Artifact extends IArtifact {

    constructor(obj?: IArtifact) {
        super();
        if (obj) {
            this.artifactId = obj?.artifactId ?? this.artifactId;
            this.appId = obj?.appId ?? this.appId;
            this.userId = obj?.userId ?? this.userId;
            this.siteIdPrimary = obj?.siteIdPrimary ?? this.siteIdPrimary;
            this.feeSummaryId = obj?.feeSummaryId ?? this.feeSummaryId;
            this.name = obj?.name ?? this.name;
            this.type = obj?.type ?? this.type;
            this.key = obj?.key ?? this.key;
            this.text = obj?.text ?? this.text;
            this.artifactJsonData = obj?.artifactJsonData ?? this.artifactJsonData;
            this.artifactResponsibility = obj?.artifactResponsibility ?? this.artifactResponsibility;
            this.artifactStatus = obj?.artifactStatus ?? this.artifactStatus;
            this.createDate = obj?.createDate ?? this.createDate;
            this.lastUpdatedDate = obj?.lastUpdatedDate ?? this.lastUpdatedDate;
            this.completedDate = obj?.completedDate ?? this.completedDate;
            this.bgrUrl = obj?.bgrUrl ?? this.bgrUrl;
            this.background = obj?.background ?? this.background;
            this.feeAmount = obj?.feeAmount ?? this.feeAmount;
            this.creditAmount = obj?.creditAmount ?? this.creditAmount;
            this.totalDue = obj?.totalDue ?? this.totalDue;
            this.paidAmount = obj?.paidAmount ?? this.paidAmount;
            this.paymentUrl = obj?.paymentUrl ?? this.paymentUrl;
            this.transactionId = obj?.transactionId ?? this.transactionId;
            this.receivedDate = obj?.receivedDate ?? this.receivedDate;
            this.role = obj?.role ?? this.role;
            this.formName = obj?.formName ?? this.formName;
            this.url = obj?.url ?? this.url;
            this.downloaded = obj?.downloaded ?? this.downloaded;
            this.expectedCount = obj?.expectedCount ?? this.expectedCount;
            this.sectionName = obj?.sectionName ?? this.sectionName;
            this.sectionValid = obj?.sectionValid ?? this.sectionValid;
            this.documents = obj?.documents ?? this.documents;
            this.docTypes = obj?.docTypes ?? this.docTypes;
            this.requestedDocuments = obj?.requestedDocuments ?? this.requestedDocuments;
            if (obj.actions) {
                obj.actions.forEach(value => {
                    this.actions?.push(new BtnAction(value))
                })
            }
            this.description = obj?.description ?? this.description;
            this.useInstructions = obj?.useInstructions ?? this.useInstructions;
            this.instructions = obj?.instructions ?? this.instructions;
            this.locked = obj?.locked ?? this.locked;
            this.visibility = obj?.visibility ?? this.visibility;
            this.maxUpload = obj?.maxUpload ?? this.maxUpload;
            this.responsible = obj?.responsible ?? this.responsible;
            if(this.key == ARTIFACT_KEY.CRIMINAL){
                obj['viewerRoles'] = [USER_ROLES.APPLICANT];
            }
            this.viewerRoles = obj?.viewerRoles ?? this.viewerRoles;
        }
    }

    toJSON() {
        return {
            artifactId: this.artifactId,
            appId: this.appId,
            userId: this.userId,
            siteIdPrimary: this.siteIdPrimary,
            feeSummaryId: this.feeSummaryId,
            name: this.name,
            type: this.type,
            key: this.key,
            text: this.text,
            artifactJsonData: this.artifactJsonData,
            artifactResponsibility: this.artifactResponsibility,
            artifactStatus: this.artifactStatus,
            createDate: this.createDate,
            lastUpdatedDate: this.lastUpdatedDate,
            completedDate: this.completedDate,
            bgrUrl: this.bgrUrl,
            background: this.background,
            feeAmount: this.feeAmount,
            creditAmount: this.creditAmount,
            totalDue: this.totalDue,
            paidAmount: this.paidAmount,
            paymentUrl: this.paymentUrl,
            transactionId: this.transactionId,
            receivedDate: this.receivedDate,
            role: this.role,
            documents: this.documents,
            docTypes: this.docTypes,
            requestedDocuments: this.requestedDocuments,
            formName: this.formName,
            url: this.url,
            downloaded: this.downloaded,
            expectedCount: this.expectedCount,
            sectionName: this.sectionName,
            sectionValid: this.sectionValid,
            description: this.description,
            useInstructions: this.useInstructions,
            instructions: this.instructions,
            locked: this.locked,
            visibility: this.visibility,
            maxUpload: this.maxUpload,
            responsible: this.responsible,
            viewerRoles: this.viewerRoles,
        }
    }
}