import { Injectable } from '@angular/core';
import { AlsdeUser, ISiteRole } from '../models/alsde-user.model';
import { LocalStorageService } from './local-storage.service';
import { HttpService } from './http.service';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { IAlsdeBackground, AlsdeBackground } from '../models/background.model';
import { ApplicationWizard } from '../models/application-wizard.model';
import { USER_ROLES } from '../enums/user.enum';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from './util.service';
import { ROLES_BY_USER_TYPE } from '../enums/ui.enum';
import { APPLICATION_ACTION_COMPELTE_STATUSES, APPLICATION_STATUS } from '../enums/application.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public bgrData = new BehaviorSubject<IAlsdeBackground>(new AlsdeBackground());
  public appList$ = new BehaviorSubject<ApplicationWizard[]>([]);
  private _user = new AlsdeUser({} as AlsdeUser);
  constructor(
    public ls: LocalStorageService,
    private http: HttpService,
    private route: ActivatedRoute,
    public util: UtilService
  ) {
    const user = this.ls.getItem('user');
    this._user = user ? new AlsdeUser(JSON.parse(user)) : this._user;
  }

  fetchUser(token: string, rolesAccess: any) {
    console.log(this.route.snapshot.queryParams['role']);

    // TODO - This should be more robust
    const decode = atob(token.split('.')[1]);
    let obj = JSON.parse(decode);
    obj.siteRoles = JSON.parse(obj.Roles).RoleList.map((i: any) => new ISiteRole(i));
    obj.Roles = JSON.parse(obj.Roles).RoleList.map((i: any) => i.RoleName);
    obj.selectedSiteRole = obj.siteRoles[0] || new ISiteRole();
    this._user = new AlsdeUser({
      alsdeId: obj.AlsdeId,
      firstName: obj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"],
      lastName: obj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
      dob: obj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth"],
      email: obj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
      roles: obj.Roles,
      siteRoles: obj.siteRoles,
      selectedSiteRole: obj.selectedSiteRole,
      permissions: [],
    });
    this._user.permissions = this.findPermissions(this._user.roles, rolesAccess);
    console.log('roles', this._user.roles);
    this.ls.setItem('user', JSON.stringify(this._user));
  }

  findPermissions(roles: USER_ROLES[], rolesAccess: any) {
    let items = roles.reduce((permissions, role) => {
      return permissions.concat(rolesAccess[role]);
    }, []);

    //array unique 
    return [...new Set(items)].sort();
  }


  BackgroundService(alsdeId?:string) {
    alsdeId = alsdeId || this._user.alsdeId;
    return this.http.get('background?id=' + alsdeId).subscribe((x: any) => {
      this.bgrData.next(x);
    }, error => { this.bgrData.next(new AlsdeBackground()); });
  }

  InprogressService() {
    this.http.get('app/list').subscribe({
      next: (x: any) => { this.appList$.next(x.map((i:any)=> new ApplicationWizard(i))); this.notifyInprogressAppExist(x)},
      error: () => { this.appList$.next([]); }
    })
  }

  notifyInprogressAppExist(appList:ApplicationWizard[]){
    let InprogressApp = appList.find((app)=>{
      return !APPLICATION_ACTION_COMPELTE_STATUSES.includes(app.status?.Value || APPLICATION_STATUS.STARTED);
    });
    if(InprogressApp){
      this.util.openSnackBar("Your account has in-process applications")
    }
  }

  getUser(): AlsdeUser {
    return this._user;
  }

  setUser(user: AlsdeUser) {
    this._user = user;
  }

  isEduAgent(){
    return this.getSelectedSiteRole()?.RoleName?.toUpperCase() !== USER_ROLES.APPLICANT;
  }

  getUserDemographics(alsdeId: string) {
    const url = this.isEduAgent() ? 'userlookup?ALSDEID=' : 'demographics?id=';
    return this.http.get(url + alsdeId).pipe(
      map((res: any) => {
        return this.setupUserLookup(res);
      })
    )
  }

  setupUserLookup(res: any){
    let personalInfo =  res.Demographics ? res.Demographics : res;
    return {
      demographics : this.setupUserDemographics(personalInfo),
      background : res.Background || this.bgrData.value,
    }
  }

  setupUserDemographics(personalInfo: any) {
    if(!personalInfo){
      this.util.openSnackBar("User not found");
      throw "User not found";
    }
    const a = personalInfo;
    a.phoneNumbers.map((x: any) => {
      if (x.phoneNumberType == 'Work') a.workPhone = x.phoneNumber;
      if (x.phoneNumberType == 'Cell') a.cellPhone = x.phoneNumber;
      if (x.phoneNumberType == 'Home') a.homePhone = x.phoneNumber;
    });
    a.emailAddresses.map((x: any) => {
      // 12-18-23 BM AIM has changed their data model relating to emails
      if (x.emailAddressType == 'Personal Email') a.personalEmail = x.emailAddress;
      if (x.emailAddressType == 'Primary email address' || x.emailAddressType == 'Work email address') a.workEmail = x.emailAddress;
      if (!a.personalEmail) a.personalEmail = a.workEmail;
    });
    a.addresses.map((x: any) => {
      if (x.addressType == 'Work') a.address2 = x.streetAddress || x.poBoxNumber;
      if (x.addressType == 'Home') a.address1 = x.streetAddress || x.poBoxNumber;
      a.city = x.city;
      a.zipCode = x.zipCode;
      a.state = x.usStateName;
      a.country = x.countryName;
    });
    a.sex = a.sex.toLowerCase() == 'm' ? 'Male' : 'Female';
    // a.liveInUnitedState = a.liveInUnitedState ? 'Yes' : 'No';
    // a.stationedOnUsMilitaryBase = a.stationedOnUsMilitaryBase ? 'Yes' : 'No';
    // a.employedAlabamaEducationalInstitution = a.employedAlabamaEducationalInstitution ? 'Yes' : 'No';

    a.birthDetails.dateOfBirth = this.util.datePipe(new Date(a.birthDetails.dateOfBirth), 'MM/dd/yyyy');
    // 2-13-24 Bob, hacky stuff to deal with the aim date not having a time component
    if (a.driverLicense.expirationDate && a.driverLicense.expirationDate.indexOf('/') < 0) {
      a.driverLicense.expirationDate = this.util.datePipe(new Date(a.driverLicense.expirationDate+" 00:00"), 'MM/dd/yyyy');
    }
    a.userId = a.alsdeId;
    return a;
  }

  applistlookup(filter:any) {
    this.http.get('applistlookup', filter).subscribe({
      next: (x: any) => { this.appList$.next(x); },
      error: () => { this.appList$.next([]); }
    })
  }

  getSelectedSiteRole(): ISiteRole {
    return this._user.selectedSiteRole;
  }

  setSelectedSiteRole(siteRole:ISiteRole) {
    this._user.selectedSiteRole = siteRole;
    this.ls.setItem('user', JSON.stringify(this._user));
  }

  getRoleQueryString(){
    return {
      role: this._user.selectedSiteRole.RoleName,
      ...this._user.selectedSiteRole
    };
  }

  fingerprintAppointmentText(item:any){
    return 'Appointment Date:<b>'+this.util.datePipe(item.appointmentDateUtc)+'</b>, Scheduled Date:<b>'+this.util.datePipe(item.scheduledDateUtc)+'</b>, Status:<b>'+item.status+'.</b>'
  }
}
