import { Injectable } from '@angular/core';
import { AppHttpClient } from './app-http-client.service';
import { Observable, map } from 'rxjs';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public http : AppHttpClient
  ) { }

  getNotifications():Observable<Notification[]>{
    return this.http.get('notification').pipe(
      map((res:any)=>res.map((notification:any)=>new Notification(notification)))
    );
  }
}
