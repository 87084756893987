import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  environment = environment;
  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {}

  navigate(route:string) {
    this.router.navigate([route]);
  }

  getUser(){
    return this.userService.getUser();
  }

  logout(){
    return this.authService.logout();
  }

  login(){
    window.location.href = environment.aimURL;
  }

  homePage(){
    let isEduAgent = this.userService.isEduAgent();
    return isEduAgent ? '/edu-agent/home' : '/app';
  }

  changeRoleWiseDashboard(){
    this.userService.setSelectedSiteRole(this.userService.getSelectedSiteRole());
    let isEduAgent = this.userService.isEduAgent();
    this.router.navigate(isEduAgent ? ['/edu-agent/home'] : ['/app'])
    .then(() => {
      window.location.reload();
    });;
  }
}
