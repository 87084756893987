export class INotification{
    title: string = ""
    description: string = ""
    type: string = ""
    displayDate: Date = new Date()
    createDate: Date = new Date()
    startDate: Date = new Date()
    endDate: Date = new Date()
}

export class Notification extends INotification{

    constructor(options?: INotification){
        super();
            if(options){
            this.title = options.title
            this.description = options.description
            this.type = options.type
            this.displayDate = options.displayDate
            this.createDate = options.createDate
            this.startDate = options.startDate
            this.endDate = options.endDate
            
        }
    }

    toJSON(){
        return {
            title: this.title,
            description: this.description,
            type: this.type,
            displayDate: this.displayDate,
            createDate: this.createDate,
            startDate: this.startDate,
            endDate: this.endDate,
        }
    }
}