import { Component, Input, OnInit, Output, EventEmitter, OnChanges, Injector } from '@angular/core';
import { CertQuestionsMock } from 'src/app/core/mocks/cert-questions.mock';
import { QuestionModel } from 'src/app/core/models/question.model';
import { AppConfiguration } from 'src/app/app-configuration';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subject, filter } from 'rxjs';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { UtilService } from 'src/app/core/services/util.service';
import { Artifact } from 'src/app/core/models/artifact.model';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-professional-status-criminal-history',
  templateUrl: './professional-status-criminal-history.component.html',
  styleUrls: ['./professional-status-criminal-history.component.scss'],
})
export class ProfessionalStatusCriminalHistoryComponent extends ArtifactBaseComponent implements OnInit, OnChanges {
  
  questions: QuestionModel[] = [];
  ARTIFACT_KEY = ARTIFACT_KEY;
  docTypes:any = null;

  constructor(
    private injector: Injector,
    private _fb: FormBuilder,
    public appConfig: AppConfiguration,
    public override appWizService : AppWizService,
    public util : UtilService,
    public override uploadService : UploadService,
  ) {
    super(injector)
    this.loadQuestions(JSON.parse(JSON.stringify(CertQuestionsMock.criminal)));
   }

   loadQuestions(questionJson: any[]) {
    this.interalForm = this._fb.group({});
    this.questions = [];
    questionJson.forEach((element, index) => {
      this.questions.push(new QuestionModel(element));
      this.interalForm.addControl('citQA'+index, new FormControl('', [Validators.required]));
      this.interalForm.addControl('citQASub'+index, new FormControl('', [Validators.required]));
    });
  }

  initJsonData(){
    this.jsonData = this.jsonData || { questions:null, answers: null };
    this.jsonData.answers = this.jsonData.answers || {};
    this.jsonData.questions = this.jsonData.questions || this.questions;

    this.jsonData.questions.map((i:QuestionModel)=>{  
      i.answers[0].documents = i.answers[0].documents || [];
    });

    this.questions = this.jsonData.questions;
    this.interalForm.patchValue(this.jsonData.answers);
    this.docTypes = this.appWizService.getDocTypes(this.artifact);
  }
  validateJsonData(){
    let inValidItems = this.questions.filter((question:QuestionModel)=>{
      if(!question.responseValue || question.responseValue=='Yes' && !this.validateQuestionOnYES(question)){
        return true
      }
      return false
    });
    this.artifact.sectionValid = !inValidItems.length;
  }

  validateQuestionOnYES(question:QuestionModel){
    // return this.util.validateMinMax(question.explanation,50,5000) && question.answers[0].documents?.length
    return this.util.validateMinMax(question.explanation,50,5000);
  }

  resetJsonData(){
    this.questions.map((i:QuestionModel)=>{
      i.responseValue = '';
      i.explanation = '';
    });
    return this.getUnUsedDocuments();
  }

  override processBeforeSubmit(){
    this.jsonData = {questions: this.questions, answers: this.interalForm.value};
  }

  getUnUsedDocuments(){
    let unUsedDocuments: any[] = [];
    this.questions.forEach((question:QuestionModel, index:number)=>{
      if(question.responseValue!=='Yes'){
        question.explanation = '';
        let patchData:any = {};
        patchData['citQASub'+index] = '';
        this.interalForm.patchValue(patchData);
        if(question.answers[0].documents?.length){
          unUsedDocuments = unUsedDocuments.concat(question.answers[0].documents);
          question.answers[0].documents = [];
        }
      }
    });
    return unUsedDocuments;
  }

  onAnswerChange(){
    let unUsedDocuments = this.getUnUsedDocuments();
    if(unUsedDocuments.length){
      this.uploadService.deleteAllDocs(unUsedDocuments).subscribe(()=>{
        this.submitSection();
      });
    }
    this.validateSection();
  }

}

