<div class="mt-1">
    <div class="description my-1">Please complete the Class B general information below</div>
    <div class="container">
        <form [formGroup]="interalForm">
            <div class="row form-group">
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="select" [form]="interalForm" title="Program Level"
                        placeholder="Program Level" controlName="programType" [data]="programTypes"></app-form-control>
                </div>
                <div class="col-sm-12"></div>
                <div class="col-sm-6 col-md-6">
                    <app-form-control type="date" [form]="interalForm" title="Date of unconditional Admission" placeholder="Date of unconditional Admission"
                        controlName="admissionDate" ></app-form-control>
                </div>
                <div class="col-sm-6 col-md-6">
                    <app-form-control type="date" [form]="interalForm" title="Program Completion Date" placeholder="Program Completion Date"
                        controlName="completionDate" [maxDate]="maxDate"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-4">
                    <app-form-control type="text" [form]="interalForm" title="Professional Studies GPA" 
                        placeholder="Professional Studies GPA" controlName="profGPA"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-4">
                    <app-form-control type="text" [form]="interalForm" title="Teaching Field GPA" 
                        placeholder="Teaching Field GPA" controlName="teachGPA"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-4">
                    <app-form-control type="text" [form]="interalForm" title="Overall Degree GPA" 
                        placeholder="Overall Degree GPA" controlName="overallGPA"></app-form-control>
                </div>
            </div>
        </form>
    </div>
</div>