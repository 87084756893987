<div>
    <div class="description py-3">Please add education records here. List only the institutions where a bachelor's
        degree or higher was earned and in descending order beginning with the most recent degree earned. If you have
        earned multiple degrees from the same college or university, only list that college or university one time. Ex.
        You hold a bachelor's and master's degree from the University of Alabama, only list the University of Alabama
        one time. </div>
    <div class="container">
        <div class="row">
            <div class="my-3 table-section" *ngIf="jsonData.records.length > 0">
                <div class="row overflow-auto">
                    <table class="table table-striped table-mobile-responsive table-mobile-sided">
                        <thead>
                            <tr>
                                <th scope="col">Name of College/University</th>
                                <th scope="col">Location</th>
                                <th scope="col">Dates Attended</th>
                                <th scope="col">Degree(s)</th>
                                <th scope="col">Major(s)</th>
                                <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of jsonData.records;">
                                <td scope="row" data-content="Name of College/University">{{element.college}}</td>
                                <td data-content="Location">{{element.location}}</td>
                                <td data-content="Dates Attended">{{element.startDate| date:'MM/dd/yyyy'}} -
                                    {{element.endDate| date:'MM/dd/yyyy'}}</td>
                                <td data-content="Degree(s)">{{element.degree}}</td>
                                <td data-content="Major(s)">{{element.major}}</td>
                                <td data-content="Remove" *ngIf="!isReadOnly()"><mat-icon
                                        (click)="deleteEducationRecord(element)"
                                        style="font-size:32px; color:red;">close</mat-icon></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="px-2 pt-3">
                <div class="row form-group">
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Name of College/University"
                            placeholder="Name of College/University" controlName="college"
                            (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Location" placeholder="Location"
                            controlName="location" (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="Start Date" placeholder="Start Date"
                            controlName="startDate" [minDate]="minFromDate" [maxDate]="maxFromDate"
                            (dateChange)="dateChanges('start', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="End Date" placeholder="End Date"
                            controlName="endDate" [minDate]="minToDate" [maxDate]="maxToDate"
                            (dateChange)="dateChanges('end', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="select" [form]="interalForm" title="Degree(s)"
                            placeholder="Degree(s)" controlName="degree" [multiple]="true" [data]="degreeList"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Major(s)"
                            placeholder="Major(s)" controlName="major"
                            (keydown)="util.checkLen($event, 100)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <button mat-stroked-button color="accent" [disabled]="!interalForm.valid"
                            (click)="createEducationRecord()">Add Education Record</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>