<div class="container pt-5">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-8 col-xl-8" style="margin: 0 auto;">
            <div class="card p-5" style="align-items: center;">
                <div><mat-icon style="font-size: 30px;">error</mat-icon></div>
                <div class="text-center">
                    <div style="font-family: monospace;font-size: 25px;color: rgba(0, 0, 0, 0.87);">Browser not supported</div>
                    <div style="color: rgba(0, 0, 0, 0.87);" class="py-2"> Please use the latest version of Chrome, Opera, Firefox or Safari.</div>
                </div>
            </div>
        </div>
    </div>
</div>