<div>
    <div class="description pt-3">This section is to be completed in compliance with Ala. Code § 31-13-(29)(c)(1) which
        provides that United States citizenship and lawful presence in the United States must be appropriately verified.
        The Systematic Alien Verification for Entitlements (SAVE) system will be used to verify lawful presence in the
        United States. Alabama certification will not be processed until documentation of United States citizenship or
        lawful presence has been confirmed by the Educator Certification Section.</div>
    <div class="primary">
        <form [formGroup]="interalForm">
            <div *ngFor="let question of questions; let i = index;">
                <app-question [showTooltipIcon]="false" [hidden]="hideQuestion(i)" [disabled]="isReadOnly()" [question]="question" [formControlName]="'citQA'+i" [showControlButtons]="false" (valueChange)="onAnswerChange(i)">
                </app-question>
                <div *ngIf="interalForm.get('citQA'+i) && interalForm.get('citQA'+i)?.value=='Yes'">
                    <div class="pt-3">Please select the documentation you are submitting</div>
                    <mat-radio-group aria-label="Select the documentation you are submitting" [formControlName]="'citQASub'+i">
                        <div *ngFor="let opt of question.answers[0].requiredArtifacts">
                            <mat-radio-button class="radio-button" [disabled]="locked" value="{{opt}}" (change)="question.answers[0].selectedArtifact = opt">
                                <div style="white-space:normal;">{{opt}}</div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                    <br/>
                    <app-file-upload 
                        *ngIf="question.answers[0].selectedArtifact" 
                        (emitData)="onDocumentUpdate()" 
                        [appConfig]="appConfig.citUploaderConfig" 
                        [btnName]="'Upload Supporting Documents'" 
                        [type]="ARTIFACT_KEY.CITIZENSHIP"
                        [artifact]="artifact"
                        [uploadSummary]="question.answers[0].documents"
                        [docTypes]="docTypes"
                        [locked]="locked"
                    ></app-file-upload>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row pt-3 text-danger px-2" *ngIf="err">We are not able to process your application through this method</div>