import { Component, Input, OnInit } from '@angular/core';
import { Certification } from '../../../core/models/certification.model';
import { CertificateService } from 'src/app/core/services/certificate.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-current-certs',
  templateUrl: './current-certs.component.html',
  styleUrls: ['./current-certs.component.scss']
})
export class CurrentCertsComponent implements OnInit {

  @Input ('alsdeId') alsdeId: string = "";
  certs: Certification[] = new Array<Certification>();
  isLoader = true;

  constructor(
    public certService: CertificateService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    if(this.alsdeId && this.userService.isEduAgent()){
      this.loadCertsByAlsdeId();
    }else{
      this.loadCerts();
    }
  }

  loadCerts() {
    this.isLoader = true;
    this.certService.getCertificates().subscribe((response:any)=>{
      this.certs = response;
      this.isLoader = false;
    });
  }

  loadCertsByAlsdeId() {
    this.isLoader = true;
    this.certService.getCertificatesByAlsdeId(this.alsdeId).subscribe((response:any)=>{
      this.certs = response;
      this.isLoader = false;
    });
  }

  getCertByValid(isValid:boolean){
    return this.certs.filter(x=>x.valid == isValid)
  }
}
