import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { IAlsdeBackground, AlsdeBackground } from 'src/app/core/models/background.model';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  bgrData: IAlsdeBackground = new AlsdeBackground();
  appList: ApplicationWizard[] = [];

  constructor(
    private router: Router, private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.BackgroundService();
    this.userService.InprogressService();
    this.userService.bgrData.subscribe(x => this.bgrData = x );
    this.userService.appList$.subscribe(y => this.appList = y );
  }
  
  newApp() {
    this.router.navigate(['/app/cert-wizard']);
  }
}
