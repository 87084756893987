<div class="panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon class="panel-icon" color="accent">{{message.typeIcon}}
                </mat-icon>
                <!-- <span class="date px-2 text-truncate" title="{{message.subject}}">{{message.subject}}</span>
                <span class="date px-2 text-truncate" *ngIf="message.sent">&nbsp;{{message.sent | date:'short'}} &nbsp;</span> -->
                <span class="date px-2 text-truncate_" title="{{message.subject}}">{{message.subject}} <span *ngIf="message.sent">&nbsp;{{message.sent | date:'short'}} &nbsp;</span></span>
                
            </mat-panel-title>
            <!-- <mat-panel-description></mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="content" [innerHTML]="message.body"></div>
        <div style="margin-top:10px;">
            <a mat-stroked-button color="accent" (click)="navigate(message)">{{message.linkName}}</a>
        </div>
    </mat-expansion-panel>
</div>