export const NOTIFY = {
    ARTIFACT_SAVE: {
        SUCCESS:"Artifact save success",
        FAILED:"Artifact save failed",
    },
    ARTIFACT_DELETE: {
        SUCCESS:"Artifact delete success",
        FAILED:"Artifact delete failed",
    },
    APPLICATION_SAVE: {
        SUCCESS:"Your application has been submitted",
        FAILED:"We had a problem saving your application",
    },
    APPLICATION_REJECT: {
        SUCCESS:"Application has been requested for resubmit and the applicant notified",
        FAILED:"We had a problem saving your application",
    }
};
