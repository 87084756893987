import { Component, Injector, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-class-info-a-alternative',
  templateUrl: './class-info-a-alternative.component.html',
  styleUrls: ['./class-info-a-alternative.component.scss']
})
export class ClassInfoAAlternativeComponent extends ArtifactBaseComponent implements OnInit, OnChanges{
  programTypes = [
    {viewValue:"Bachelor's",value:"Bachelor's"},
    {viewValue:"Master's",value:"Master's"},
    {viewValue:"Education Specialist",value:"Education-Specialist"},
  ]
  today = new Date();
  maxDate = new Date(2200, 0, 1);

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      programType:    ['', [Validators.required]],
      admissionDate:  ['', [Validators.required, ValidationService.noFutureDate]],
      completionDate:  ['', [Validators.required]],
      degreeGPA: ['', [Validators.required, ValidationService.numericWithDecimalOnly]],
      programGPA: ['', [Validators.required, ValidationService.numericWithDecimalOnly]],
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  initJsonData(){
    this.jsonData = this.jsonData || { classinfo: null };
    this.jsonData.classinfo = this.jsonData.classinfo || {};
    this.interalForm.patchValue(this.jsonData.classinfo);
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.classinfo = {};
    this.interalForm.reset();
  }
  override processBeforeSubmit(){
    this.jsonData = {classinfo:this.interalForm.value};
  }
}
