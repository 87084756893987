export const ARTIFACT_KEY = {
    ACCEPTANCE: "acceptance-signature",
    ACCEPTANCE_IHE: "acceptance-signature-ihe",
    ACCEPTANCE_LEA: "acceptance-signature-lea",
    ACCEPTANCE_3rdParty: "acceptance-signature-3rdparty",
    ACCEPTANCE_LEA_TEAMS: "signature-lea-teams",
    APPFEE: "application-fee",
    BACKGROUND: "background-check",
    CER: "cer",
    COMPENTENCY: "compentency-record",
    EDUCATIONAL: "education-record",
    EDUCATIONAL_SUBSTITUTE: "education-record-substitute",
    EDUCATIONAL_TE: "education-record-te",
    EDUCATIONAL_HS: "education-record-hs",
    EXPERIENCE: "educational-experience",
    EXP: "exp",
    INTERNSHIP_PLACEMENT: "internship-placement",
    MENTOR: "mentor",
    MILITARY: "military-declaration",
    MILITARYFEE: "military-fee-waiver",
    MOREINFO: "more-info-needed",
    PERSON: "person",
    PERSONAL: "personal-data",
    POWERSCHOOL: "powerschool-record",
    PRAXIS: "praxis-assessment",
    CRIMINAL: "professional-status-criminal-history",
    CITIZENSHIP: "proof-of-citizenship",
    TRANSCRIPTS: "transcripts",
    UPLOAD_APPROVED_CHECKLIST: "upload-approved-checklist",
    UPLOAD_APPROVED_CHECKLIST_IHE: "upload-approved-checklist-ihe",
    UPLOAD_APPROVED_CHECKLIST_LEA: "upload-approved-checklist-lea",
    UPLOADEXISTING: "upload-existing-certificate",
    CLASS_AA:"class-aa-info",
    CLASS_A_ALT:"alternative-class-a-info",
    CLASS_A_TRD:"traditional-class-a-info",
    CLASS_B:"class-b-info",
    SCHOOL_PLACEMENT:"school-placement",
    TEAMS_CONTRACT_DETAILS:"contract-details",
    UPLOAD_LICENSE_COSMO:"upload-cosmo-instructor-license",
    UPLOAD_LICENSE_BARBER:"upload-barber-instructor-license",
    UPLOAD_LICENSE_OPHS:"upload-occupational-proficiency-health-science-license",
    UPLOAD_LICENSE_OP: "occupational-proficiency",
    UPLOAD_SIGNED_CERT: "signed-cert-upload",
    UPLOAD_EXP: "exp-upload",
    UPLOAD_TRANSCRIPT: "upload-transcript",
    UPLOAD_TRANSCRIPT_IHE: "upload-transcript-ihe",
    UPLOAD_TRANSCRIPT_LEA: "upload-transcript-lea",
    UPLOAD_TRANSCRIPT_ASSC_SCIENCE: "assc-science-transcript-upload",
    UPLOAD_MISC: "upload-misc",
    UPLOAD_LEA_DL: "lea-dl-upload",
    UPLOAD_3RDPARTY_LEA_DL: "3rdparty-dl-upload",
};

export const ARTIFACTS_ORDER = [
    ARTIFACT_KEY.PERSONAL,
    ARTIFACT_KEY.CITIZENSHIP,
    ARTIFACT_KEY.MILITARY,
    ARTIFACT_KEY.CRIMINAL,
    ARTIFACT_KEY.BACKGROUND,
    ARTIFACT_KEY.EDUCATIONAL,
    ARTIFACT_KEY.EDUCATIONAL_SUBSTITUTE,
    ARTIFACT_KEY.EDUCATIONAL_TE,
    ARTIFACT_KEY.EDUCATIONAL_HS,
    ARTIFACT_KEY.EXPERIENCE,
    ARTIFACT_KEY.POWERSCHOOL,
    ARTIFACT_KEY.PRAXIS,
    ARTIFACT_KEY.UPLOAD_LICENSE_OP,
    ARTIFACT_KEY.UPLOAD_MISC,
    ARTIFACT_KEY.TRANSCRIPTS,
    ARTIFACT_KEY.EXP,
    ARTIFACT_KEY.CER,
    ARTIFACT_KEY.UPLOADEXISTING,
    ARTIFACT_KEY.UPLOAD_LICENSE_COSMO,
    ARTIFACT_KEY.UPLOAD_LICENSE_BARBER,
    ARTIFACT_KEY.UPLOAD_LICENSE_OPHS,
    ARTIFACT_KEY.APPFEE,
    ARTIFACT_KEY.UPLOAD_TRANSCRIPT,
    ARTIFACT_KEY.UPLOAD_APPROVED_CHECKLIST,
    ARTIFACT_KEY.MILITARYFEE,
    ARTIFACT_KEY.ACCEPTANCE,

    ARTIFACT_KEY.PERSON,
    ARTIFACT_KEY.MENTOR,
    ARTIFACT_KEY.COMPENTENCY,
    ARTIFACT_KEY.CLASS_B,
    ARTIFACT_KEY.CLASS_A_ALT,
    ARTIFACT_KEY.CLASS_A_TRD,
    ARTIFACT_KEY.CLASS_AA,
    ARTIFACT_KEY.INTERNSHIP_PLACEMENT,
    ARTIFACT_KEY.SCHOOL_PLACEMENT,
    ARTIFACT_KEY.TEAMS_CONTRACT_DETAILS,

    ARTIFACT_KEY.UPLOAD_TRANSCRIPT_IHE,
    ARTIFACT_KEY.UPLOAD_TRANSCRIPT_LEA,
    ARTIFACT_KEY.UPLOAD_TRANSCRIPT_ASSC_SCIENCE,
    ARTIFACT_KEY.UPLOAD_SIGNED_CERT,
    ARTIFACT_KEY.UPLOAD_EXP,
    ARTIFACT_KEY.UPLOAD_APPROVED_CHECKLIST_IHE,
    ARTIFACT_KEY.UPLOAD_APPROVED_CHECKLIST_LEA,
    ARTIFACT_KEY.UPLOAD_LEA_DL,
    ARTIFACT_KEY.UPLOAD_3RDPARTY_LEA_DL,
    

    ARTIFACT_KEY.ACCEPTANCE_IHE,
    ARTIFACT_KEY.ACCEPTANCE_LEA,
    ARTIFACT_KEY.ACCEPTANCE_3rdParty,
    ARTIFACT_KEY.ACCEPTANCE_LEA_TEAMS,
    ARTIFACT_KEY.MOREINFO,
]

export const ARTIFACTS_BY_USER = [
    ARTIFACT_KEY.PERSONAL,
    ARTIFACT_KEY.MENTOR,
    ARTIFACT_KEY.CITIZENSHIP,
    ARTIFACT_KEY.MILITARY,
    ARTIFACT_KEY.CRIMINAL,
    ARTIFACT_KEY.EDUCATIONAL,
    ARTIFACT_KEY.EDUCATIONAL_SUBSTITUTE,
    ARTIFACT_KEY.EDUCATIONAL_TE,
    ARTIFACT_KEY.EDUCATIONAL_HS,
    ARTIFACT_KEY.EXPERIENCE,
    ARTIFACT_KEY.PRAXIS,
    ARTIFACT_KEY.INTERNSHIP_PLACEMENT,
    ARTIFACT_KEY.CLASS_AA,
    ARTIFACT_KEY.CLASS_A_ALT,
    ARTIFACT_KEY.CLASS_A_TRD,
    ARTIFACT_KEY.CLASS_B,
    ARTIFACT_KEY.SCHOOL_PLACEMENT,
    ARTIFACT_KEY.TEAMS_CONTRACT_DETAILS,
    ARTIFACT_KEY.MOREINFO,
    ARTIFACT_KEY.ACCEPTANCE,
    ARTIFACT_KEY.ACCEPTANCE_IHE,
    ARTIFACT_KEY.ACCEPTANCE_LEA,
    ARTIFACT_KEY.ACCEPTANCE_3rdParty,
    ARTIFACT_KEY.ACCEPTANCE_LEA_TEAMS,
]

export const APPDATA_POST_REQUIRED_ARCTIFACTS = [
    ARTIFACT_KEY.ACCEPTANCE,
    ARTIFACT_KEY.ACCEPTANCE_IHE,
    ARTIFACT_KEY.ACCEPTANCE_LEA,
    ARTIFACT_KEY.ACCEPTANCE_3rdParty,
    ARTIFACT_KEY.ACCEPTANCE_LEA_TEAMS,
    ARTIFACT_KEY.SCHOOL_PLACEMENT,
    ARTIFACT_KEY.TEAMS_CONTRACT_DETAILS
];

export const AGENT_ARTIFACTS_ACCEPTANCE = [
    ARTIFACT_KEY.ACCEPTANCE_IHE,
    ARTIFACT_KEY.ACCEPTANCE_LEA,
    ARTIFACT_KEY.ACCEPTANCE_3rdParty,
    ARTIFACT_KEY.ACCEPTANCE_LEA_TEAMS,
];

export const ARTIFACTS_ACCEPTANCE = [
    ...AGENT_ARTIFACTS_ACCEPTANCE,
    ARTIFACT_KEY.ACCEPTANCE,
];

export const ARTIFACTS_SUBMIT = [
    ...ARTIFACTS_ACCEPTANCE,
    ARTIFACT_KEY.MOREINFO
];