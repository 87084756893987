import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'src/app/core/services/message.service';
import { Message } from '../../../core/models/message.model';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { APPLICATION_ACTION_COMPELTE_STATUSES, APPLICATION_STATUS } from 'src/app/core/enums/application.enum';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { USER_ROLES } from 'src/app/core/enums/user.enum';
import { AlsdeUser } from 'src/app/core/models/alsde-user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  messages: Message[] = [];
  isLoaderMsg = true;
  user: AlsdeUser;
  @Input() appList: ApplicationWizard[] = [];
  constructor(
    public messageService: MessageService, 
    private router: Router, 
    private ls: LocalStorageService,
    private userService: UserService
  ) { 
    this.user = this.userService.getUser();
  }

  ngOnInit(): void {
    this.isLoaderMsg = true;
    const id = JSON.parse(this.ls.getItem('user'))?.alsdeId;
    this.messageService.getUnreadMessages('messages?userId='+id).subscribe({
      next: (res:any)=>{
        this.isLoaderMsg = false;
        if(res == null) return;
        this.messages = res.messages.filter((y:Message) => y.read == null).map((x:Message) => {
            x.link = 'message-center' ,
            x.linkName = 'Open Message Center',
            x.typeIcon = 'email';
            return x;
        }).sort((a:Message,b:Message)=>{
          return new Date(b.sent).getTime() - new Date(a.sent).getTime()
        });
      }, error: () => {
        this.isLoaderMsg = false;
      }
    });
  }

  redirect(Obj:any) {
    this.router.navigate(['/app/app-wizard/'+Obj.appId]);
  }

  appActionText(status:string){
    switch(status){
        case APPLICATION_STATUS.STARTED:
            return 'Continue Application'; break;
        case APPLICATION_STATUS.MOREINFO:
            return 'Submit More Info'; break;
        default:
            return "View Application"
    }
  }

  getAppList(isComplete:boolean){
    return this.appList.filter((app)=>{
      if(isComplete){
        return APPLICATION_ACTION_COMPELTE_STATUSES.includes(app.status?.Value || APPLICATION_STATUS.STARTED);
      }else{
        return !APPLICATION_ACTION_COMPELTE_STATUSES.includes(app.status?.Value || APPLICATION_STATUS.STARTED);
      }
    }).map((i: any) => {
      let isActionPending = (i.actionableRoles || []).includes(this.userService.getSelectedSiteRole()?.RoleName);
      if (isActionPending) {
        i.isActionPending = true;
      }
      return i;
    })
    // .sort((a:any, b:any) => {
    //   return new Date(b.startedDate).getTime() - new Date(a.startedDate).getTime();
    // })
    // .sort((a:any, b:any) => {
    //   return a.isActionPending==b.isActionPending ? 0 : a.isActionPending ? -1:1;
    // });
  }
}
