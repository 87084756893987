export class FeeSummaryModel {
  appId: string = '';
  userId: string = '';
  transactionId: string = '';
  transactionDate?: Date;
  feeTotal: number = 0;
  feeTotalPaid: number = 0;
  inMilitary: boolean = false;
  creditAmount: number = 0;
  creditUsed: number = 0;
  balanceAmount: number = 0.0;
  totalReceived: number = 0.0;
  totalDue?: number = 0;
  paidAmount: number = 0.0;
  token?: string;
  paymentUrl?: string = '';

  feeSummaryId: string = '';
  items: FeeItem[] = [];
}

export class FeeItemModel {
  feeAmount: number = 0.0;
  feeAmountPaid: number = 0.0;
  id: string = '';
  name: string = '';
  description: string = '';
}
export class FeeItem extends FeeItemModel {
  constructor(options?: FeeItemModel) {
    super();
    if (options) {
      this.feeAmount = options.feeAmount;
      this.feeAmountPaid = options.feeAmountPaid;
      this.name = options.name;
      this.description = options.description;
      this.id = options.id;
    }
  }
  toJSON() {
    return {
      feeAmount: this.feeAmount,
      feeAmountPaid: this.feeAmountPaid,
      name: this.name,
      description: this.description,
      id: this.id,
    };
  }
}
export class FeeSummary extends FeeSummaryModel {
  constructor(options?: FeeSummaryModel) {
    super();
    if (options) {
      this.appId = options.appId;
      this.userId = options.userId;
      this.transactionId = options.transactionId;
      this.transactionDate = options.transactionDate;
      this.feeTotal = options.feeTotal;
      this.feeTotalPaid = options.feeTotalPaid;
      this.inMilitary = options.inMilitary;
      this.creditAmount = options.creditAmount;
      this.creditUsed = options.creditUsed;
      this.balanceAmount = options.balanceAmount;
      this.totalReceived = options.totalReceived;
      this.totalDue = options.totalDue;
      this.paidAmount = options.paidAmount;
      this.token = options.token;
      this.paymentUrl = options.paymentUrl;

      this.feeSummaryId = options.feeSummaryId;

      if (options.items) {
        this.items = [];
        options.items.forEach((val) => {
          this.items.push(new FeeItem(val));
        });
      }
    }
  }

  /*
  toJSON() {
    return {
      appId: this.appId,
      userId: this.userId,
      transactionId: this.transactionId,
      feeAmount: this.feeAmount,
      creditAmount: this.creditAmount,
      totalDue: this.totalDue,
      paidAmount: this.paidAmount,
      paymentUrl: this.paymentUrl,
      feeSummaryId: this.feeSummaryId,
      feeItems: this.feeItems,
    };
  }
  */
}
