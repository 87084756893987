import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessagesMock } from '../mocks/messages.mock';
import { Message } from '../models/message.model';
import { AppHttpClient } from './app-http-client.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor( private http: AppHttpClient,
    public userService : UserService ) {
    
   }

  getUnreadMessages(url: string):Observable<Object>{
    return this.http.get(url,this.userService.getSelectedSiteRole());
    
  }

  updateReadMessage(url: string, data: Object) {
    return this.http.post(url, data);
  }
  getInProcessApps():Observable<Message[]>{
    return of(MessagesMock.inProcessApps.map(i=>new Message(i)))
  }

  getFileInfo(url: string, id: string) {
    return this.http.get(`${url}?id=${id}`);
  }
}
