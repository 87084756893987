import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerModel } from '../models/answer-model';
import { QuestionModel } from '../models/question.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  showAnswerInfo(answer: AnswerModel){
    if(answer.tooltip){
      this._snackBar.open(answer.tooltip,'Close', {
        duration: 13000
      })
    }
  }

  getFirstQuestion(questions: QuestionModel[]){
    return questions.find(i => i.isFirst);
  }

  getNextQuestion(answer: AnswerModel, questions: QuestionModel[]){
    return questions.find(i => answer.nextQuestionId == i.id);
  }

  canSubmitForm(question: QuestionModel){
    return question.isLast && question.canSubmit
  }
}
