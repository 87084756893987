import { Component, OnInit, OnChanges, Injector } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-educational-experience',
  templateUrl: './educational-experience.component.html',
  styleUrls: ['./educational-experience.component.scss']
})
export class EducationalExperienceComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  minFromDate: Date | string = new Date(1900, 0, 1);
  maxFromDate: Date | string = new Date();
  minToDate: Date | string = new Date(1900, 0, 1);
  maxToDate: Date | string = new Date();

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      startDate: ['', [Validators.required, ValidationService.noFutureDate]],
      endDate: ['', [ValidationService.noFutureDate]], // BM 9-21-23 End date is optional
      schoolName: ['', [Validators.required, Validators.maxLength(100)]],
      schoolLocation: ['', [Validators.required, Validators.maxLength(100)]],
      gradeSubject: ['', [Validators.required, Validators.maxLength(255)]],
    },{
      validator: ValidationService.dateNoMoreThan('endDate','startDate', 'start date'),
    });
  }

  createExperienceRecord(){
    if(!this.interalForm.value.endDate){
      this.interalForm.value.endDate = null;
    }
    this.jsonData.records.push(this.interalForm.value);
    this.resetDate();
    this.interalForm.reset();
    this.validateSection();
  }
  deleteExperienceRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }
  dateChanges(type: string, event: any) {
    if(!event){
        return;
    }
    if(type == 'start') {
      this.minToDate = new Date(event);
      this.minToDate.setDate(this.minToDate.getDate() + 1);
    }
    else {
        // BM 9-22-23 We should not allow the max date to be less then the start date
        let d = new Date(event);
        if(d < this.minToDate){
            return;
        }
        this.maxFromDate = new Date(event);
        this.maxFromDate.setDate(this.maxFromDate.getDate() - 1);
    }
  }
  resetDate(){
    this.minToDate = new Date(1900, 0, 1);
    this.maxFromDate = new Date();
  }
  
  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
  }
}
