import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { CertificationWizard } from '../models/certification-wizard.model';
import { AppHttpClient } from './app-http-client.service';
import { UtilService } from './util.service';
import { ARTIFACTS_ORDER } from '../constants/artifacts-order.constants';
import { UserService } from './user.service';
import { AnswerModel } from '../models/answer-model';

@Injectable({
  providedIn: 'root'
})
export class CertWizService {

  certWizard: CertificationWizard = new CertificationWizard();
  constructor(
    public http : AppHttpClient,
    public userService : UserService,
    public util : UtilService,
  ) { }

  init(){
    return this.http.get('cert-wiz',this.userService.getRoleQueryString()).pipe(
      tap((res:any)=>{
        res = this.checkCertWizardResponce(res);
        this.certWizard = new CertificationWizard(res);
      })
    );
  }

  checkCertWizardResponce(res:any) {
    if(res.questions.length){
      let question = res.questions[res.questions.length-1];
      question.selectedAnswers = question.selectedAnswers || [];
      if(!question.selectedAnswers.length){
        if(question.answerType.name == 'text'){
          question.selectedAnswers.push(new AnswerModel(question.answers[0]));
        }
      }
    }
    return res;
  }

  next(){
    return this.http.post('cert-wiz', this.certWizard.toJSON()).pipe(
      tap((res:any)=>{
        if(res.requiredArtifacts){
          res.requiredArtifacts = this.util.sortByArray(res.requiredArtifacts, ARTIFACTS_ORDER, (t)=>t?.key);
        }
        res = this.checkCertWizardResponce(res);
        this.certWizard = new CertificationWizard(res);
      })
    );
  }

  back(){
    return this.http.post('cert-wiz/back', this.certWizard.toJSON()).pipe(
      tap((res:any)=>{
        res = this.checkCertWizardResponce(res);
        this.certWizard = new CertificationWizard(res);
      })
    );
  }

}
