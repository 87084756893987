import { ARTIFACT_STATUS, ARTIFACT_TYPE } from "../enums/artifact.enum";
import { IMessage } from "../models/message.model";

export class MessagesMock {
    public static messages:IMessage[] = [
        {
            id : 1,
            title : 'Application Package Complete',
            subject : 'We have received all requirements for OTH 12/29/2022 Application',
            text : 'Congratulations, ALSDE has received all the required artifacts which constitutes a Complete Application Package for application ID 274326. Review of your application will now begin with ALSDE Certification staff. Keep an eye out for additional messages relating to this Certification Application ',
            link : '/app/message-center',
            linkName : 'Open Message Center',
            type :'message',
            typeIcon :'email',
            sent : new Date('06/20/2023').toString(),
            status:'unread',
            messageId: '123',
            body: 'Test',
            read: new Date()
        },
        {
            id : 2,
            title : 'Additional Information Required-2',
            subject : 'Subject: Additional Information Required-2',
            text : 'You have received feedback from the ALSDE certification staff regarding your OTH 12/29/2022 Application',
            link : '/app/message-center',
            linkName : 'Open Message Center',
            type :'message',
            typeIcon :'email',
            sent : new Date('11/21/2022').toString(),
            status:'unread',
            messageId: '123',
            body: 'Test',
            read: new Date()
        }
    ]

    public static inProcessApps:IMessage[] = [
        {
            id : 3,
            title : 'New OTH Approach Application-1',
            text : 'You have an in-process application',
            link : '/app/app-wizard',
            linkName : 'Continue Application',
            icon :'check_box',
            type :'application',
            typeIcon :'pending_actions',
            sent : new Date('01/20/2023').toString(),
            status:'unread',
            messageId: '123',
            body: 'Test',
            read: new Date()
        },
        {
            id : 4,
            title : 'New OTH Approach Application-2',
            text : 'You have an in-process application',
            link : '/app/app-wizard',
            linkName : 'Continue Application',
            icon :'check_box',
            type :'application',
            typeIcon :'pending_actions',
            sent : new Date('01/20/2023').toString(),
            status:'unread',
            messageId: '123',
            body: 'Test',
            read: new Date()
        }
    ]
}
