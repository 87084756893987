import { BGR_STATUS } from "../enums/background.enum";

export class IAlsdeBackground {
    alsdeId!: string;
    isBackgroundCheckRequired?: boolean;
    portalMessage?: string;
    systemOfRecord?: string;
    statusMessage: BGR_STATUS = BGR_STATUS.NO_INFO;
    statusDate?: string;
    verfiedDate?: string;
    fingerprintAppointments?: any[];
}

export class AlsdeBackground extends IAlsdeBackground{

    constructor(obj?:IAlsdeBackground){
        super();
        if(obj){
            this.alsdeId = obj?.alsdeId;
            this.isBackgroundCheckRequired = obj?.isBackgroundCheckRequired;
            this.portalMessage = obj?.portalMessage;
            this.systemOfRecord = obj?.systemOfRecord;
            this.statusMessage = obj?.statusMessage;
            this.statusDate = obj?.statusDate;
            this.fingerprintAppointments = obj?.fingerprintAppointments;
        }
    }

    toJSON(){
        return {
            alsdeId: this.alsdeId,
            isBackgroundCheckRequired : this.isBackgroundCheckRequired,
            portalMessage : this.portalMessage,
            systemOfRecord : this.systemOfRecord,
            statusMessage: this.statusMessage,
            statusDate: this.statusDate,
            verfiedDate: this.verfiedDate,
            fingerprintAppointments: this.fingerprintAppointments,
        }
    }

    setEmpty() {
        return null;
    }
}