import { UI_FEATURES } from "../enums/ui.enum";
import { USER_ROLES } from "../enums/user.enum";
export class ISiteRole {
    RoleName:string = USER_ROLES.APPLICANT;
    RoleLevel:number = 0;
    siteId:number = 0;
    SiteName:string = "";
    constructor(obj?:ISiteRole){
        if(obj){
            this.RoleName = obj?.RoleName;
            this.RoleLevel = obj?.RoleLevel;
            this.siteId = obj?.siteId;
            this.SiteName = obj?.SiteName;
        }
    }
}

export class IAlsdeUser {
    alsdeId!: string;
    firstName?: string;
    lastName?: string;
    dob?: Date;
    email?: string;
    roles: USER_ROLES[] = [];
    siteRoles: ISiteRole[] = [];
    permissions: UI_FEATURES[] = [];
    selectedSiteRole: ISiteRole = new ISiteRole();
}

export class AlsdeUser extends IAlsdeUser{

    constructor(obj?:IAlsdeUser){
        super();
        if(obj){
            this.alsdeId = obj?.alsdeId;
            this.firstName = obj?.firstName;
            this.lastName = obj?.lastName;
            this.dob = obj?.dob;
            this.email = obj?.email;
            this.roles = obj?.roles || [];
            this.siteRoles = obj?.siteRoles || [];
            this.permissions = obj?.permissions || [];
            if(obj?.selectedSiteRole?.RoleName){
                let sr = obj?.selectedSiteRole;
                this.selectedSiteRole = this.siteRoles.find(i=>i.RoleName == sr.RoleName && (!sr?.siteId || i.siteId == sr.siteId)) || new ISiteRole();
            }else{
                this.selectedSiteRole = this.siteRoles[0];
            }
        }
    }

    toJSON(){
        return {
            alsdeId: this.alsdeId,
            firstName : this.firstName,
            lastName : this.lastName,
            dob : this.dob,
            email: this.email,
            roles: this.roles,
            siteRoles: this.siteRoles,
            permissions: this.permissions,
            selectedSiteRole: this.selectedSiteRole,
        }
    }
}