import { Component, OnInit, Output, EventEmitter, OnChanges, Input, Injector } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subject, filter } from 'rxjs';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { CertQuestionsMock } from 'src/app/core/mocks/cert-questions.mock';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { Artifact } from 'src/app/core/models/artifact.model';
import { QuestionModel } from 'src/app/core/models/question.model';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-military-declaration',
  templateUrl: './military-declaration.component.html',
  styleUrls: ['./military-declaration.component.scss'],
})
export class MilitaryDeclarationComponent extends ArtifactBaseComponent implements OnInit, OnChanges {
  questions: QuestionModel[] = [];

  constructor(
    private injector: Injector,
    private _fb: FormBuilder,
    public override appWizService : AppWizService,
  ) {
    super(injector)
    this.interalForm = this._fb.group({
      'militaryQA0': ['', Validators.required],
      'militaryQA1': ['', Validators.required]
    });
    this.loadQuestions(JSON.parse(JSON.stringify(CertQuestionsMock.military)));
  }

  loadQuestions(questionJson: any[]) {
    this.questions = [];
    questionJson.forEach((element) => {
      this.questions.push(new QuestionModel(element));
    });

    this.interalForm.get('militaryQA0')?.valueChanges.subscribe((value) => {
      if(value === 'Yes') this.interalForm.get('militaryQA1')?.patchValue('No')
    });
    this.interalForm.get('militaryQA1')?.valueChanges.subscribe((value) => {
      if(value === 'Yes') this.interalForm.get('militaryQA0')?.patchValue('No')
    });
  }
  valueChange(event: any) {
    this.validateSection();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { answers: null };
    this.jsonData.answers = this.jsonData.answers || {};
    this.interalForm.patchValue(this.jsonData.answers)
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.answers = {};
    this.interalForm.reset();
  }

  override processBeforeSubmit(){
    this.questions[0].responseValue = this.interalForm.value.militaryQA0 || "";
    this.questions[1].responseValue = this.interalForm.value.militaryQA1 || "";
    this.jsonData = {questions:this.questions, answers:this.interalForm.value};
  }
}
