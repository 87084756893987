import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const token = this.authService.getAuth().token;
    if (token && request.url.includes(environment.apiURL)) {
      request = request.clone({
        setHeaders: {"Authorization": `Bearer ${token}`}
      });
      if(request.url.indexOf('/cert?id') > -1 || request.urlWithParams.indexOf('/forms?form') > -1) {
        request = request.clone({
          setHeaders: {"Accept": `application/pdf`}
        });
      }
    }
    return next.handle(request);
  }
}