<ol class="list-group rounded-0">
    <li class="list-group-item d-flex justify-content-between mt-1 border bg-color-primary"
        *ngFor="let noti of notifications;let idx = index;">
        <div class="ms-2 me-auto align-self-center">
            <div class="fw-bold">{{noti.title}}</div>
            <div>{{noti.description}}</div>
        </div>
        <button mat-icon-button (click)="remove(idx)">
            <mat-icon>cancel</mat-icon>
        </button>
    </li>
</ol>