import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errortostring',
  pure: false
})
export class ErrortostringPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const errorMsg : any = args[0];
    return this.errorToString(value, errorMsg.key, errorMsg.value);
  }

  errorToString(errorField:string, errorKey:string, errorValue:any) : String {
    let text = "";
    switch (errorKey) {
      case 'required': text = `${this.fistCap(errorField)} is required!`; break;
      case 'pattern': text = `${this.fistCap(errorField)} have wrong pattern!`; break;
      case 'email': text = `Please enter your valid ${errorField.toLowerCase()} !(eg.test@test.com)`; break;
      case 'minlength': text = `${this.fistCap(errorField)} should have a minimum of ${errorValue.requiredLength} digits!`; break;
      case 'maxlength': text = `${this.fistCap(errorField)} should have a maximum of ${errorValue.requiredLength} digits!`; break;
      case 'areEqual': text = `${this.fistCap(errorField)} should be equal!`; break;
      case 'invalidNumber': text = `${this.fistCap(errorField)} should be a number!`; break;
      case 'invalidCharacter': text = `The ${errorField.toLowerCase()} should be a character!`; break;
      case 'invalidUrl': text = `${this.fistCap(errorField)} should be a valid link!(eg.test.com)`; break;
      case 'invalidAddress': text = `${this.fistCap(errorField)} should be a valid address!`; break;
      case 'mustMatch': text = `${this.fistCap(errorField)} should match!`; break;
      case 'mustHasNumber': text = `${this.fistCap(errorField)} should have a number!`; break;
      case 'mustHasLowerChar': text = `${this.fistCap(errorField)} should have lowercase characters!`; break;
      case 'mustHasUpperChar': text = `${this.fistCap(errorField)} should have uppercase characters!`; break;
      case 'mustHasSpecialChar': text = `${this.fistCap(errorField)} should have a special character!`; break;
      case 'invalidBoolean': text = `${this.fistCap(errorField)} should be a 0 or 1!`; break;
      default: text = `${errorField}: ${errorKey} : ${errorValue}`;
    };
    return text;
  }

  fistCap(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
