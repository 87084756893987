import { Component } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Notification } from '../../../../core/models/notification.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  notifications:Notification[] = [];

  constructor(public notificationService: NotificationService){
    
  }

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe({
      next:(notifications)=>{
        this.notifications = notifications
      }
    });
  }

  remove(index:number){
    this.notifications.splice(index,1);
  }
}
