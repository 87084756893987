<mat-toolbar class="header p-0 p-sm-2 mat-toolbar-multiple-rows">
    <mat-toolbar-row>
        <a class="log-tag" [routerLink]="homePage()">
            <img title="Alabama Certification for Educators" class="fw-header__logo" id="desktop-log" src="../assets/logo-full.svg" />
            <img title="Alabama Certification for Educators" class="fw-header__logo" id="phone-log" src="../assets/logo-profile.png" />
        </a>
        <span class="example-spacer"></span>
        <div class="user-name" *ngIf="getUser().alsdeId">
            Hi {{ getUser().firstName }}
            <br />ALSDE ID: {{getUser().alsdeId}}
            <div class="d-flex flex-row">
                <label class="align-items-center d-flex me-2">Role:</label>
                <div>
                    <select class="form-select form-select-sm py-0" style="height: 90%;" (change)="changeRoleWiseDashboard()" [(ngModel)]="getUser().selectedSiteRole">
                        <option [ngValue]="siteRole" *ngFor="let siteRole of getUser().siteRoles">{{siteRole.RoleName}}<span *ngIf="siteRole.SiteName"> - </span>{{siteRole.SiteName}}</option>
                    </select>
                </div>
                </div>
        </div>
        <button mat-mini-fab class="app-button" color="primary" style="margin-top: 5px" aria-label="Help"
            (click)="navigate('/app/help')">
            <mat-icon class="material-icons md-dark">help_outline</mat-icon>
        </button>
        <button mat-mini-fab class="app-button" color="primary" style="margin-top: 5px" aria-label="Menu"
            [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons md-dark">menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a *ngIf="getUser().alsdeId" [routerLink]="homePage()" mat-menu-item><mat-icon color="accent">home</mat-icon>
                <span>Home</span></a>
            <a *ngIf="getUser().alsdeId" target="_blank" href="{{environment.externalUrl.alsdeUserProfile}}" mat-menu-item><mat-icon color="accent">person</mat-icon>
                <span>User Profile (AIM)</span></a>
            <a *ngIf="getUser().alsdeId" [routerLink]="['/app/message-center']" mat-menu-item><mat-icon color="accent">email</mat-icon>
                <span>Message Center</span></a>
            <button *ngIf="getUser().alsdeId" (click)="logout()" mat-menu-item><mat-icon color="accent">logout</mat-icon>
                <span>Logout</span></button>
            <a *ngIf="!getUser().alsdeId" [routerLink]="['/app/auth/login']" (click)="login()" mat-menu-item><mat-icon color="accent">login</mat-icon>
                <span>Login</span></a>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
<app-banner></app-banner>