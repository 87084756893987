<div class="container pt-5">
    <div class="row">
        <div class="col-6 col-md-6" style="    margin: 0 auto;">
            <div class="card p-5" style="align-items: center;">
                <div><mat-icon style="font-size: 30px;">warning</mat-icon></div>
                <div style="text-align: center;">
                    <div style="font-family: monospace;font-size: 25px;color: rgba(0, 0, 0, 0.87);">Payment Error</div>
                    <div style="color: rgba(0, 0, 0, 0.87);" class="py-2">Something went wrong with your payment, don't worry you haven't been charged. Please resubmit your payment.</div>
                </div>
            </div>
        </div>
    </div>
</div>