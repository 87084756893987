import { Component, OnInit } from '@angular/core';
import  packageJson from '../../../../../package.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  environment = environment;
  constructor() { }

  ngOnInit(): void {
    console.log("version: " + packageJson.version);
  }

}
