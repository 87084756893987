import { Component, OnInit, OnChanges, Injector} from '@angular/core';
import { FormControl, Validators, FormBuilder} from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-praxxis-assessment-list',
  templateUrl: './praxxis-assessment-list.component.html',
  styleUrls: ['./praxxis-assessment-list.component.scss'],
})
export class PraxxisAssessmentListComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  field: Array<any> = [{ viewValue: 'Test', value: 'test' }];
  grade: Array<any> = [{ viewValue: 'Test', value: 'test' }];

  constructor(
    private injector: Injector,
    private fb: FormBuilder, 
    public override appWizService: AppWizService
  ) {
    super(injector);
    this.interalForm = this.fb.group({
      teachingField: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      gradeLevel: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      testNumber: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      praxisName: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      testPassedDate: new FormControl('', [Validators.required,ValidationService.noFutureDate]),
    });
  }

  createRecord(){
    this.jsonData.records.push(this.interalForm.value);
    this.interalForm.reset();
    this.validateSection();
  }
  deleteRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
  }
}
