<div style="background: #1a1e4b;">
    <div class="container">
        <footer class="pb-3 pt-5">
            <ul class="nav justify-content-center pb-3 mb-3">
                <li class="nav-item"><a target="_blank" href="{{environment.social.facebook}}" class="px-3 social-icon"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li class="nav-item"><a target="_blank" href="{{environment.social.instagram}}" class="px-3 social-icon"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                <li class="nav-item"><a target="_blank" href="{{environment.social.twitter}}" class="px-3 social-icon"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li class="nav-item"><a target="_blank" href="{{environment.social.linkedin}}" class="px-3 social-icon"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
            <p class="text-center text-white"> © 2023 We Teach Alabama </p>
        </footer>
    </div>
</div>