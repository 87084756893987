
<div class="card">
    <div class="title">
        <mat-icon color="accent" title="Dashboard" class="title-icon">dashboard_customize</mat-icon>
        <h2>Dashboard</h2>
    </div>
    <div class="divider"></div>

    
    <h3>Messages</h3>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoaderMsg"></mat-progress-bar>
    <ng-container *ngIf="messages && messages.length > 0;else elseBlock">
        <div *ngFor="let item of messages">
            <app-dashboard-item [message]="item"></app-dashboard-item>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <mat-card><mat-card-content class="text-center">No new messages</mat-card-content></mat-card>
        <a class="mt-1" mat-stroked-button color="accent" [routerLink]="['/app/message-center']">Open Message Center</a>
    </ng-template>
    
    <div class="divider" style="margin-top: 15px;"></div>
    <h3>In-Process Applications</h3>
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="!appList"></mat-progress-bar> -->
    <div *ngFor="let item of getAppList(false)" class="pb-2">
        <!-- {{item|json}} -->
        <!-- <app-dashboard-item [message]="item"></app-dashboard-item> -->
        <div class="panel">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon class="panel-icon" color="accent"  title="{{item.isActionPending ? 'Action Pending':''}}">{{ item.isActionPending ? 'pending_actions' : '' }}</mat-icon>
                        <span class="px-2 date">{{item.startedDate | date:'MM/dd/yyyy'}} - {{item.appId}} - 
                        {{item.approach}} Application</span>
                    </mat-panel-title>
                    <!-- <mat-panel-description></mat-panel-description> -->
                </mat-expansion-panel-header>
                <div class="test-header">Status: <div class="test-val" [ngClass]="item.status?.Value" style="color:goldenrod;">{{item.status?.Value}}</div></div>
                <div class="content pt-3" *ngIf="['submitted'].indexOf(item.status?.Value)>-1">
                    Your application has been completed and submitted to the Alabama State Department of Education, Educator Certification Section. Although your application has been submitted, submission does not mean approved. Approval of submitted documents and eligibility for certificate issuance will be determined after your file is reviewed by a certification specialist. If needed, additional information may be requested.
                </div>
                <div style="margin-top:10px;">
                    <a mat-stroked-button color="accent" (click)="redirect(item)">{{appActionText(item.status?.Value)}}</a>
                </div>
            </mat-expansion-panel>
        </div>
    </div>

    <div class="divider" style="margin-top: 15px;"></div>
    <h3>Completed Applications</h3>
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="!appList"></mat-progress-bar> -->
    <div *ngFor="let item of getAppList(true)" class="pb-2">
        <!-- <app-dashboard-item [message]="item"></app-dashboard-item> -->
        <div class="panel">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon class="panel-icon" title="Pending Item" color="accent">pending_actions
                        </mat-icon>
                        <span class="px-2 date">{{item.startedDate | date:'MM/dd/yyyy'}} - {{item.appId}} - 
                        {{item.approach}} Application</span>
                    </mat-panel-title>
                    <!-- <mat-panel-description></mat-panel-description> -->
                </mat-expansion-panel-header>
                <div class="test-header">Status: <div class="test-val" [ngClass]="item.status?.Value" style="color:goldenrod;">{{item.status?.Value}}</div></div>
                <div class="content pt-3" *ngIf="['submitted'].indexOf(item.status?.Value)>-1">
                    Your application has been completed and submitted to the Alabama State Department of Education, Educator Certification Section. Although your application has been submitted, submission does not mean approved. Approval of submitted documents and eligibility for certificate issuance will be determined after your file is reviewed by a certification specialist. If needed, additional information may be requested.
                </div>
                <div style="margin-top:10px;">
                    <a mat-stroked-button color="accent" (click)="redirect(item)">{{appActionText(item.status?.Value)}}</a>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
    
</div>
