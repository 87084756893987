import { Component, Input } from '@angular/core';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent {

  @Input() appList: ApplicationWizard[] = [];
  constructor(
    private userService: UserService
  ){

  }

  getAppList(){
    return this.appList;
  }
}
