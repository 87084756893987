import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { from, mergeMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http: HttpService,
  ) { }

  deleteAllDocs(docs:any[]) {
    console.log(docs);
    const payload = {
      headers : new HttpHeaders({'Content-Type': 'application/json'}),
      body: {
        "id": "",
        "appId": docs[0].appId
      }
    };

    return from(docs).pipe(
      mergeMap(doc => {
        payload.body.id = doc.id;
        return this.http.delete('file', payload);
      }),
      mergeMap((x:any) => {
        return this.http.delete(x.preSignedURL)
      }),
    );
  }
}
