export enum BGR_STATUS{
    'VERIFIED' = 'Verified',
    'PENDING' = 'Pending',
    'FAILED' = 'Failed',
    'NO_INFO' = 'No Information',
    'REVOKED' = 'Revoked',
    'SUSPENDED' = 'Suspended',
    'DENIED' = 'Denied',
    'VOLUNTARY_SURRENDER' = 'Voluntary Surrender',
    'UNSUITABLE' = 'Unsuitable',
    'SURRENDERED' = 'Surrendered',
    'NOTICE_OF_PROPOSED_ACTION' = 'Notice of Proposed Action',
    'CLOSED_LETTER' = 'Closed Letter',
    'COMP_WITH_FINDINGS' = 'Completed with Findings',
    'PROBATION' = 'Probation',
    'SUITABLE' = 'Suitable',
    'MAIL_TO_SYS' = 'Mail to System',
    'SUITABLE_FOR_PRIVATE' = 'Suitable for Private',
    'PENDING_REVIEW' = 'Pending Review',
    'BGR_CHECK_REQUIRED' = 'Background Check Required',
}

export const FAILED_BGRS = [
    BGR_STATUS.REVOKED,
    BGR_STATUS.SUSPENDED,
    BGR_STATUS.DENIED,
    BGR_STATUS.SURRENDERED,
    BGR_STATUS.UNSUITABLE,
    BGR_STATUS.CLOSED_LETTER,
    // BGR_STATUS.VOLUNTARY_SURRENDER,
    // BGR_STATUS.NOTICE_OF_PROPOSED_ACTION,
    // BGR_STATUS.FAILED,
]

export const VERIFIED_BGRS = [
    BGR_STATUS.VERIFIED,
    BGR_STATUS.PROBATION,
    BGR_STATUS.SUITABLE,
    BGR_STATUS.MAIL_TO_SYS,
    BGR_STATUS.COMP_WITH_FINDINGS,
    BGR_STATUS.SUITABLE_FOR_PRIVATE,
]

export const PENDING_BGRS = [
    BGR_STATUS.PENDING,
    BGR_STATUS.PENDING_REVIEW,
]

export const NO_INFO_BGRS = [
    BGR_STATUS.NO_INFO,
    BGR_STATUS.PENDING_REVIEW,
    BGR_STATUS.BGR_CHECK_REQUIRED,
]