export class IAnswerModel {
  id!: string
  answerText: string = ""
  showTextbox!: boolean;
  textboxValue!: string; //used in frontend only
  signer!: string
  tooltip?: string
  approach?: string
  terminationMessage: any
  nextQuestionId?: string
  wizardComplete: boolean = false
  artifactKeys: string[] = []
  documents?: any[] = []
  answerCode:string=""
  grouping:string=""

  /*****temp******/
  requiredArtifacts: any[] = []
  selectedArtifact!: null
  visible!:boolean
  responseValue!: string
}

export class AnswerModel extends IAnswerModel {
  constructor(options?: IAnswerModel) {
    super()
    if (options) {
      this.id = options.id
      this.answerText = options.answerText ?? this.answerText;
      this.showTextbox = options.showTextbox
      this.tooltip = options.tooltip
      this.approach = options.approach
      this.terminationMessage = options.terminationMessage
      this.nextQuestionId = options.nextQuestionId
      this.signer = options.signer
      this.wizardComplete = options.wizardComplete
      this.artifactKeys = options.artifactKeys
      this.requiredArtifacts = options.requiredArtifacts
      this.documents = options.documents || [];
      this.selectedArtifact = options.selectedArtifact
      this.visible = options.visible
      this.responseValue = options.responseValue
      this.answerCode = options.answerCode
      this.grouping = options.grouping
    }
  }

  toJSON() {
    return {
      id: this.id,
      answerText: this.answerText,
      showTextbox: this.showTextbox,
      tooltip: this.tooltip,
      approach: this.approach,
      terminationMessage: this.terminationMessage,
      nextQuestionId: this.nextQuestionId,
      signer: this.signer,
      wizardComplete: this.wizardComplete,
      artifactKeys: this.artifactKeys,
      requiredArtifacts: this.requiredArtifacts,
      documents: this.documents,
      selectedArtifact: this.selectedArtifact,
      visible: this.visible,
      responseValue: this.responseValue,
      answerCode: this.answerCode,
      grouping: this.grouping,
    }
  }
}
