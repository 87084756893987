import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ARTIFACT_STATUS, ARTIFACT_TYPE, ARTIFACT_STATUS_LABEL, ARTIFACT_STATUS_CLASS } from 'src/app/core/enums/artifact.enum';
import { Artifact } from 'src/app/core/models/artifact.model';
import { CertWizService } from 'src/app/core/services/cert-wiz.service';
import { HttpService } from 'src/app/core/services/http.service';
import { UiStoreService } from 'src/app/core/services/ui-store.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ARTIFACT_KEY, ARTIFACTS_BY_USER } from 'src/app/core/constants/artifacts-order.constants';
import { AppConfiguration } from 'src/app/app-configuration';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { Subscription } from 'rxjs';
import { ROLE_NAME } from 'src/app/core/enums/ui.enum';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-app-required-item',
  templateUrl: './app-required-item.component.html',
  styleUrls: ['./app-required-item.component.scss']
})
export class AppRequiredItemComponent implements OnInit {

  @Input() sequenceNumber: number = 0;
  @Input() artifact!: Artifact
  @Input() addWizObj!: any;
  @Input() isApp: boolean = false;
  expandedArtifactId = '';
  ARTIFACT_STATUS = ARTIFACT_STATUS
  ARTIFACT_TYPE = ARTIFACT_TYPE
  ARTIFACT_STATUS_LABEL = ARTIFACT_STATUS_LABEL
  ARTIFACT_STATUS_CLASS = ARTIFACT_STATUS_CLASS
  ARTIFACT_KEY = ARTIFACT_KEY;
  locked: boolean | undefined = false;
  subscriptions: Subscription[] = [];
  appConfig: AppConfiguration = new AppConfiguration();
  constructor(
    public _ui: UiStoreService,
    public certWizService : CertWizService,
    public route : ActivatedRoute,
    private router: Router,
    private http: HttpService,
    public utilService: UtilService,
    public appWizService : AppWizService,
    public userService : UserService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this._ui.expandedArtifactId.subscribe((i) => this.expandedArtifactId = i || ''),
      this.appWizService.artifactUpdated$.subscribe(this.artifactUpdated.bind(this))
    ];
    this.appConfig.fileUploadConfig.maxFileCount = this.artifact.maxUpload || this.appConfig.fileUploadConfig.maxFileCount;
  }

  ngOnChanges() { }

  artifactUpdated(artifact: Artifact){
    if(artifact?.artifactId === this.artifact.artifactId){
      this.artifact = artifact;
    }
  }

  showTooltip(message: string) {
    alert(message);
  }

  onOpenClose(artifact: Artifact, isOpen: boolean) {
    if (isOpen) {
      console.log(artifact.key);
      console.log(artifact.artifactId);
      this._ui.expandedArtifactId.next(artifact.artifactId);
      if(ARTIFACTS_BY_USER.includes(artifact.key)){
        setTimeout(()=>this._ui.selectedTab.appWizard = 1,100);
      }
    }
  }

  requiredArtifactskeys(){
    return this.certWizService.certWizard.requiredArtifacts.map(i=>i.key);
  }

  canShowArtifact(){
    return true
    return this.requiredArtifactskeys().includes(this.artifact.key);
  }

  paymentRoute() {
    this.router.navigate(['/app/payment'], { 
      queryParams: { 
        appId: this.artifact.appId,
        feeSummaryId: this.artifact.feeSummaryId,
      }
    });
  }

  download(artifact: Artifact) {
    this.utilService.urlToBlob('forms', {form: artifact.formName, appId:artifact.appId}, artifact.name).subscribe((x:any) => {
      if(x){
        this.appWizService.updateDownloadedStatus(artifact);
      }
    });
  }

  TranscriptArtifactChecked(){
    this.appWizService.updateAppWizard(this.artifact).subscribe();
  }

  ngStyle(){
    let style:any = {};
    if((this.artifact.artifactStatus >= ARTIFACT_STATUS.SUBMIT)){
      style['background-color'] ='#f1f8e9';
    }
    else if((this.artifact.visibility == 'readonly')){
      style['background-color'] ='#dddddd';
    }
    else{
      style['background-color'] ='white';
    }

    if(this.expandedArtifactId === this.artifact.artifactId){
      style['border'] ='solid 3px #42a5f5';
    }
    return style;
  }

  feeSummary(){
    let feeSummaryId = this.artifact.feeSummaryId;
    return this.appWizService.appWizard.feeSummaries.find((i:any)=>i.feeSummaryId == feeSummaryId)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  isReadonly(){
    return this.artifact.visibility == "readonly";
  }
}
