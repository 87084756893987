import { Component, OnInit, Output, Input, EventEmitter, forwardRef } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Message } from '../../../../core/models/message.model';
import { QuestionService } from '../../../../core/services/question.service';
import { AnswerModel } from '../../../../core/models/answer-model';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';
import { QuestionModel } from '../../../../core/models/question.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionComponent),
      multi: true,
    }
  ]
})
export class QuestionComponent implements OnInit, ControlValueAccessor {

  @Output() valueChange = new EventEmitter<QuestionModel>();
  @Output() emittedObj = new EventEmitter<QuestionModel>();
  @Input() public question: QuestionModel = new QuestionModel();
  @Input() public showControlButtons = true;
  @Input() public showTooltipIcon = true;
  @Input() public showHelpIcon = true;
  @Input() public disabled: boolean | undefined = false;

  customForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private questionService: QuestionService,
    private _fb: FormBuilder,
  ) { 
    this.customForm = this._fb.group({
      customControl: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.customForm.get('customControl')?.valueChanges.subscribe((value) => {
      this.onChanged(value);
      this.onTouched();
    });
  }

  onChanged: any = () => { };

  onTouched: any = () => { };

  writeValue(obj: any): void {
    this.customForm.get('customControl')?.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valueChanged(event: any, answer: AnswerModel) {
    if (event.source.checked) {
      this.question.responseValue = answer.answerText;
      this.customForm.get('customControl')?.patchValue(event.value);
      this.valueChange.emit(this.customForm.getRawValue());
      this.emittedObj.emit(this.question);
      if (answer.terminationMessage?.text) {
        this.showTooltip(answer.terminationMessage.text, 'Certification Approach not Currently Supported')
      }
    }
  }

  showTooltip(value: string, title?: string) {
    let msg = new Message({} as Message);
    msg.text = this.question?.tooltip || '';
    msg.title = title || 'Question Details';
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: { msg: msg }, width: '500px'
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
  answerTooltip(value: AnswerModel) {
    this.questionService.showAnswerInfo(value);
  }
  next() {

  }
  back() {

  }
}
