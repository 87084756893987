

  <div class="card">
    <div class="card-header">
      <div class="d-flex">
        <h4 class="me-2 mb-0"> 
          Note to Applicant
        </h4>
        <mat-icon color="accent" title="Help" class="question-tooltip cursor-pointer" (click)="util.openMsgDialog(helpText)">
          help_outline</mat-icon>
      </div>
    </div>
    <div class="card-body">
      <app-form-control type="textarea" [form]="interalForm" title="Note to Applicant" placeholder="Note to Applicant" controlName="rejectReason"></app-form-control>
    </div>
  </div>


  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [disabled]="interalForm.invalid" (click)="requestApplicantResubmit()">Submit</button>
  </mat-dialog-actions>