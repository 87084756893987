import { Component, Injector, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UserLookupComponent } from 'src/app/shared/components/user-lookup/user-lookup.component';

@Component({
  selector: 'app-internship-placement',
  templateUrl: './internship-placement.component.html',
  styleUrls: ['./internship-placement.component.scss']
})
export class InternshipPlacementComponent extends ArtifactBaseComponent implements OnInit, OnChanges{

  @ViewChild(UserLookupComponent) userLookup!: UserLookupComponent;
  gradePlacementList = [
    {viewValue:"Pre-K",value:'Pre-K'},
    {viewValue:"K",value:'K'},
    {viewValue:"1",value:'1'},
    {viewValue:"2",value:'2'},
    {viewValue:"3",value:'3'},
    {viewValue:"4",value:'4'},
    {viewValue:"5",value:'5'},
    {viewValue:"6",value:'6'},
    {viewValue:"7",value:'7'},
    {viewValue:"8",value:'8'},
    {viewValue:"9",value:'9'},
    {viewValue:"10",value:'10'},
    {viewValue:"11",value:'11'},
    {viewValue:"12",value:'12'},
  ]

  constructor(
    private injector: Injector,
    public fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      schoolPlacement: ['', [Validators.required, Validators.maxLength(100)]],
      gradePlacement: ['', [Validators.required, Validators.maxLength(100)]],
      alsdeId: ['', [Validators.required, Validators.maxLength(100)]],
      firstName: [''],
      lastName: [''],
    });
  }

  createRecord(){
    this.jsonData.records.push(this.interalForm.value);
    this.interalForm.reset();
    this.validateSection();
    this.userLookup.reset();
  }
  deleteRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
    this.userLookup.reset();
  }

  override processBeforeSubmit(){
    this.artifact.expectedCount = this.jsonData.records?.length;
  }

  onUserFound(user:any){
    console.log("Demographic user found",user);
    this.interalForm.patchValue({
      alsdeId:user.demographics.alsdeId,
      firstName:user.demographics.firstName,
      lastName:user.demographics.lastName
    });
  }
}
