<div class="row align-items-center">
    <!-- <h3>User Lookup</h3> -->
    <div class="col-auto">
        <label for="alsdeid" class="col-form-label">ALSDE ID</label>
    </div>
    <div class="col-auto">
        <input type="text" [(ngModel)]="formAlsdeId" id="alsdeid" class="form-control">
    </div>
    <div class="col-auto">
        <button mat-stroked-button (click)="searchUser()" [disabled]="!formAlsdeId">Select</button>
    </div>
</div>
<div class="row mt-2">
    <div class="col-auto">
        <table class="table table-borderless text-start">
            <tbody>
                <tr><th>ALSDE ID:</th><td (click)="dialogService.viewCertificates(alsdeUser?.demographics?.alsdeId)">
                    <button type="button" class="btn btn-link link-dark px-0">{{alsdeUser?.demographics?.alsdeId}}</button></td></tr>
                <tr><th>First Name:</th><td>{{alsdeUser?.demographics?.firstName}}</td></tr>
                <tr><th>Last Name:</th><td>{{alsdeUser?.demographics?.lastName}}</td></tr>
                <!-- <tr><th>Address:</th><td>{{alsdeUser?.demographics?.address1}}</td></tr> -->
                <tr><th>Date of Birth:</th><td>{{alsdeUser?.demographics?.birthDetails?.dateOfBirth}}</td></tr>
                <tr><th>Sex:</th><td>{{alsdeUser?.demographics?.sex}}</td></tr>
                <tr><th>Phone Number:</th><td>{{alsdeUser?.demographics?.workPhone}}</td></tr>
                <tr><th>Email:</th><td>{{emailAddresses()}}</td></tr>
                <tr><th>Race:</th><td>{{alsdeUser?.demographics?.raceEthnicity?.race}}</td></tr>
                <tr><th>Background Info:</th><td style="max-width: 200px;">
                    <mat-icon *ngIf="FAILED_BGRS.includes(alsdeUser?.background?.statusMessage)" class="panel-icon fail" title="failed" >gpp_bad</mat-icon>
                    <span class="align-text-top" style="display: inline-table;margin-top: -8px;">{{alsdeUser?.background?.statusMessage}}</span>
                    <span class="align-text-top" style="display: inline-table;margin-top: -8px;">{{alsdeUser?.background?.portalMessage	}}</span>
                    <app-read-more *ngFor="let item of (alsdeUser?.background?.fingerprintAppointments || [])" [text]="userService.fingerprintAppointmentText(item)"></app-read-more>
                </td></tr>
            </tbody>
        </table>
    </div>
</div>