<div class="card">
    <div class="title">
        <mat-icon color="accent" title="Certifications" class="title-icon">verified</mat-icon>
        <h2>Certifications</h2>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoader"></mat-progress-bar>
    <div class="divider"></div>
    <div *ngIf="getCertByValid(true).length">
        <h3>Current</h3>
        <div *ngFor="let cert of getCertByValid(true)">
            <app-cert-item [cert]="cert" [alsdeId]="alsdeId"></app-cert-item>
        </div>
    </div>
    <mat-card *ngIf="!getCertByValid(true).length">
        <mat-card-content>No Current Certificate</mat-card-content>
    </mat-card>

    <div class="divider" style="margin-top: 15px;"></div>
    <mat-expansion-panel *ngIf="getCertByValid(false).length">
        <mat-expansion-panel-header>
            <mat-panel-title>
                History
            </mat-panel-title>
            <!-- <mat-panel-description></mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="content">
            <div *ngFor="let cert of getCertByValid(false)">
                <app-cert-item [cert]="cert" [alsdeId]="alsdeId"></app-cert-item>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-card *ngIf="!getCertByValid(false).length">
        <mat-card-content>No History</mat-card-content>
    </mat-card>
</div>