<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div>
                <mat-icon color="accent" title="{{item.isActionPending ? 'Action Pending':''}}" class="title-icon">{{ item.isActionPending ? 'pending_actions' : '' }}</mat-icon>
                <span class="px-2 pt-1 date align-top" style="display: inline-block;">
                    {{item.appId}} - 
                    {{item.applicantId}} - 
                    {{item.firstName}} {{item.lastName}} - 
                    {{item.approach}} - 
                    {{item.startedDate | date:'MM/dd/yyyy'}}
                </span>
            </div>
        </mat-panel-title>
        <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
    <div class="mt-2">
        <div class="test-header d-flex">Originator Id: {{item.startedBy}}</div>
        <div class="test-header d-flex">Originator Name: {{item.originatorFirstName}} {{item.originatorLastName}}</div>
        <div class="test-header d-flex">Status: <div class="test-val ms-1" [ngClass]="item.status?.Value" style="color:goldenrod;"> {{item.status?.Value || APPLICATION_STATUS.STARTED}}</div></div>
    </div>
    <div class="content pt-3" *ngIf="[APPLICATION_STATUS.SUBMITED].indexOf(item.status?.Value || APPLICATION_STATUS.STARTED)>-1">
        Your application has been completed and submitted to the Alabama State Department of Education, Educator Certification Section. Although your application has been submitted, submission does not mean approved. Approval of submitted documents and eligibility for certificate issuance will be determined after your file is reviewed by a certification specialist. If needed, additional information may be requested.
    </div>
    <div style="margin-top:10px;">
        <a mat-stroked-button color="accent" (click)="redirect(item)">{{appActionText(item.status?.Value || APPLICATION_STATUS.STARTED)}}</a>
    </div>
</mat-expansion-panel>