import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { IAlsdeBackground, AlsdeBackground } from 'src/app/core/models/background.model';
import { UtilService } from 'src/app/core/services/util.service';
import { BGR_STATUS, FAILED_BGRS, NO_INFO_BGRS, PENDING_BGRS, VERIFIED_BGRS } from 'src/app/core/enums/background.enum';

@Component({
  selector: 'app-background-review',
  templateUrl: './background-review.component.html',
  styleUrls: ['./background-review.component.scss']
})
export class BackgroundReviewComponent implements OnInit {

  @Input ('bgrData') bgrData: IAlsdeBackground = new AlsdeBackground();
  @Input ('goToFieldprint') goToFieldprint: boolean = true;

  FAILED_BGRS = FAILED_BGRS;
  VERIFIED_BGRS = VERIFIED_BGRS;
  PENDING_BGRS = PENDING_BGRS;
  NO_INFO_BGRS = NO_INFO_BGRS;
  constructor(
    public userService: UserService,
    public util: UtilService
  ) { }

  ngOnInit(): void { }

  getLength(data: IAlsdeBackground) {
    if(data) return Object.keys(data).length;
    else return null;
  }

  

}
