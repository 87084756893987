import { Component, OnInit } from '@angular/core';
import { CertWizService } from 'src/app/core/services/cert-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cert-wizard',
  templateUrl: './cert-wizard.component.html',
  styleUrls: ['./cert-wizard.component.scss']
})
export class CertWizardComponent implements OnInit {

  constructor(
    public certWizService : CertWizService,
    public appWizService : AppWizService,
    public utilService: UtilService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.initCertWizard();
  }

  initCertWizard() {
    this.certWizService.init().subscribe();
  }

  progressValue(){
    let isComplete = this.certWizService.certWizard.isComplete;
    let length = this.certWizService.certWizard.questions.length;
    let value = (length-1)*15;
    if(isComplete){
      return 100;
    }
    else if(value<=75){
      return value;
    }
    else{
      return 100-25/Math.pow(1.3,length-6);
    }
  }

  createNewApp() {
    this.appWizService.createAppWizard(this.certWizService.certWizard).subscribe({
      next: (res:any)=>{
        this.router.navigate(['/app/app-wizard',res.appId]);
      }
    });
  }

  back(){
    this.certWizService.back().subscribe();
  }
}
