<div>
    <div [innerHTML]="artifact.instructions" *ngIf="artifact.useInstructions"></div>
    <div class="description pt-3"  *ngIf="!artifact.useInstructions">
        <div>
            <p><span >
                I understand the Educator Certification Section is unable to determine eligibility for Alabama certification until all required application components have been received and reviewed. Additional information may be requested upon review of the file.
            </span></p>
            <p><span >
                I understand that I must meet all Alabama certification requirements in effect on the date the application is received in the Educator Certification Section. Since certification requirements are subject to change, current requirements may be viewed at <a href="https://www.alabamaachieves.org" target="_blank">https://www.alabamaachieves.org</a> (click Teachers & Administrators -> Teacher Center -> Teacher Certification).
            </span></p>
            <p><span >
                I understand that I must thoroughly read all requirements of this approach.
            </span></p>

            <p><span >
                I understand that it is my responsibility to keep all personal data on file and in my AIM account current.
            </span></p>

            <p><span >
                I understand that I must have access to the email address used in the creation of my AIM account to receive all messages and correspondence notifications sent to me in the ACE online application system. 
            </span></p>

            <p><span >
                I understand that it is my responsibility to maintain my certificate(s) and to stay informed of current regulations for renewal.
            </span></p>


            <p><span >
                By affixing my electronic signature to this document, I am certifying all information pertaining to this application form is true and correct and failure to submit accurate information may result in revocation or non-issuance of my certificate.
            </span></p>


            <p><span >
                I understand that by affixing my electronic signature to this document, it is the legal equivalent of my manual/handwritten signature.
            </span></p>

        </div>
    </div>
    <div class="primary">
        <form [formGroup]="interalForm">
            <div class="row">
                <mat-checkbox class="example-margin pb-3" formControlName="agree">
                    <span style="font-size: 16px;white-space: normal !important;">
                        <b>By checking this box, I agree to the above statements and submit my printed name as signature</b>
                    </span>
                </mat-checkbox>
                <div class="row col-12 justify-content-between">
                    <app-form-control type="text" [ngClass]="{'col-8':!isMobile, 'col-12':isMobile}"  [form]="interalForm" title="Name" placeholder="Enter your full name" controlName="userName"></app-form-control>
                    <app-form-control type="date" [ngClass]="{'col-4':!isMobile, 'col-12':isMobile}" [form]="interalForm" title="Date" placeholder="Enter Date" controlName="date"></app-form-control>
                </div>
                <!-- <app-form-control type="text" class="col-10" [form]="interalForm" title="Signature of the Applicant" placeholder="Enter your Name" controlName="signature"></app-form-control> -->
            </div>
        </form>
    </div>
</div>