import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, of, forkJoin } from 'rxjs';
import { Auth, IAuth } from '../models/auth.model';
import { AppHttpClient } from './app-http-client.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { delay, map, takeUntil } from 'rxjs/operators';
import { AlsdeUser } from '../models/alsde-user.model';
import { PERMISSIONS_BY_ROLE } from '../enums/ui.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _auth = new Auth({} as Auth);
  private unsubscriber : Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    public ls: LocalStorageService,
    public userService: UserService,
    private http: AppHttpClient,
  ) {
    const auth = this.ls.getItem('auth');
    this._auth = auth ? new Auth(JSON.parse(auth)) : this._auth;
  }

  getAuth(): Auth {
    return this._auth;
  }

  setAuth(auth: IAuth) {
    this.ls.setItem('auth', JSON.stringify(auth));
    this._auth = new Auth(auth);
    
  }

  validateToken(token: string) {
    return forkJoin([
      this.getRolesAccessList(),
      this.http.get('authenticate?token=' + token)
    ]).pipe(
      map(([rolesAccess, userData]: [any,any]) => {
        if (userData && userData.Token) {
          userData.token = userData.Token;
        }
        this.setAuth(new Auth(userData));
        this.userService.fetchUser(userData.Token, rolesAccess);
        return this.userService.getUser(); 
      })
    );
  }

  logout() {
    this.ls.clear();
    this.userService.setUser(new AlsdeUser());
    this.router.navigate(['/app/auth/login']);
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  getRolesAccessList(){
    return of(PERMISSIONS_BY_ROLE).pipe(delay(1000));
  }
}
