import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  public getString (object: any) {
    let paramsAndValues = [];
    for(let key in object) {
      let value = encodeURIComponent((object[key] || "").toString());
      paramsAndValues.push([key, value].join('='));
    }
    return paramsAndValues.join('&');
  }

  public postString (object: any) {
    var body = new FormData();
    for(let key in object) {
      let value = encodeURIComponent(object[key].toString());
      body.append(key,value);
    }
    return body;
  }

  public toFormData( formValue: any ) {
    const formData = new FormData();
  
    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }
  
    return formData;
  }

  public prefixUri(uri: string) {
    if (uri.indexOf('://') > -1) {
        return uri;
    }
    return `${environment.apiURL}${uri}`;
  }

  public get(url: string, data?: any, options?: any) {
    if(!url.includes('?')) url += "?";
    return this.http.get(this.prefixUri(url) + this.getString(data), options);
  }  
  
  public post(url: string, data?: any, options?: any) {
    return this.http.post(this.prefixUri(url), data, options);
  }  
  
  public put(url: string, data?: any, options?: any) {
    return this.http.put(this.prefixUri(url), data, options);
  }  
  
  public delete(url: string, options?: any) {
    return this.http.delete(this.prefixUri(url), options);
  }
  
}
