import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'src/app/core/models/message.model';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss'],
})
export class DashboardItemComponent implements OnInit {
   
    @Input() message:Message = new Message({} as Message);
  
  constructor(private router: Router) {}

  ngOnInit(): void {}

  open(route:string){
    this.router.navigate([route]);
  }

  navigate(Obj:Message) {
    this.router.navigate(['/app/'+Obj.link], { queryParams: { id: Obj.messageId }});
  }
}
