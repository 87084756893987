


<div class="d-flex flex-nowrap">
    <div [ngClass]="{'show-less': showShortDesciption}">
        <span [innerHTML]="text"></span> &nbsp;
        <a class="btn-more-less" (click)="alter()" *ngIf="!showShortDesciption">less</a>
    </div>
    <a class="btn-more-less" (click)="alter()" *ngIf="showShortDesciption">more</a>
</div>
  