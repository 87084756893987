export class IBtnAction {
    text: string = "";
    internalLink?: string;
    externalLink?: string;
    downloadLink?: string;
}

export class BtnAction extends IBtnAction {

    constructor(obj?: IBtnAction) {
        super();
        if (obj) {
            this.text = obj?.text;
            this.internalLink = obj?.internalLink;
            this.externalLink = obj?.externalLink;
            this.downloadLink = obj?.downloadLink;
        }
    }

    toJSON() {
        return {
            text: this.text,
            internalLink: this.internalLink,
            externalLink: this.externalLink,
            downloadLink: this.downloadLink,
        }
    }
}