
<div class="card">
    <div class="title">
        <mat-icon color="accent" title="Checklist" class="title-icon">assignment_ind</mat-icon>
        <h2>Background Review</h2>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="!getLength(bgrData)"></mat-progress-bar>
    <div class="divider"></div>
    <div class="panel">
        <mat-expansion-panel *ngIf="getLength(bgrData) && bgrData?.alsdeId ;else elseBlock">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon *ngIf="FAILED_BGRS.includes(bgrData.statusMessage)" class="panel-icon fail" title="failed" >gpp_bad</mat-icon>
                    {{bgrData.statusMessage}}
                </mat-panel-title>
                <!-- <mat-panel-description></mat-panel-description> -->
            </mat-expansion-panel-header>
            <div class="content">
                <div class="item">Last Activity: <span class="value">{{bgrData.statusDate ? (bgrData.statusDate | date: 'MM/dd/yyyy') : 'N/A'}}</span> </div>
                <br/>
                <div class="divider"></div>
                <div class="pb-2">{{bgrData.portalMessage}}</div>
            </div>
            <app-read-more *ngFor="let item of (bgrData?.fingerprintAppointments || [])" [text]="userService.fingerprintAppointmentText(item)"></app-read-more>
            <br/>
            <button mat-stroked-button color="accent" (click)="util.gotToFieldPrint()" *ngIf="!userService.isEduAgent() && goToFieldprint">Go to Fieldprint</button>
        </mat-expansion-panel>
    </div>
</div>
<ng-template #elseBlock>
    <mat-card><mat-card-content class="text-center">No data found.</mat-card-content></mat-card>
</ng-template>