<div class="container">
    <div class="question">
        <div class="question-text">
            {{ question.questionText }}
        </div>
        <mat-icon color="accent" *ngIf="showHelpIcon" title="Help" class="question-tooltip cursor-pointer" (click)="showTooltip(question.tooltip || '')">
            help_outline</mat-icon>
    </div>
    <div [ngSwitch]="question.answerType.name" class="px-2">

        <!-- Free Text Control -->
        <mat-form-field *ngSwitchCase="'text'" appearance="outline" class="control">
            <mat-label>Enter answer here</mat-label>
            <input *ngSwitchCase="'text'" matInput />
        </mat-form-field>

        <!-- Yes/No Control -->
        <div class="card" *ngSwitchCase="'single'" appearance="outline" class="control form-control">
            <ng-container [formGroup]="customForm">           
                <mat-radio-group aria-label="Select Yes or No" formControlName="customControl">
                    <div *ngFor="let answer of question?.answers" class="d-flex">
                        <mat-radio-button class="radio-button" [disabled]="disabled" [value]="answer.answerText" [checked]="question.responseValue == answer.answerText" (change)="valueChanged($event, answer)">
                        <!-- <mat-radio-button class="radio-button" [value]="answer.answerText" [checked]="false" (change)="valueChanged($event, answer)"> -->
                            <span class="wrap-mat-radio-label">{{answer.answerText}}</span>
                        </mat-radio-button>
                        <mat-icon *ngIf="showTooltipIcon" color="accent-dark" title="More Information" class="answer-tooltip"
                            (click)="answerTooltip(answer)">info</mat-icon>
                    </div>
                </mat-radio-group>
            </ng-container>
        </div>

        <!-- Multi Choice Control -->
        <div *ngSwitchCase="'multi-choice'" appearance="outline" class="control form-control">
            <div *ngFor="let answer of question?.answers" class="d-flex">
                <mat-checkbox class="radio-button" [disabled]="disabled" [value]="answer.answerText" (change)="valueChanged(this, answer)">{{answer.answerText}}
                </mat-checkbox>
                <mat-icon color="accent-dark" title="More Information" class="answer-tooltip"
                    (click)="answerTooltip(answer)">info</mat-icon>
            </div>
        </div>

    </div>
    <div *ngIf="showControlButtons" style="margin-top: 10px;">
        <!-- <button *ngIf="!question.isFirst" mat-stroked-button color="accent" (click)="back()" style="margin-right:5px;">Back</button> -->
        <!-- <button *ngIf="!question.isLast" mat-stroked-button color="accent" (click)="next()" style="margin-left:5px">Next</button> -->
    </div>
</div>
