import { Input, Directive, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Subscription, filter } from 'rxjs';
import { ARTIFACTS_SUBMIT, ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { APPLICATION_STATUS } from 'src/app/core/enums/application.enum';
import { ARTIFACT_SUBMMITED_BY } from 'src/app/core/enums/artifact.enum';
import { Artifact } from 'src/app/core/models/artifact.model';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UiStoreService } from 'src/app/core/services/ui-store.service';
import { UploadService } from 'src/app/core/services/upload.service';

@Directive()
export abstract class ArtifactBaseComponent {

  @Input() artifact: Artifact = {} as Artifact;
  @Input() stepControl: FormControl = {} as FormControl;
  @Input() isMobile: boolean = true;
  @Input() submit$ = new Subject();
  @Input() reset$ = new Subject();

  isSelectedArtifact: boolean = false;
  locked: boolean = false;
  readonly: boolean = false;
  isExpired: boolean = false;
  jsonData: any = {};
  interalForm: FormGroup = new FormGroup({});
  subscriptions: Subscription[] = [];
  appWizService!: AppWizService;
  uploadService!: UploadService;
  _ui!: UiStoreService;

  constructor(private injectorObj: Injector) { 
    this.appWizService = this.injectorObj.get(AppWizService);
    this._ui = this.injectorObj.get(UiStoreService);
    this.uploadService = this.injectorObj.get(UploadService);
  }

  abstract initJsonData() : void
  abstract resetJsonData() : any
  abstract validateJsonData() : void

  ngOnInit(): void {
    this.subscriptions = [
      this.reset$.pipe(filter(x => x == this.artifact.artifactId+this.isMobile)).subscribe(this.resetSection.bind(this)),
      this.submit$.pipe(filter(x => x == this.artifact.artifactId+this.isMobile)).subscribe(this.triggerSubmit.bind(this)),
      this._ui.jsonData$.pipe(filter((x:any) => x[0] == this.artifact.artifactId+this.isMobile)).subscribe(this.updateJsonData.bind(this)),
      this.appWizService.artifactUpdated$.subscribe(this.artifactUpdated.bind(this)),
      this._ui.expandedArtifactId.subscribe(this.onSelectedArtifactChanged.bind(this))
    ];

    this.initArtifact();
  }

  initArtifact(){
    this.jsonData = this.appWizService.getArtifactJsonData(this.artifact);
    this.initJsonData();
    setTimeout(()=>{
      this.initJsonData();
      this.validateSection();
    },200);
  }

  ngOnChanges() {
    this.checkLocked();
    this.ngxOnChanges();
  }

  checkLocked() {
    if(this.isReadOnly()) {
      this.interalForm.disable();
    }else{
      this.interalForm.enable();
    }
  }

  isReadOnly(){
    return this.appWizService.isReadOnly(this.artifact);
  }

  artifactUpdated(artifact: Artifact){
    if(artifact?.artifactId === this.artifact.artifactId){
      this.artifact = artifact;
      this.ngOnChanges();
    }
  }

  validateSection(){
    this.validateJsonData();
    this.stepControl.setValue(this.artifact.sectionValid);
    this._ui.jsonData$.next([this.artifact.artifactId+!this.isMobile, this.jsonData, this.interalForm.value]);
  }

  updateJsonData(data:any){
    // console.log(data);
    this.jsonData = data[1];
    this.interalForm.patchValue(data[2], {emitEvent: false});
  }

  resetSection(){
    this.interalForm.reset();
    let unUsedDocuments = this.resetJsonData();
    if(unUsedDocuments?.length){
      this.uploadService.deleteAllDocs(unUsedDocuments).subscribe(()=>{
        this.submitSection();
      });
    }else{
      this.submitSection();
    }
  }  

  triggerSubmit(){
    if(this.artifact.sectionValid){
      this.artifact.submittedBy = ARTIFACT_SUBMMITED_BY.SUBMIT_CLICK;
      this.submitSection();
    }
  }

  submitSection(){
    this.validateSection();
    this.processBeforeSubmit();
    this.appWizService.setArtifactJsonData(this.artifact, this.jsonData);
  }

  onDocumentUpdate(){
    this.artifact.submittedBy = ARTIFACT_SUBMMITED_BY.FILE_UPLOAD;
    this.submitSection();
  }

  onSelectedArtifactChanged(selectedArtifactId:string){
    if(this.isMobile == this._ui.isMobile){ //to remove multiple calls
      let isSelectedArtifact = selectedArtifactId == this.artifact.artifactId;
      if(this.isSelectedArtifact && !isSelectedArtifact){
        // console.log("need to submit", this.artifact.name);
        if(!ARTIFACTS_SUBMIT.includes(this.artifact.key)){
          this.triggerSubmit();
        }
      }
      this.isSelectedArtifact = isSelectedArtifact; 
    }
  }
  processBeforeSubmit(){}
  ngxOnChanges(){}

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
