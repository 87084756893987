import { AnswerModel } from "./answer-model"
import { Artifact } from "./artifact.model"
import { QuestionModel } from "./question.model"

export class ICertificationWizard{
    sessionId!: string
    startDate: Date | null = null
    userId: string | null = null
    applicantId: string | null = null
    approach: string | null = null
    isComplete: boolean = false
    initiatorRole: string | null = null
    questions: QuestionModel[] = [];
    requiredArtifacts: Artifact[] = [];
    selectedAnswers: AnswerModel[] = [];
}

export class CertificationWizard extends ICertificationWizard{

    constructor(options?: ICertificationWizard){
        super();
            if(options){
            this.sessionId = options.sessionId
            this.startDate = options.startDate ? new Date(options.startDate) : null;
            this.userId = options.userId;
            this.applicantId = options.applicantId;
            this.approach = options.approach,
            this.isComplete = options.isComplete
            this.initiatorRole = options.initiatorRole
            if(options.questions){
                options.questions.forEach(value =>{
                    this.questions?.push(new QuestionModel(value))
                })
            }
            if(options.requiredArtifacts){
                options.requiredArtifacts.forEach(value =>{
                    this.requiredArtifacts?.push(new Artifact(value))
                })
            }
            if(options.selectedAnswers){
                options.selectedAnswers.forEach(value =>{
                    this.selectedAnswers?.push(new AnswerModel(value))
                })
            }
        }
    }

    toJSON(){
        return {
            sessionId: this.sessionId,
            startDate: this.startDate,
            userId: this.userId,
            applicantId: this.applicantId,
            approach: this.approach,
            isComplete: this.isComplete,
            initiatorRole: this.initiatorRole,
            questions: this.questions.map(i=>i.toJSON()),
            requiredArtifacts: this.requiredArtifacts.map(i=>i.toJSON()),
            selectedAnswers: this.selectedAnswers.map(i=>i.toJSON())
        }
    }
}