import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { Subject, filter } from 'rxjs';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { Artifact } from 'src/app/core/models/artifact.model';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-acceptance-signature',
  templateUrl: './acceptance-signature.component.html',
  styleUrls: ['./acceptance-signature.component.scss']
})
export class AcceptanceSignatureComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  environment = environment;
  constructor( 
    private injector: Injector,
    private fb: FormBuilder, 
    public override appWizService : AppWizService
  ) {
    super(injector)
    this.interalForm = this.fb.group(this.getFormElements());
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }

  getFormElements() {
      return {
        userName: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        agree: new FormControl(null, [Validators.requiredTrue]),
        date: new FormControl(null, [Validators.required]),
        // signature: new FormControl(null, [Validators.required])
      };
  }

  initJsonData(): void {
    this.jsonData = this.jsonData || { acceptanceSignature: null };
    this.jsonData.acceptanceSignature = this.jsonData.acceptanceSignature || {};
    this.interalForm.patchValue(this.jsonData.acceptanceSignature)
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.acceptanceSignature = {};
    this.interalForm.reset();
  }

  override processBeforeSubmit(){
    this.jsonData = {acceptanceSignature:this.interalForm.value};
  }

  

}
