

  <div class="card" style="overflow: hidden;">
    <div class="card-header">
      <div class="d-flex">
        <h4 class="me-2 mb-0">
          Name: <b>{{demographics.displayName}}</b> | 
          ALSDE ID: <b>{{alsdeId}}</b>
        </h4>
      </div>
    </div>
    <div class="card-body">
      <mat-dialog-content>
        <app-background-review [bgrData]="bgrData" [goToFieldprint]="false"></app-background-review>
        <span class="m-1"></span>
        <app-current-certs [alsdeId]="alsdeId"></app-current-certs>
      </mat-dialog-content>
    </div>
  </div>


  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>