<h2 mat-dialog-title>{{data.msg.title}}</h2>
<mat-dialog-content class="mat-typography">

  <p [innerHtml]="data.msg.text"></p>

  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="data.msg.isShowCancel">Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
