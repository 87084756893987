import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { HttpService } from 'src/app/core/services/http.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';
import { environment } from 'src/environments/environment';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  environment = environment
  @Input() personalInfo: any;

  constructor(
    private injector: Injector,
    private _fb: FormBuilder,
    public override appWizService : AppWizService,
    public ls : LocalStorageService,
    private http: HttpService,
    public util: UtilService,
    public userService: UserService,
  ) {
    super(injector)
    this.interalForm = this._fb.group({
      alsdeId: [''],
      userId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      title: [''],
      maidenName: [''],
      suffix: [''],
  
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      country: ['', Validators.required],
      cellPhone: ['', Validators.required],
      workPhone: [''],
      homePhone: [''],
      personalEmail: ['', Validators.required],
      workEmail: [''],
      birthDetails: this._fb.group({
        dateOfBirth: ['', Validators.required],
      }),
      sex: ['', Validators.required],
      raceEthnicity: this._fb.group({
        ethnicity: ['', Validators.required],
        race: ['', Validators.required],
      }),
      driverLicense: this._fb.group({
        licenseNumber: ['', Validators.required],
        expirationDate: ['', Validators.required],
      }),
      liveInUnitedState: ['', Validators.required],
      stationedOnUsMilitaryBase: ['', Validators.required],
      employedAlabamaEducationalInstitution: ['', Validators.required],
    });
  }

  initJsonData(){
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){    
  }

  override processBeforeSubmit(){
    this.jsonData = {user:this.interalForm.value};
  }

  override ngxOnChanges() {
    if(this.personalInfo) this.setupUserDemographics();
  }

  setupUserDemographics() {
    this.interalForm.patchValue(this.personalInfo);
    this.validateSection();
  }
}

