<div class="mt-1">
    <div class="description my-1">Please complete the Class AA general information below</div>
    <div class="container">
        <form [formGroup]="interalForm">
            <div class="row form-group">
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="select" [form]="interalForm" title="Program Level"
                        placeholder="Program Level" controlName="programType" [data]="programTypes"></app-form-control>
                </div>
                <div class="col-sm-12"></div>
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="date" [form]="interalForm" title="Date of unconditional Admission" placeholder="Date of unconditional Admission"
                        controlName="admissionDate" ></app-form-control>
                </div>
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="date" [form]="interalForm" title="Program Completion Date" placeholder="Program Completion Date"
                        controlName="completionDate" [maxDate]="maxDate"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="text" [form]="interalForm" title="Prerequisite Certification Held"
                        placeholder="Prerequisite Certification Held" controlName="certificateName"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="date" [form]="interalForm" title="Prerequisite Certification Issuance Date"
                        placeholder="Prerequisite Certification Issuance Date" controlName="certificateIssueDate"></app-form-control>
                </div>
                <div class="col-sm-12 col-md-6">
                    <app-form-control type="text" [form]="interalForm" title="Approved Program GPA" 
                        placeholder="Approved Program GPA" controlName="gpa"></app-form-control>
                </div>
            </div>
        </form>
    </div>
</div>