export const environment = {
  production: false,
  apiURL: 'https://ccz9rjlfqg.execute-api.us-east-1.amazonaws.com/ACE/api/',
  aimURL: 'https://stageaim.alsde.edu/',
  mockApiURL: 'http://localhost:3000/api',

  externalUrl: {
    fieldPrint:"https://fieldprintalabama.com/",
    alabamaachieves:"https://www.alabamaachieves.org",
    certificationBasedOnTestCbt:"https://www.alabamaachieves.org/2022/07/certification-based-on-test-cbt/",
    atfProfessional:"https://www.alabamaachieves.org/2022/10/atf-professional/",
    ctcAdditionalTeachingDe:"https://www.alabamaachieves.org/2022/10/ctc-additional-teaching-de/",
    driverAndTrafficSafetyEducation:"https://www.alabamaachieves.org/2022/07/driver-and-traffic-safety-education/",
    substituteLicense:"https://www.alabamaachieves.org/2022/06/substitute-license/",
    emergency:"https://www.alabamaachieves.org/2022/06/emergency/",
    ctcTemporaryCertificates:"https://www.alabamaachieves.org/2022/10/ctc-temporary-certificates/",
    alsdeAce:"https://www.alsde.edu/ACE",
    alsdeUserProfile:"https://stageaim.alsde.edu/UserProfile.aspx",
  },
  social:{
    facebook:"https://www.facebook.com/ALTeachingandLeading",
    instagram:"https://www.instagram.com/teaching_and_leading_alabama",
    twitter:"https://twitter.com/TLAlabama",
    linkedin:"https://www.linkedin.com/company/alabama-department-of-education/",
  }
};
