<div class="container-fluid">
    <div class="question">
        <div class="question-text">
            {{ question.questionText }}
        </div>
        <mat-icon color="accent" title="Help" class="question-tooltip" *ngIf="question.tooltip"
            (click)="questionTooltip(question.tooltip)">help_outline</mat-icon>
    </div>
    <div [ngSwitch]="question.questionType" class="px-2">
        <div *ngSwitchCase="'userLookup'">
            <app-user-lookup (onUserFound)="onUserFound($event)"></app-user-lookup>
        </div>
        <div *ngSwitchCase="'cert'">
            <div [ngSwitch]="question.answerType.name">
                <!-- Free Text Control -->
                <div class="row" *ngSwitchCase="'text'">
                    <div *ngFor="let answer of question?.selectedAnswers" class="col-12">
                        <mat-form-field appearance="fill" class="w-100 form-field" class="control" >
                            <mat-label>Enter answer here</mat-label>
                            <input matInput [(ngModel)]="answer.answerText"/>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Dropdown Control -->
                <div class="row" *ngSwitchCase="'dropdown'">
                    <div  class="col-12">
                        <mat-form-field appearance="fill" class="w-100 form-field">
                            <mat-select placeholder="Select" [(ngModel)]="question.selectedAnswers[0]">
                              <mat-option *ngFor="let answer of question?.answers" [value]="answer">
                                {{answer.answerText}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Yes/No Control -->
                <div class="card" *ngSwitchCase="'single'" appearance="outline" class="control form-control">
                    <mat-radio-group aria-label="Select Yes or No" [(ngModel)]="selectedAnswers[0]"
                        [disabled]="question.lockAnswers" style="text-align: left;">
                        <div *ngFor="let answer of question?.answers" class="d-flex">
                            <mat-radio-button class="radio-button" [value]="answer.id"
                                (change)="valueChanged($event, answer)">
                                <span class="wrap-mat-radio-label">{{answer.answerText}}</span>
                            </mat-radio-button>
                            <mat-icon color="accent-dark" title="More Information" class="answer-tooltip"
                                *ngIf="answer.tooltip" (click)="answerTooltip(answer.tooltip)">info</mat-icon>
                        </div>
                    </mat-radio-group>
                </div>

                <!-- Multi Choice Control -->
                <div *ngSwitchCase="'multiple'" appearance="outline" class="control form-control">
                    <div class="row">
                        <div class="col-sm-12 text-start column-count-2">
                            <div *ngFor="let answer of question?.answers; let i = index">
                                <mat-checkbox class="radio-button" (change)="valueChanged($event, answer)"
                                    [checked]="selectedAnswers.includes(answer.id)"
                                    [disabled]="question.lockAnswers || disabledOnMaxSelectionsReached(answer)">
                                    {{answer.answerText}}
                                    <input *ngIf="answer.showTextbox && selectedAnswers.includes(answer.id)" (input)="valueChangedTextbox(answer)" matInput class="inline-input" [(ngModel)]="answer.textboxValue"/>
                                </mat-checkbox>
                                <mat-icon color="accent-dark" title="More Information" class="answer-tooltip"
                                    *ngIf="answer.tooltip" (click)="answerTooltip(answer.tooltip)">info</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 10px;">
        <button [disabled]="questions.length<2" mat-stroked-button color="accent" (click)="back()"
            style="margin-right:5px;">Back</button>
        <button [disabled]="!canNext()" mat-stroked-button color="accent" (click)="next()"
            style="margin-left:5px">Next</button>
        <!-- <button *ngIf="questionService.canSubmitForm(question)" mat-stroked-button color="accent" (click)="submit()" style="margin-left:5px">Launch Application</button> -->
    </div>
    <!-- {{question.selectedAnswers|json}} -->
</div>

<!-- {{utilService.decycle(question)|json}} -->