import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/core/models/message.model';
import { MessageService } from 'src/app/core/services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit {

  loader: boolean = false;
  messages: Message[] = [];
  selectedMessage: Message = new Message({} as Message);
  selectedTab = 0;

  constructor(
    public messageService: MessageService, 
    private activatedRoute:ActivatedRoute, 
    private ls: LocalStorageService, 
    private utilService: UtilService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['id'] && params['id'] !== ''){
        this.getSingleMsg(params['id'])
      } else this.getSingleMsg('');
    });
  }

  ngOnInit(): void { }

  getSingleMsg(id:String) {
    this.loader = true;
    const uid = JSON.parse(this.ls.getItem('user')).alsdeId;
    this.messageService.getUnreadMessages('messages?userId='+uid).subscribe((res:any) => {
      this.loader = false;
        if(res == null) return;
        this.messages = this.reverseChronological(res.messages);
        if(id == '') {
          this.selectedMessage = res.messages[0];
        }
        else {
          this.selectedMessage = this.messages.filter((x:Message) => x.messageId == id)[0];
          if(this.selectedMessage.read == null) {
            this.selectedMessage.read = new Date();
            this.updateRead(this.selectedMessage, 0);
          }
          this.selectedTab = 1;
        }
    });
  }

  updateRead(data:Message, index: any) {
    this.loader = true;
    this.messageService.updateReadMessage('messages', data).subscribe((res:any) => {
      this.loader = false;
      if(res.success == 'True') {
        // this.messages[index] = data;
      }
    });
  }

  selectMessage(msg:Message, index: Number){
    this.selectedTab = 1;
    this.selectedMessage = msg;
    if(msg.read == null) {
      this.selectedMessage.read = new Date();
      this.updateRead(this.selectedMessage, index);
    }
  }

  ChangeTab(event:any) {
    this.selectedTab = event.index;
  }

  downloadAttachment() {
    if(this.selectedMessage?.attachmentDocId) {
      this.loader = true;
      this.messageService.getFileInfo('file', this.selectedMessage?.attachmentDocId).subscribe((x:any) => {
        this.loader = false;
        if(x.preSignedURL) {
          this.utilService.urlToBlob(x.preSignedURL, {}, this.selectedMessage.title).subscribe();
        }
      });
    }
  }

  reverseChronological(msgs:Message[]){
    return msgs.sort((a:Message,b:Message)=>{
      return new Date(b.sent).getTime() - new Date(a.sent).getTime()
    })
  }
}
