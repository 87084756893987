<div class="container px-0 max-w-100">
  <div class="row pt-4 mx-0">
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 p-2">
      <div class="card h-100">
        <div class="ms-3">
          <a class="text-decoration-none" [routerLink]="['/app/app-wizard',feeSummary.appId]">Back</a>
        </div>
        <table class="table mt-3">
          <tbody>
            <tr>
              <th>AppID:</th>
              <td>{{feeSummary.appId}}</td>
            </tr>
            <tr>
              <th>Total Fee:</th>
              <td>{{feeSummary.feeTotal | currency}}</td>
            </tr>
            <tr>
              <th>Credit Amount:</th>
              <td>
                {{feeSummary.creditAmount | currency}}
                <mat-icon style='padding-right: 10px;vertical-align: middle;' 
                  color="accent" 
                  title="More Information"
                  (click)="util.openMsgDialog('This value represents funds previously submitted to the Agency that are on file and will be used to offset new fees.')"
                >help_outline</mat-icon>
              </td>
            </tr>
            <tr>
              <th>Paid Amount:</th>
              <td>{{feeSummary.feeTotalPaid | currency}}</td>
            </tr>
            <tr>
              <th>Balance Owed:</th>
              <td>{{calculateBalance() | currency}}</td>
            </tr>
          </tbody>
        </table>
        <button mat-stroked-button color="accent" class="w-100 mt-auto" *ngIf="calculateBalance() != 0 || true"
          (click)="preparePayment()">{{feeSummary.balanceAmount > 0 ? 'Pay': 'Use Credit'}}</button>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-2 ">
      <div class="card min-vh-75 bg-light border">
        <div class="card-body container px-4">
          <div class="row gx-5">
            <div class="col-12 pt-2" *ngFor="let item of feeSummary.items;">
              <div class="mb-3 p-3 border bg-light text-left d-flex">
                <div class="col-6">{{item.name}}</div>
                <div class="col-3">Fee: {{ item.feeAmount | currency }}</div>
                <div class="col-3">Paid: {{ item.feeAmountPaid | currency }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>