import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/core/services/util.service';
import { environment } from '../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { USER_ROLES } from 'src/app/core/enums/user.enum';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ROLES_BY_USER_TYPE } from 'src/app/core/enums/ui.enum';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  environment = environment;
  private unsubscriber : Subject<void> = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public userService: UserService,
    public router: Router,
    private util: UtilService,
    private ls: LocalStorageService
  ) { }

  ngOnInit(): void {
    
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
   
    this.route.queryParams.subscribe((params) => {
    
      let accessToken = params['Token'];
      if (accessToken) {
        // Need to use the token to hit the api for the bearer token
        this.authService.validateToken(accessToken).subscribe(auth => {
            if(auth){
                let redirectUrl = this.ls.getItem('afterlogin-redirect-url');
                this.ls.removeItem('afterlogin-redirect-url');
                if(redirectUrl){
                  window.location.href = redirectUrl;
                }else{
                  let isEduAgent = this.userService.isEduAgent();
                  let home = isEduAgent ? '/edu-agent/home' : '/app';
                  this.router.navigate([home]);
                }
            }else{
                this.util.openMsgDialog('Unable to complete login', 'We ran into a problem');
            }
        })
       
      }
    });
  }

  login(){
    window.location.href = environment.aimURL;
  }
}
