import { Component, OnInit, OnChanges, Injector } from '@angular/core';
import { CertQuestionsMock } from 'src/app/core/mocks/cert-questions.mock';
import { QuestionModel } from 'src/app/core/models/question.model';
import { AppConfiguration } from 'src/app/app-configuration';
import { FormBuilder } from '@angular/forms';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UploadService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-proof-of-citizenship',
  templateUrl: './proof-of-citizenship.component.html',
  styleUrls: ['./proof-of-citizenship.component.scss'],
})
export class ProofOfCitizenshipComponent extends ArtifactBaseComponent implements OnInit, OnChanges {

  questions: QuestionModel[] = [];
  ARTIFACT_KEY = ARTIFACT_KEY;
  docTypes: any = null;
  err: boolean = false;

  constructor(
    private injector: Injector,
    public appConfig: AppConfiguration,
    public override appWizService: AppWizService,
    private _fb: FormBuilder,
    private util: UtilService,
    public override uploadService: UploadService,
  ) {
    super(injector)
    this.interalForm = this._fb.group({
      'citQA0': [''],
      'citQA1': [''],
      'citQASub0': [''],
      'citQASub1': ['']
    });
    this.loadQuestions(JSON.parse(JSON.stringify(CertQuestionsMock.cit)));
  }

  loadQuestions(questionJson: any[]) {
    questionJson.forEach((element) => {
      this.questions.push(new QuestionModel(element));
    });
  }

  hideQuestion(i: number) {
    let val = this.interalForm.get('citQA0')?.value || '';
    return i == 1 && ['', 'Yes'].indexOf(val) > -1
  }

  initJsonData() {
    this.jsonData = this.jsonData || { questions: null, answers: null };
    this.jsonData.answers = this.jsonData.answers || {};
    this.jsonData.questions = this.jsonData.questions || this.questions;

    this.questions = this.jsonData.questions;
    this.interalForm.patchValue(this.jsonData.answers);

    this.questions[0].answers[0].documents = this.questions[0].answers[0].documents || [];
    this.questions[1].answers[0].documents = this.questions[1].answers[0].documents || [];
    this.docTypes = this.appWizService.getDocTypes(this.artifact);

  }
  validateJsonData() {
    let yesAnswers = this.questions.filter(i => i.responseValue == 'Yes' || i.responseValue == undefined);
    if (yesAnswers.length == 0) {
      this.util.openSnackBar("Required citizenship or lawfully present in the US.", "Error");
      this.artifact.sectionValid = false;
    } else if (yesAnswers[0]?.answers[0].documents?.length) {
      this.artifact.sectionValid = true;
    } else {
      this.artifact.sectionValid = false;
    }
    this.err = this.questions.filter(i => i.responseValue == 'No').length == 2;
  }
  resetJsonData() {
    this.jsonData.answers = {};
    return this.questions.reduce((unUsedDocuments:any[], item, index) => unUsedDocuments.concat(this.getUnUsedDocuments(index)), []);
  }

  getUnUsedDocuments(i: number) {
    let documents: any[] = [];
    let formValue: any = this.interalForm.value;
    if (this.interalForm.get('citQA' + i)?.value == 'Yes') {
      let revertIndex = i == 0 ? 1 : 0;
      formValue['citQA' + revertIndex] = 'No';
      this.interalForm.patchValue(formValue);
      this.questions[revertIndex].responseValue = 'No';
      documents = documents.concat(this.questions[revertIndex].answers[0].documents || []);
      this.questions[revertIndex].answers[0].documents = [];
    }
    documents = documents.concat(this.questions[i].answers[0].documents || []);
    this.questions[i].answers[0].documents = [];
    return documents;
  }

  onAnswerChange(i: number){
    let unUsedDocuments = this.getUnUsedDocuments(i);
    if(unUsedDocuments.length){
      this.uploadService.deleteAllDocs(unUsedDocuments).subscribe(()=>{
        this.submitSection();
      });
    }
    this.validateSection();
  }

  override processBeforeSubmit() {
    if (this.interalForm.get('citQA0')?.value == 'yes') {
      this.interalForm.patchValue({ citQA1: '', citQASub1: '' });
    }
    this.jsonData = { questions: this.questions, answers: this.interalForm.value };
  }

}
