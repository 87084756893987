import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { UserLookupComponent } from 'src/app/shared/components/user-lookup/user-lookup.component';

@Component({
  selector: 'app-alsde-person',
  templateUrl: './alsde-person.component.html',
  styleUrls: ['./alsde-person.component.scss']
})
export class AlsdePersonComponent extends ArtifactBaseComponent implements OnInit{
  @ViewChild(UserLookupComponent) userLookup!: UserLookupComponent;
  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
    public util: UtilService
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      alsdeId: ['', [Validators.required]],
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  initJsonData(){
    this.jsonData = this.jsonData || { person: {} };
    this.jsonData.person = this.jsonData.person || {};
    this.jsonData.person.demographics = this.jsonData.person.demographics || {demographics:{}};
    this.interalForm.patchValue(this.jsonData.person);
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.person = {};
    this.interalForm.reset();
    this.userLookup.reset();
  }

  onUserFound(user:any){
    console.log("Demographic user found",user);
    this.interalForm.patchValue({
      alsdeId:user?.demographics?.alsdeId
    });
    this.jsonData = {person:user};
    this.validateSection();
  }
}
