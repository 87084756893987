export enum ARTIFACT_TYPE{
    'FormDownloadArtifact'= 'FormDownloadArtifact',
    'FileUploadArtifact'= 'FileUploadArtifact',
    'TranscriptArtifact'= 'TranscriptArtifact',
    'FormSectionArtifact'= 'FormSectionArtifact',
    'BgrArtifact'= 'BgrArtifact',
    'FeeArtifact'= 'FeeArtifact',
    'mixed' = 'mixed'
}

export enum ARTIFACT_STATUS{
    'RESUBMIT' = 0,
    'OUTSTANDING' = 1,
    'SUBMIT' = 2,
    'PACKAGECOMPLETE' = 3,
    'COMPLETE' = 4,
}

export const ARTIFACT_STATUS_LABEL = [
    'RESUBMIT',
    'OUTSTANDING',
    'SUBMIT',
    'ITEM COMPLETE',
    'ITEM COMPLETE'
];

export const ARTIFACT_STATUS_CLASS = [
    'pending',  //'RESUBMIT',
    'outstanding-orange',  //'OUTSTANDING',
    'pending',  //'SUBMIT',
    'complete',  //'PACKAGECOMPLETE',
    'complete',  //'COMPLETE'
];

export enum ARTIFACT_SUBMMITED_BY{
    'FILE_UPLOAD' = 'FILE_UPLOAD',
    'SUBMIT_CLICK' = 'SUBMIT_CLICK',
}