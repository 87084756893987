import { Component } from '@angular/core';
import { CustomSpinnerService } from './core/interceptors/custom-spinner.service';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showCustomLoader: number = 0;

  constructor(
    private spin:CustomSpinnerService, 
    public platform: Platform, 
    private router: Router,
  ) { }

  ngOnInit() {
    if(this.platform.isBrowser && this.platform.TRIDENT) {
      this.router.navigate(['/app/support']);
    }
    this.spin.counter.subscribe(x => { setTimeout(()=> {this.showCustomLoader = x;}, 10);});
  }
}
