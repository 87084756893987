import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent {
  @Input ('text') text: string = "";
  showShortDesciption = true

  alter() {
    this.showShortDesciption = !this.showShortDesciption
  }
}
