<div>
    <div class="description pt-3">
        <p>PLEASE READ CAREFULLY</p>
        <p>IF YOU ANSWER “YES” TO ANY OF THE QUESTIONS BELOW, YOU WILL BE REQUIRED TO PROVIDE A BRIEF DESCRIPTION OF THE
            CIRCUMSTANCES YOU ARE REFERRING TO BEFORE COMPLETING YOUR APPLICATION. THE ALSDE MAY ALSO CONTACT YOU FOR
            MORE INFORMATION. YOU MAY ALSO UPLOAD COURT DOCUMENTS, NOTARIZED PERSONAL STATEMENTS, AND OTHER DOCUMENTS
            PERTINENT TO THE MATTER(S) BEING REPORTED USING THE UPLOAD FEATURE BELOW.
        
            FAILURE TO PROVIDE ACCURATE RESPONSES TO THE FOLLOWING QUESTIONS MAY DELAY THE REVIEW OF YOUR APPLICATION 
            AND COULD RESULT IN DISCIPLINARY ACTION BY THE ALABAMA STATE DEPARTMENT OF EDUCATION UP TO AND INCLUDING THE DENIAL 
            OF A CERTIFICATE OR LICENSE.
        </p>
        <p>Each Question requires a response</p>
    </div>
    <div class="primary">
        <form [formGroup]="interalForm">
            <div *ngFor="let question of questions; let i = index;" style="margin-bottom:10px;">
                <app-question [question]="question" [formControlName]="'citQA'+i" [showHelpIcon]="false" [showTooltipIcon]="false" [showControlButtons]="false" (valueChange)="onAnswerChange();" [disabled]="isReadOnly()">
                </app-question>
                <div *ngIf="question.responseValue == 'Yes'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Explanation</mat-label>
                        <textarea matInput required [(ngModel)]="question.explanation" (input)="onAnswerChange()" [formControlName]="'citQASub'+i"
                            placeholder="Please provide as much detail as you can explaining the Yes response to this question"
                            rows="4" maxlength="5000"></textarea>
                            <span style="font-size:12px;color:#dd2c00;" *ngIf="!util.validateMinMax(question.explanation,50,5000)">Provide 50-5000 characters</span>
                    </mat-form-field>
                    <app-file-upload 
                        (emitData)="onDocumentUpdate()" 
                        [appConfig]="appConfig.citUploaderConfig" 
                        [btnName]="'Upload Supporting Documents'" 
                        [artifact]="artifact" 
                        [type]="ARTIFACT_KEY.CRIMINAL"
                        [uploadSummary]="question.answers[0].documents"
                        [docTypes]="question.docTypes"
                        [locked]="locked"
                    ></app-file-upload>
                </div>
            </div>
        </form>
    </div>
</div>