export class CertQuestionsMock {
  public static questions = [
    {
      id: '1',
      visible: true,
      isFirst: true,
      questionText: 'What kind of certification are you seeking',
      tooltip: 'You may be seeking reciprocity from another state, adding a certificate, or renewal',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Out of State Certification',
          tooltip: 'I hold a valid Professional Certificate in another state and I am seeking Alabama certification.',
          requiredArtifacts: [],
          nextQuestionId: '2',
        },
        {
          answerText: 'Add an Additional Teaching Field',
          tooltip: 'String',
          requiredArtifacts: [],
          nextQuestionId: '24',
        },
      ],
    },
    {
      id: '2',
      visible: false,
      questionText: 'Do you hold a valid professional educator certificate in another state?',
      tooltip: 'Tooltip',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
          nextQuestionId: null,
          terminationMessage: {
            text: 'You cannot apply through this approach.  Please refer to the requirements for this approach',
            link: '',
          },
        },
        {
          answerText: 'Yes',
          tooltip: 'String',
          requiredArtifacts: [
            {
              artifactId: 'a',
              description: 'The CER form is used to verfiy previous experience from another state',
            },
          ],
          nextQuestionId: '3',
        },
      ],
    },
    {
      id: '3',
      isLast: true,
      visible: false,
      questionText:
        'Is your professional educator certificate in speech language pathology?',
      tooltip: 'You cannot apply through this approach.  Please see the Speech-Language Pathology Professional Educator Certificate, Speech-Language Pathology Temporary Certificate, and Speech-Language Pathology Assistant Certificate approaches for certification option',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
          nextQuestionId: '4',
        },
        {
          answerText: 'Yes',
          tooltip: 'String',
          requiredArtifacts: [],
          nextQuestionId: '',
          terminationMessage: {
            text: 'You cannot apply through this approach.  Please see the Speech-Language Pathology Professional Educator Certificate, Speech-Language Pathology Temporary Certificate, and Speech-Language Pathology Assistant Certificate approaches for certification options.',
            link: '',
          },
        },
      ],
    },
  ];
  public static military = [
    {
      id: '1',
      visible: true,
      questionText:
        'I am married to and living with an active-duty member of the United States Armed Forces who has been relocated and stationed in Alabama under official military orders',
      tooltip: 'Spouse of an active military member stationed in Alabama',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: 'I do not meet all criteria',
          requiredArtifacts: [],
        },
      ],
    },
    {
      id: '2',
      visible: true,
      questionText:
        'I am an active-duty member of the United States Armed Forces who has been relocated and stationed in Alabama under official military orders.',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
    },
  ];
  public static cit = [
    {
      id: '1',
      visible: true,
      isFirst: true,
      questionText:
        'I hereby declare that I am a citizen of the United States.',
      tooltip: 'If you are a U.S. citizen, please select one of the acceptable forms of verification from this list to verify your citizenship',
      answerType: { name: 'single' },
      nextQuestionId: '2',
      answers: [
        {
          visible: false,
          answerText: 'Yes',
          tooltip: '',
          selectedArtifact: null,
          documents: [],
          requiredArtifacts: [
            'A - An Alabama driver’s license or non-driver’s identification card issued by the Alabama Department of Public Safety',
            'B - A birth certificate indicating birth in the United States or one of its territories',
            'C - Pertinent pages of a United States valid or expired passport identifying the person and the person’s passport number, or the person’s United State passport',
            'D - United States naturalization documents or the number of the certificate of naturalization',
            'E - Other documents or methods of proof of United States citizenship issued by the federal government pursuant to the Immigration and Nationality Act of 1952, as amended',
            'F - Bureau of Indian Affairs card number, tribal treaty card number, or tribal enrollment number',
            'G - A consular report of birth abroad of a citizen of the United States of America',
            'H - A certification of citizenship issued by the United States Citizenship and Immigration Services',
            'I - A certification of report of birth issued by the United States Department of State',
            'J - An American Indian Card, with KIC classification, issued by the United States Department of Homeland Security',
            'K - Final adoption decree showing the person’s name and United States birthplace',
            'L - An official United States Military record of service showing the applicant’s place of birth in the United States',
            'M - An extract from a United States hospital record of birth created at the time of the person’s birth indicating the place of birth in the United States',
            'N - AL-verify',
            'O - A valid Uniformed Services Privileges and Identification Card',
            'P - Any form of ID authorized by the Alabama Department of Revenue',
          ],
        },
        {
          visible: false,
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
    },
    {
      id: '2',
      visible: false,
      isLast: true,
      questionText:
        'I hereby declare that I am an alien lawfully present in the United States.',
      tooltip: 'If you select lawful presence, please select one of the acceptable forms of verification from this list to verify your lawful presence',
      answerType: { name: 'single' },
      nextQuestionId: '',
      answers: [
        {
          visible: false,
          answerText: 'Yes',
          tooltip: '',
          selectedArtifact: null,
          documents: [],
          requiredArtifacts: [
            'A - A valid tribal enrollment card or other form of tribal identification bearing a photograph or other biometric identifier',
            'B - Any valid United States federal or state government issued identification document bearing a photograph or other biometric identifier, including a valid Uniformed Services Privileges and Identification Card if issued by an entity that requires proof of lawful presence in the United States before issuance',
            'C - A foreign passport with an unexpired United States Visa and a corresponding stamp or notation by the United States Department of Homeland Security indicating the bearer’s admission to the United States',
            'D - A foreign passport issued by a visa waiver country with the corresponding entry stamp and unexpired duration of stay notation or an I-94 W form by the United States Department of Homeland Security indicating the bearer’s admission to the United States'

          ],
        },
        {
          visible: false,
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [
          ],
        },
      ],
    },
  ];
  public static criminal = [
    {
      id: '1',
      visible: true,
      questionText:
        'Have you ever had any adverse action (e.g. warning, reprimand, suspension, revocation, denial, voluntary surrender) taken against a professional certificate, license or permit issued by an agency other than the Alabama State Department of Education?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [{
          "displayName": "Personal Statement",
          "code": "Personal Statement",
          "subTypes": null
        },
        {
          "displayName": "Other Agency Document",
          "code": "Other Agency Document",
          "subTypes": null
        }]
      }],
    },
    {
      id: '2',
      visible: true,
      questionText:
        'Are you currently the subject of an investigation involving a violation of a profession’s laws, rules, standards or Code of Ethics by an agency other than the Alabama State Department of Education?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [{
          "displayName": "Personal Statement",
          "code": "Personal Statement",
          "subTypes": null
        },
        {
          "displayName": "Other Agency Document",
          "code": "Other Agency Document",
          "subTypes": null
        }]
      }],
    },
    {
      id: '3',
      visible: true,
      questionText:
        'Are you currently the subject of an investigation involving sexual misconduct or physical harm to a child?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [
          {
            "displayName": "Personal Statement",
            "code": "Personal Statement",
            "subTypes": null
          },
          {
            "displayName": "Court Records",
            "code": "Court Records",
            "subTypes": null
          },
          {
            "displayName": "Other Agency Document",
            "code": "Other Agency Document",
            "subTypes": null
          },
        ]
      }],
    },
    {
      id: '4',
      visible: true,
      questionText:
        'Have you ever resigned from a position rather than face disciplinary action?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [{
          "displayName": "Personal Statement",
          "code": "Personal Statement",
          "subTypes": null
        },
        {
          "displayName": "Other Agency Document",
          "code": "Other Agency Document",
          "subTypes": null
        }]
      }],
    },
    {
      id: '5',
      visible: true,
      questionText:
        'Have you ever been convicted of, or entered a plea of no contest to a felony or misdemeanor other than a minor traffic violation?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [{
          "displayName": "Personal Statement",
          "code": "Personal Statement",
          "subTypes": null
        },
        {
          "displayName": "Court Records",
          "code": "Court Records",
          "subTypes": null
        }]
      }],
    },
    {
      id: '6',
      visible: true,
      questionText:
        'Are you the subject of a pending investigation involving a criminal act?',
      tooltip: '',
      answerType: { name: 'single' },
      answers: [
        {
          answerText: 'Yes',
          tooltip: '',
          requiredArtifacts: [],
        },
        {
          answerText: 'No',
          tooltip: '',
          requiredArtifacts: [],
        },
      ],
      docTypes: [{
        "displayName": "BGR",
        "code": "BGR",
        "subTypes": [{
          "displayName": "Personal Statement",
          "code": "Personal Statement",
          "subTypes": null
        },
        {
          "displayName": "Court Records",
          "code": "Court Records",
          "subTypes": null
        }]
      }],
    },
  ];
}
